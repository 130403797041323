import { Box, IconButton, Stack, Table, TableContainer, TablePagination, Typography } from '@mui/material'
import EnhancedTableHead from './EnhancedTableHead'
import { EnhancedTableProps } from './table.types'
import { DeleteOutlineRounded } from '@mui/icons-material'

const EnhancedTable = <T,>({
  rows,
  rowCount = -1,
  headCells,
  order,
  orderBy,
  selected,
  expandable,
  page = 0,
  rowsPerPage,
  rowsPerPageOptions,
  next_cursor,
  previous_cursor,
  onRequestSort,
  onSelectAllClick,
  onChangePage,
  onChangeRowsPerPage,
  onBulkDelete,
  children,
}: EnhancedTableProps<T>) => {
  return (
    <Box sx={{ overflowY: 'auto' }}>
      <Stack direction="row" justifyContent="flex-end" sx={{ height: (theme) => theme.spacing('7') }}>
        {selected && selected.length > 0 && (
          <Stack direction="row" alignItems="center" flex={1}>
            <Typography variant="body2">
              {selected.length} {selected.length === 1 ? 'item' : 'items'} selected
            </Typography>
            {onBulkDelete && (
              <IconButton onClick={onBulkDelete}>
                <DeleteOutlineRounded />
              </IconButton>
            )}
          </Stack>
        )}
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelDisplayedRows={() => ''}
          slotProps={{
            actions: {
              nextButton: {
                disabled: !next_cursor,
              },
              previousButton: {
                disabled: !previous_cursor,
              },
            },
          }}
          data-testid="table-pagination"
        />
      </Stack>
      <TableContainer
        sx={{
          maxHeight: 600,
          maxWidth: '100%',
        }}
      >
        <Table aria-labelledby="tableTitle" stickyHeader data-testid="table">
          <EnhancedTableHead<T>
            headCells={headCells}
            numSelected={selected?.length}
            order={order}
            expandable={expandable}
            orderBy={orderBy}
            onSelectAllClick={onSelectAllClick}
            onRequestSort={onRequestSort}
            rowCount={rows.length}
          />
          {children}
        </Table>
      </TableContainer>
    </Box>
  )
}

export default EnhancedTable
