import { Box, Button, Stack, Typography, Card, useTheme, FormControlLabel, Switch } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { useState } from 'react'
import UploadDialog from '../UploadDialog'
import TimePointSubmissionStatusChip from '../TimePointSubmissionStatusChip'
import { TimepointSubmissionStatus } from '@common/config/api/client'

export type TimePointConfigurationProps = {
  name: string
  status?: TimepointSubmissionStatus
  modalities: Array<string>
  containerSubmissionId: string
  timePointFormId?: string | null
}

export default function TimePointConfiguration({
  name,
  modalities,
  status,
  timePointFormId,
  containerSubmissionId,
}: TimePointConfigurationProps) {
  const theme = useTheme()
  const [filesUploadDialogVisible, setFilesUploadDialogVisible] = useState(false)

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h5">{name}</Typography>
          <TimePointSubmissionStatusChip status={status} />
        </Box>
        <Button variant="contained" size="large" color="primary">
          Submit
        </Button>
      </Box>
      <Stack gap={2} mt={3}>
        {timePointFormId ? (
          <Card sx={{ p: 3, borderRadius: 4 }} elevation={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="flex-start">
                <CheckCircleOutlineRoundedIcon sx={{ color: 'text.disabled', mt: 0.5 }} />
                <Box ml={1}>
                  <Typography variant="h6">Clinical information form</Typography>
                  <Typography variant="body1" color="text.secondary">
                    Head eForm for Stroke Assessment
                  </Typography>
                </Box>
              </Box>
              <Button variant="contained" size="medium" color="secondary">
                Fill out form
              </Button>
            </Box>
          </Card>
        ) : null}
        <Card sx={{ p: 3, borderRadius: 4 }} elevation={1}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="flex-start">
              <CheckCircleOutlineRoundedIcon sx={{ color: 'text.disabled', mt: 0.5 }} />
              <Box ml={1}>
                <Typography variant="h6">Exam results</Typography>
                <Typography variant="body1" color="text.secondary">
                  Upload files with procedure results for the modalities mentioned below.
                </Typography>
              </Box>
            </Box>
            <Button variant="contained" size="medium" color="primary" onClick={() => setFilesUploadDialogVisible(true)}>
              Upload files
            </Button>
          </Box>
          <Stack gap={2} mt={4}>
            {modalities.map((item) => (
              <Box
                key={item}
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius={3}
                border="1px solid"
                borderColor={theme.palette.divider}
              >
                <Box>
                  <Typography variant="h6">{item} modality</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Files not uploaded
                  </Typography>
                </Box>
                <FormControlLabel
                  value="disabled"
                  control={<Switch color="primary" />}
                  label="I don’t have any files to upload"
                  labelPlacement="start"
                />
              </Box>
            ))}
          </Stack>
        </Card>
        {filesUploadDialogVisible && (
          <UploadDialog
            containerSubmissionId={containerSubmissionId}
            onSave={() => {}}
            onClose={() => setFilesUploadDialogVisible(false)}
          />
        )}
      </Stack>
    </Box>
  )
}
