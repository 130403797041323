import { StorageService } from '@common/services/storageService'
import { createContext, useContext } from 'react'

export const StorageContext = createContext<StorageService | null>(null)

export const useStorage = () => {
  const storage = useContext(StorageContext)
  if (!storage) throw new Error('useStorage must be used within StorageProvider')
  return storage
}
