import { Chip } from '@mui/material'

import { QueryStatus } from '@common/config/api/client'
import { TASK_QUERY_COLOR, TASK_QUERY_TEXT_COLOR } from './QueryStatusColor'
import { formatQueryStatusText } from '../utils'

export default function QueryStatusChip({ queryStatus }: { queryStatus: QueryStatus }) {
  return (
    <Chip
      data-testid="task-query-chip"
      label={formatQueryStatusText(queryStatus)}
      size="small"
      sx={{
        backgroundColor: TASK_QUERY_COLOR[queryStatus],
        color: TASK_QUERY_TEXT_COLOR[queryStatus],
        padding: 0.5,
        alignItems: 'center',
      }}
    />
  )
}
