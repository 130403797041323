import { Menu, MenuProps } from '@mui/material'
import { ReactNode } from 'react'

export interface BaseDropdownMenuProps extends Omit<MenuProps, 'open'> {
  anchorEl: HTMLElement | null
  onClose: () => void
  children: ReactNode
}

export function BaseDropdownMenu({ anchorEl, onClose, children, ...props }: BaseDropdownMenuProps) {
  return (
    <Menu
      sx={{ mt: '45px' }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
      {...props}
    >
      {children}
    </Menu>
  )
}
