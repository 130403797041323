import { Outlet } from 'react-router-dom'
import StudyHeader from './StudyHeader/StudyHeader'
import StudyFooter from './StudyFooter/StudyFooter'
import { Box } from '@mui/material'
import eventEmitter from '@common/utils/eventEmitter'
import { BaseLayout } from '@common/components/layouts/BaseLayout'

export default function CreateOrUpdateStudyLayout() {
  const handleSaveAsDraft = () => eventEmitter.emit('onSaveAsDraft')
  const handleSaveAndContinue = () => eventEmitter.emit('onSaveAndContinue')

  return (
    <BaseLayout
      header={<StudyHeader handleSaveAsDraft={handleSaveAsDraft} isSubmitting={false} />}
      footer={<StudyFooter handleSaveAndContinue={handleSaveAndContinue} isSubmitting={false} />}
    >
      <Box sx={{ paddingTop: 8, paddingBottom: 12 }}>
        <Outlet />
      </Box>
    </BaseLayout>
  )
}
