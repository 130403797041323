import { MenuItem, MenuList, Typography } from '@mui/material'
import { useState } from 'react'

export default function ScrollMenu({
  menuItems,
  onMenuItemClick,
}: {
  menuItems: string[]
  onMenuItemClick: (item: string) => void
}) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <MenuList sx={{ padding: 3 }}>
      {menuItems.map((item, index) => (
        <MenuItem
          selected={index === selectedIndex}
          key={index}
          onClick={() => {
            onMenuItemClick(item)
            setSelectedIndex(index)
          }}
          sx={{
            borderRadius: 2,
            height: 44,
            px: 2,
            py: 0.5,
            mb: index !== menuItems.length - 1 ? 1 : 0,
          }}
        >
          <Typography variant="subtitle1">{item}</Typography>
        </MenuItem>
      ))}
    </MenuList>
  )
}
