import { CurrentActiveTabContext } from '@common/context/CurrentActiveTabContext'
import { useContext } from 'react'

export const useActiveTab = () => {
  const context = useContext(CurrentActiveTabContext)
  if (context === undefined) {
    throw new Error('useActiveTab must be used within a CurrentActiveTabProvider')
  }
  return context
}
