import ExpandableSubjectTableRow from './ExpandableSubjectTableRow'
import { Order } from '@common/components/Table/table.types'
import { useMemo } from 'react'
import { TimepointSubject } from '@common/config/api/client'
import { TableBody } from '@mui/material'
import { getComparator } from '@features/subject/utils'

export default function SubjectListTableBody({
  rows,
  order,
  orderBy,
}: {
  rows: TimepointSubject[]
  order: Order
  orderBy: keyof TimepointSubject
}) {
  const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows])

  return (
    <TableBody data-testid="subject-list-table-body">
      {visibleRows.map((subject) => (
        <ExpandableSubjectTableRow
          key={subject.subject_id}
          subject={subject}
          data-testid={`subject-row-${subject.subject_id}`}
        />
      ))}
    </TableBody>
  )
}
