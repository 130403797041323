import { Box, TableCell, TableRow } from '@mui/material'
import TimepointSubmissionChip from './TimepointSubmissionChip'
import AddTimepointSubmissionChip from './AddTimepointSubmissionChip'
import { TimepointSubmissionRow } from '@features/subject/subject.types'
import { useNavigate, useParams } from 'react-router-dom'
import { generatePathWithParams } from '@common/utils/urlUtils.ts'
import { ROUTES } from '../../../../routes.tsx'

export default function TimepointSubmissionTableRow({
  timepointSubmissionRow,
  subjectId,
}: {
  timepointSubmissionRow: TimepointSubmissionRow
  subjectId: string
}) {
  const navigate = useNavigate()
  const { studyId } = useParams<{
    studyId: string
  }>()

  const navigateToSubjectDetails = (timepointSubmissionId: string) => {
    const path = generatePathWithParams(ROUTES.SUBJECT_DETAILS.path, {
      studyId: studyId!,
      subjectId: subjectId!,
      timepointSubmissionId: timepointSubmissionId,
    })

    navigate(path)
  }

  return (
    <TableRow data-testid="timepoint-submission-row">
      <TableCell
        sx={{
          position: 'sticky',
          bgcolor: 'background.paper',
          left: 0,
          zIndex: 10,
        }}
        data-testid="empty-cell-0"
      />
      <TableCell
        sx={{
          position: 'sticky',
          bgcolor: 'background.paper',
          left: 48,
          zIndex: 10,
        }}
        data-testid="empty-cell-1"
      />
      <TableCell
        sx={{
          position: 'sticky',
          bgcolor: 'background.paper',
          left: 218,
          zIndex: 10,
        }}
        data-testid="empty-cell-2"
      />
      <TableCell
        sx={{
          position: 'sticky',
          bgcolor: 'background.paper',
          left: 388,
          zIndex: 10,
        }}
        data-testid="empty-cell-3"
      />
      {timepointSubmissionRow.timepoint_submissions.map((timepointSubmission, index) => (
        <TableCell
          key={index}
          sx={{
            paddingX: 0.5,
            paddingY: 0.25,
          }}
          data-testid={`timepoint-cell-${index}`}
        >
          {timepointSubmission.timepoint_submission ? (
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                navigateToSubjectDetails(timepointSubmission.timepoint_submission?.timepoint_submission_id || '')
              }
            >
              <TimepointSubmissionChip
                timepoint={timepointSubmission.timepoint_submission}
                data-testid={`timepoint-chip-${index}`}
              />
            </Box>
          ) : (
            timepointSubmission.isLast && (
              <AddTimepointSubmissionChip
                timepointId={timepointSubmission.timepoint_id || ''}
                subjectId={subjectId}
                data-testid={`add-timepoint-chip-${index}`}
              />
            )
          )}
        </TableCell>
      ))}
    </TableRow>
  )
}
