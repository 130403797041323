import { useEffect, useState } from 'react'
import { hasAuthParams, useAuth } from 'react-oidc-context'
import { ProtectedAppProps } from '../auth.types'
import { useUserPermissions } from '@auth/hooks/useAuthQueries'

export function ProtectedApp(props: ProtectedAppProps) {
  const { children } = props

  const auth = useAuth()

  const [hasTriedSignin, setHasTriedSignin] = useState(false)

  const {
    data: permissions,
    isLoading: isLoadingPermissions,
    isError: isErrorPermissions,
  } = useUserPermissions({
    enabled: auth.isAuthenticated,
  })

  useEffect(() => {
    if (!(hasAuthParams() || auth.isAuthenticated || auth.activeNavigator || auth.isLoading || hasTriedSignin)) {
      void auth.signinRedirect()
      setHasTriedSignin(true)
    }
  }, [auth, hasTriedSignin])

  if (auth.isLoading || isLoadingPermissions) {
    return <h1>Loading...</h1>
  }

  if (auth.error || isErrorPermissions) {
    return (
      <div>
        <h1>We've hit a snag</h1>
      </div>
    )
  }

  if (auth.isAuthenticated && permissions) {
    return <>{children}</>
  }

  return <h1>We've hit a snag</h1>
}
