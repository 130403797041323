import { Chip, ChipOwnProps } from '@mui/material'
import { TimepointSubmissionStatus } from '@common/config/api/client'

export type TimePointSubmissionStatusChipProps = {
  status?: TimepointSubmissionStatus
  size?: ChipOwnProps['size']
}

const StatusLabelMap: Record<TimepointSubmissionStatus, { label: string; color: ChipOwnProps['color'] }> = {
  LOCKED: {
    label: 'Locked',
    color: 'default',
  },
  IN_PROGRESS: {
    label: 'In progress',
    color: 'warning-alt',
  },
  COMPLETE: {
    label: 'Complete',
    color: 'success-alt',
  },
  NOT_STARTED: {
    label: 'Not started',
    color: 'default',
  },
  MISSED: {
    label: 'Missed',
    color: 'error-alt',
  },
}

export default function TimePointSubmissionStatusChip({ status, size = 'medium' }: TimePointSubmissionStatusChipProps) {
  if (!status) {
    return null
  }

  return (
    <Chip
      data-testid="timpoint-submission-status-chip"
      label={StatusLabelMap[status].label}
      color={StatusLabelMap[status].color}
      size={size}
      sx={{
        padding: 0.25,
        borderRadius: 100,
      }}
    />
  )
}
