import { FormDefinitionsParams } from './types'

const queryKeyFactory = {
  formMetadata: () => ['formMetadata'],
  formDefinition: (formId: string) => ['formDefinition', formId],
  formDefinitions: (params: FormDefinitionsParams) => [
    'formDefinitions',
    params.form_type,
    params.search,
    params.limit,
    params.next_cursor,
    params.previous_cursor,
    params.order_by_field,
    params.asc_order,
  ],
}

export default queryKeyFactory
