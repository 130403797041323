import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  updateFormDefinitionFormsFormDefinitionIdPut,
  FormDefinitionRecord,
  UpdateFormSchema,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

export default function useUpdateFormDefinition(): UseMutationResult<
  FormDefinitionRecord,
  AxiosError,
  { formId: string; formData: UpdateFormSchema }
> {
  return useMutation({
    mutationFn: async ({ formId, formData }) => {
      const response = await updateFormDefinitionFormsFormDefinitionIdPut({
        path: { form_definition_id: formId },
        body: formData,
        throwOnError: true,
      })
      return response.data
    },
  })
}
