import { Box, Button, Typography } from '@mui/material'
import Uppy from '@uppy/core'
import { useEffect, useState } from 'react'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined'
import RestartAltRounded from '@mui/icons-material/RestartAltRounded'

type UploadStepProps = {
  uppy: Uppy
}

export default function UploadStep({ uppy }: UploadStepProps) {
  const [uploadInfo, setUploadInfo] = useState({
    state: 'PROGRESS',
    progress: 0,
  })

  // If this hook is triggered twice for you during development - remove StrictMode from main.tsx
  useEffect(() => {
    const state = uppy.getState()

    if (state.currentUploads && Object.keys(state.currentUploads).length) {
      uppy.restore(Object.keys(state.currentUploads)[0])
    } else {
      uppy.upload()
    }

    uppy.on('complete', () => {
      console.log('complete')
      setUploadInfo({ state: uppy.getState().error ? 'ERROR' : 'COMPLETE', progress: 100 })
    })
    uppy.on('progress', (progress) => {
      setUploadInfo({ state: 'PROGRESS', progress })
    })
    uppy.on('error', () => {
      setUploadInfo({ state: 'ERROR', progress: 0 })
    })
  }, [])

  const handleRetryClick = () => {
    setUploadInfo({ state: 'PROGRESS', progress: 0 })
    uppy.retryAll()
  }

  const renderProgressBlock = () => {
    return (
      <>
        <Box position="relative" display="flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={228}
            thickness={2}
            sx={() => ({
              color: '#C9E8F2',
            })}
          />
          <CircularProgress
            variant="determinate"
            value={uploadInfo.progress}
            size={228}
            thickness={2}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              position: 'absolute',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            })}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3" component="div" sx={{ color: 'primary.dark' }}>
              {`${Math.round(uploadInfo.progress)}%`}
            </Typography>
          </Box>
        </Box>

        <Typography mt={4} sx={{ color: 'primary.dark' }}>
          Please wait, we’re uploading your files
        </Typography>
      </>
    )
  }

  const renderCompleteBlock = () => {
    return (
      <>
        <Box position="relative" display="flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={228}
            thickness={2}
            sx={() => ({
              color: '#C9E8F2',
            })}
          />
          <CircularProgress
            variant="determinate"
            value={100}
            size={228}
            thickness={2}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              position: 'absolute',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            })}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloudDoneOutlinedIcon sx={{ color: 'primary.dark', fontSize: 64 }} />
          </Box>
        </Box>

        <Typography mt={4} sx={{ color: 'primary.dark' }}>
          Files uploaded!
        </Typography>
      </>
    )
  }

  const renderErrorBlock = () => {
    return (
      <>
        <Box position="relative" display="flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={228}
            thickness={2}
            sx={(theme) => ({
              color: theme.palette.error.main,
            })}
          />
          <CircularProgress
            variant="determinate"
            value={100}
            size={228}
            thickness={2}
            sx={(theme) => ({
              color: theme.palette.error.main,
              position: 'absolute',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            })}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloudDoneOutlinedIcon sx={{ color: 'error.main', fontSize: 64 }} />
          </Box>
        </Box>

        <Typography mt={4} sx={{ color: 'error.main' }}>
          Uploading failed
        </Typography>
        <Box display="flex" gap={2} mt={6}>
          <Button variant="text" size="large" color="secondary">
            Contact support
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            startIcon={<RestartAltRounded />}
            onClick={handleRetryClick}
          >
            Retry
          </Button>
        </Box>
      </>
    )
  }

  return (
    <Box
      position="relative"
      display="flex"
      width={1}
      height={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {uploadInfo.state === 'PROGRESS' && renderProgressBlock()}
      {uploadInfo.state === 'COMPLETE' && renderCompleteBlock()}
      {uploadInfo.state === 'ERROR' && renderErrorBlock()}
    </Box>
  )
}
