import { InfoRounded } from '@mui/icons-material'
import { styled } from '@mui/material'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { ReactElement } from 'react'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltipArrow}`]: {
    maxWidth: 200,
    backgroundColor: 'tooltip.fill',
    textAlign: 'center',
  },
})

export default function ArrowTooltips({
  message,
  children,
  placement = 'bottom',
}: {
  message: string
  children?: ReactElement
  placement?: 'top' | 'right' | 'bottom' | 'left'
}) {
  return (
    <CustomTooltip title={message} arrow placement={placement}>
      {children ? (
        children
      ) : (
        <InfoRounded
          fontSize="small"
          sx={{
            color: 'text.secondary',
          }}
          data-testid="arrow-tooltip-icon"
        />
      )}
    </CustomTooltip>
  )
}
