import { AppBar, Box, Button, Dialog, DialogContent, Typography } from '@mui/material'
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react'

import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import { FormBuilderJsonResponse, FormDefinitionPayload } from '@study_setup/types/form_definition'
import { useEffect } from 'react'

type FormBuilderDialogProps = {
  onClose: () => void
  header: string
  onSave: (data: FormBuilderJsonResponse) => void
  data?: FormDefinitionPayload | null
}

export default function FormBuilderDialog({ onClose, header, onSave, data }: FormBuilderDialogProps) {
  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    questionTypes: ['text', 'checkbox', 'radiogroup', 'dropdown'],
    showSidebar: false,
    toolboxLocation: 'left',
  }
  const creator = new SurveyCreator(creatorOptions)

  creator.onShowingProperty.add(function (_, options) {
    if (options.obj.getType() == 'panel' || options.obj.getType() == 'survey') {
      options.canShow = options.property.name == 'title'
    }
  })

  const handleSave = () => {
    const surveyData = creator.getSurveyJSON()
    onSave(surveyData)
  }

  useEffect(() => {
    if (data && data.fields) {
      creator.JSON = {
        title: data.name,
        pages: [
          {
            title: data.name,
            elements: data.fields,
          },
        ],
      }
    } else {
      creator.JSON = {}
    }
  }, [data, creator])

  return (
    <Dialog fullScreen open={true} onClose={() => null}>
      <AppBar sx={{ position: 'relative', bgcolor: '#F0F4F5' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Typography variant="subtitle1" color="text.primary" ml={1}>
            {header}
          </Typography>
          <Box display="flex">
            <Button variant="contained" sx={{ mr: 1 }} onClick={handleSave}>
              Save
            </Button>
            <Button variant="text" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </AppBar>
      <DialogContent sx={{ p: 0 }}>
        <SurveyCreatorComponent creator={creator} />
      </DialogContent>
    </Dialog>
  )
}
