import { useQuery } from '@tanstack/react-query'
import {
  getStudyHomepageStudiesHomepageGet,
  GetStudyHomepageStudiesHomepageGetData,
  Pagination,
} from '@common/config/api/client'

export const useStudiesHomepage = (
  queryKey: string[],
  paginationParams: GetStudyHomepageStudiesHomepageGetData['query'],
) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getStudyHomepageStudiesHomepageGet({
        query: paginationParams,
      }),
    select: ({ data }) => {
      return {
        studies: data?.items,
        pagination: data?.pagination,
      }
    },
  })

  const { studies = [] } = data || {}
  const { pagination = {} as Pagination } = data || {}

  return {
    studies,
    pagination,
    isLoading,
    isError,
    error,
  }
}
