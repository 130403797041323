import { MenuItem } from '@mui/material'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { FormActionMenuProps } from '../types'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'

export default function FormActionMenu({ anchorEl, onClose, onDuplicate, onDelete }: Readonly<FormActionMenuProps>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      <MenuItem onClick={onDuplicate}>
        <ContentCopyRoundedIcon sx={{ mr: 1.5 }} />
        Duplicate Form
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <DeleteOutlineOutlinedIcon sx={{ mr: 1.5 }} />
        Delete Form
      </MenuItem>
    </BaseDropdownMenu>
  )
}
