import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import FolderIcon from '@mui/icons-material/FolderRounded'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  calculateNameColumnWidth,
  calculateSelectedFiles,
  countFiles,
  formatFileSize,
  hasFolders,
  calculateFolderSize,
} from '../../utils'
import dayjs from 'dayjs'
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  Paper,
  useTheme,
  Box,
  Button,
} from '@mui/material'

import { useState, Fragment, useRef, ReactNode, useEffect } from 'react'
import { FileSystemItem } from '../../subject.types'

type FileTableProps = {
  data: FileSystemItem[]
  onDelete: (ids: string[]) => void
}

const FilesTree = ({ onDelete, data }: FileTableProps) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<string[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState<number>(0)
  const showFolderColumn = hasFolders(data)

  const handleToggleExpand = (id: string) => {
    setExpanded((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]))
  }

  const gatherIds = (node: FileSystemItem): string[] => {
    const childIds = node.children?.flatMap(gatherIds) || []
    return [node.id, ...childIds]
  }

  const toggleSelectAll = (itemIds: string[], select: boolean) => {
    setSelected((prev) => (select ? [...new Set([...prev, ...itemIds])] : prev.filter((id) => !itemIds.includes(id))))
  }

  const handleSelectAll = (select: boolean) => {
    const allIds = data.flatMap(gatherIds)
    toggleSelectAll(allIds, select)
  }

  const handleSelect = (item: FileSystemItem) => {
    const isSelectedItem = selected.includes(item.id)
    toggleSelectAll(gatherIds(item), !isSelectedItem)
  }

  const isExpanded = (id: string) => expanded.includes(id)
  const isSelected = (id: string) => selected.includes(id)

  const isIndeterminate = (item: FileSystemItem): boolean => {
    if (item.type === 'file' || !item.children) return false
    const itemIds = gatherIds(item)
    const selectedIds = itemIds.filter((id) => selected.includes(id))
    return selectedIds.length > 0 && selectedIds.length < itemIds.length
  }

  useEffect(() => {
    if (tableContainerRef.current) {
      setContainerWidth(tableContainerRef.current.offsetWidth)
    }

    const handleResize = () => {
      if (tableContainerRef.current) {
        setContainerWidth(tableContainerRef.current.offsetWidth)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const renderRow = (item: FileSystemItem, level: number = 0): ReactNode[] => {
    const hasChildren = item.type === 'folder' && item.children && item.children.length > 0
    const nameColumnWidth = calculateNameColumnWidth(containerWidth || 0, level)

    const row = (
      <Fragment key={item.id}>
        <TableRow sx={{ px: 2 }}>
          <TableCell
            style={{ paddingLeft: level * 42 + 16, width: showFolderColumn ? 56 + level * 42 : 0 }}
            padding="none"
            align="left"
          >
            {hasChildren ? (
              <IconButton aria-label={`Toggle ${item.name} (${item.id})`} onClick={() => handleToggleExpand(item.id)}>
                {isExpanded(item.id) ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              </IconButton>
            ) : null}
          </TableCell>

          <TableCell align="left" style={{ width: 40 }} padding="none">
            <Checkbox
              checked={isSelected(item.id)}
              indeterminate={isIndeterminate(item)}
              onChange={() => handleSelect(item)}
              inputProps={{
                'aria-labelledby': `row-label-${item.id}`,
              }}
            />
          </TableCell>

          <TableCell
            id={`row-label-${item.id}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: `${nameColumnWidth}px`,
              flexShrink: 1,
              pl: 1,
            }}
          >
            {item.type === 'folder' && <FolderIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />}
            <Typography variant="body2" display="inline" noWrap>
              {item.name}
            </Typography>
          </TableCell>

          <TableCell align="left" style={{ width: 120 }} padding="none">
            {item.type}
          </TableCell>
          <TableCell align="left" style={{ width: 120 }} padding="none">
            {formatFileSize(item.type === 'folder' ? calculateFolderSize(item) : item.size || 0)}
          </TableCell>
          <TableCell align="left" style={{ width: 120 }} padding="none">
            {dayjs(item.date).format('DD.MM.YYYY')}
          </TableCell>
          <TableCell align="left" style={{ width: 60 }} padding="none">
            <IconButton onClick={() => onDelete([item.id])} aria-label={`Delete ${item.name}`}>
              <DeleteOutlineRoundedIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
          </TableCell>
        </TableRow>

        {hasChildren && (
          <TableRow sx={{ borderBottom: 'none !important' }}>
            <TableCell colSpan={7} padding="none">
              <Collapse in={isExpanded(item.id)} timeout="auto" unmountOnExit>
                <Table>
                  <TableBody>{item.children?.flatMap((child) => renderRow(child, level + 1))}</TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    )

    return [row]
  }

  const allIds = data.flatMap(gatherIds)
  const allSelected = allIds.every((id) => selected.includes(id))
  const someSelected = allIds.some((id) => selected.includes(id)) && !allSelected
  const totalFilesCount = countFiles(data)
  const selectedFiles = calculateSelectedFiles(data, selected)

  if (!data.length) {
    return null
  }

  return (
    <>
      <Box py={1.25} mt={2} pl={3} display="flex" alignItems="center" justifyContent="space-between">
        {selectedFiles.length ? (
          <Typography variant="subtitle2">
            {selectedFiles.length} {selectedFiles.length > 1 ? 'files' : 'file'} selected
          </Typography>
        ) : (
          <Typography variant="subtitle2">
            {totalFilesCount} {totalFilesCount > 1 ? 'files' : 'file'} added
          </Typography>
        )}
        <Button
          sx={{ visibility: selectedFiles.length ? 'visible' : 'hidden' }}
          variant="contained"
          size="medium"
          color="secondary"
          startIcon={<DeleteOutlineRoundedIcon />}
          onClick={() => onDelete(selected)}
        >
          Delete
        </Button>
      </Box>
      <Paper elevation={3} sx={{ mb: 20, borderRadius: 4, overflow: 'hidden' }}>
        <TableContainer ref={tableContainerRef}>
          <Table
            sx={{
              borderCollapse: 'collapse',
              '& .MuiTableCell-root': {
                borderBottom: 'none',
              },
              '& .MuiTableRow-root': {
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="none" />
                <TableCell align="left" style={{ width: 40 }} padding="none">
                  <Checkbox
                    indeterminate={someSelected}
                    checked={allSelected}
                    onChange={() => handleSelectAll(!allSelected)}
                    inputProps={{
                      'aria-label': 'Select all',
                    }}
                  />
                </TableCell>
                <TableCell align="left" sx={{ pl: 1 }}>
                  Name
                </TableCell>
                <TableCell align="left" style={{ width: 120 }} padding="none">
                  Kind
                </TableCell>
                <TableCell align="left" style={{ width: 120 }} padding="none">
                  Size
                </TableCell>
                <TableCell align="left" style={{ width: 120 }} padding="none">
                  Date
                </TableCell>
                <TableCell align="left" style={{ width: 60 }} padding="none" />
              </TableRow>
            </TableHead>
            <TableBody>{data.flatMap((item) => renderRow(item))}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default FilesTree
