import { Box, Chip, Divider, Typography } from '@mui/material'
import CardActions from '@mui/material/CardActions'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import { ReactNode, useState } from 'react'
import { styled } from '@mui/material/styles'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

const ExpandMore = styled('div')(({ theme }) => ({
  display: 'flex',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export type GroupedFilesBlockProps = {
  title: string
  subtitle: string
  children: ReactNode
  status?: 'PASSED'
  actions: ReactNode
}

export default function GroupedFilesBlock({ title, subtitle, children, status, actions }: GroupedFilesBlockProps) {
  const [expanded, setExpanded] = useState(true)

  return (
    <Card sx={{ p: 3, borderRadius: 4 }} elevation={1}>
      <CardActions
        disableSpacing
        sx={{ p: 0, cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center' }}
        onClick={() => setExpanded(!expanded)}
        aria-label="Expand or collapse"
      >
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          {status && status === 'PASSED' ? (
            <Chip
              icon={<CheckCircleRoundedIcon color="success" />}
              label="Passed"
              variant="filled"
              color="success-alt"
              sx={{ mr: 3 }}
            />
          ) : null}
          {actions}
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} variant="middle" />
          <ExpandMore
            sx={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <KeyboardArrowDownRoundedIcon />
          </ExpandMore>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Card>
  )
}
