import { Stack } from '@mui/material'
import Grid from '@mui/material/Grid2'
import TimepointDetails from './TimepointDetails'
import Exams from './Exams'
import StudyDefinition from './StudyDefinition'
import QCForm from './QCForm'
import ScrollMenu from './ScrollMenu'
import { useRef } from 'react'
import { QcTaskTimepointSchema } from '@common/config/api/client'

const menuItems = ['Timepoint details', 'Exams', 'Study and series definition', 'QC form']

export default function QCDetailsOverview({ qcTask }: { qcTask: QcTaskTimepointSchema }) {
  const timepointDetailsRef = useRef<HTMLDivElement>(null)
  const examsRef = useRef<HTMLDivElement>(null)
  const studyDefinitionRef = useRef<HTMLDivElement>(null)
  const qcFormRef = useRef<HTMLDivElement>(null)

  const handleScroll = (section: string) => {
    switch (section) {
      case menuItems[0]:
        timepointDetailsRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case menuItems[1]:
        examsRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case menuItems[2]:
        studyDefinitionRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case menuItems[3]:
        qcFormRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      default:
        break
    }
  }
  return (
    <Grid container size={12} paddingX={10}>
      <Grid
        size={8}
        height={900}
        overflow="auto"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none', // Hides the scrollbar for WebKit-based browsers
          },
          scrollbarWidth: 'none', // Hides the scrollbar for Firefox
        }}
      >
        <Stack padding={1} />
        <Stack spacing={2}>
          <div ref={timepointDetailsRef}>
            <TimepointDetails qcTask={qcTask} />
          </div>
          <div ref={examsRef}>
            <Exams />
          </div>
          <div ref={studyDefinitionRef}>
            <StudyDefinition />
          </div>
          <div ref={qcFormRef}>
            <QCForm />
          </div>
        </Stack>
      </Grid>
      <Grid size={4}>
        <ScrollMenu menuItems={menuItems} onMenuItemClick={handleScroll} />
      </Grid>
    </Grid>
  )
}
