import { createContext, ReactNode, useContext } from 'react'
import { useUserPermissions } from '@auth/hooks/useAuthQueries'
import { PermissionsContextType } from '@auth/types/permissions'
import { PermissionUtilsFactory } from '@auth/utils/permissionsUtilsFactory'
import { Permission } from '@auth/permissionsEnum'

export const GlobalPermissionsContext = createContext<PermissionsContextType | undefined>(undefined)

export function GlobalPermissionsProvider({ children }: { children: ReactNode }) {
  const { data: permissions = [], isLoading } = useUserPermissions()
  const permissionUtils = PermissionUtilsFactory(permissions)
  return (
    <GlobalPermissionsContext.Provider
      value={{
        permissions: permissions as Permission[],
        ...permissionUtils,
        isLoading,
      }}
    >
      {children}
    </GlobalPermissionsContext.Provider>
  )
}

export const useGlobalPermissions = () => {
  const context = useContext(GlobalPermissionsContext)
  if (!context) {
    throw new Error('useGlobalPermissions must be used within GlobalPermissionsProvider')
  }
  return context
}
