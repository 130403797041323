import { Status } from '@common/config/api/client'

export const STUDY_STATUS_COLOR: Record<Status, string> = {
  DRAFT: 'secondary.dark',
  UAT: 'secondary.main',
  LIVE: '#C9E8F2',
  CLOSE_OUT: '#D7F5DB',
  ON_HOLD: 'warning-alt.main',
  CANCELLED: 'error-alt.main',
}

export const STUDY_STATUS_TEXT_COLOR: Record<Status, string> = {
  DRAFT: 'text.primary',
  UAT: 'text.primary',
  LIVE: '#002530',
  CLOSE_OUT: '#1B5E24',
  ON_HOLD: 'warning-alt.contrastText',
  CANCELLED: 'error-alt.contrastText',
}
