import { AddRounded } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { DEFAULT_SITE } from '@common/constants/study'
import { useCreateTimepointSubmission } from '@features/subject/hooks/useCreateTimepointSubmission'

export default function AddTimepointSubmissionChip({
  subjectId,
  timepointId,
}: {
  subjectId: string
  timepointId: string
}) {
  const { mutateAsync: createTimepointSubmission } = useCreateTimepointSubmission()
  const { studyId } = useParams<{ studyId: string }>()

  const handleCreateTimepointSubmission = async () => {
    await createTimepointSubmission({
      body: {
        timepoint_id: timepointId,
      },
      path: {
        site_id: DEFAULT_SITE.site_id,
        study_id: studyId!,
        subject_id: subjectId,
      },
    })
  }

  return (
    <Chip
      data-testid="add-timepoint-chip"
      size="small"
      icon={<AddRounded data-testid="add-icon" />}
      sx={{
        backgroundColor: '#F0F3F5',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        borderRadius: '8px',
        width: 'auto',
        '& .MuiChip-label': { padding: 0 },
        paddingY: 2,
      }}
      onClick={handleCreateTimepointSubmission}
    ></Chip>
  )
}
