import Grid from '@mui/material/Grid2'
import { ReactNode } from 'react'

interface EnhancedGridProps<T> {
  items: T[]
  renderItem: (item: T) => ReactNode
  spacing?: number
}

function EnhancedGrid<T>({ items, renderItem, spacing = 2 }: EnhancedGridProps<T>) {
  return (
    <Grid container spacing={spacing} data-testid="grid">
      {items.map((item, index) => (
        <Grid
          size={{
            sm: 6,
            lg: 4,
          }}
          sx={{
            gridColumn: {
              llg: 'span 3',
            },
          }}
          key={index}
          data-testid={`grid-item-${index}`}
        >
          {renderItem(item)}
        </Grid>
      ))}
    </Grid>
  )
}

export default EnhancedGrid
