import { FormControlLabel, FormControlLabelProps, Switch as MUISwitch, SwitchProps } from '@mui/material'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'

type UpdatedFormControlLabelProps = Omit<FormControlLabelProps, 'control'>

export const Switch = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  rules,
  MUISwitchProps,
  ...rest
}: UpdatedFormControlLabelProps & UseControllerProps<T> & { MUISwitchProps?: SwitchProps }) => {
  const {
    field: { value, ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  return (
    <>
      <FormControlLabel
        inputRef={ref}
        slotProps={{
          typography: {
            color: error && error.message ? 'error' : 'text.primary',
          },
        }}
        control={<MUISwitch {...field} checked={value || false} {...MUISwitchProps} />}
        {...rest}
      />
    </>
  )
}
