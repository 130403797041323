import { QcTaskState } from '@common/config/api/client'

export const TASK_STATE_COLOR: Record<QcTaskState, string> = {
  NOT_ASSIGNED: 'secondary.main',
  NOT_STARTED: 'info-alt.main',
  IN_PROGRESS: 'warning-alt.main',
  COMPLETE: 'success-alt.main',
  REOPENED: 'warning-alt.main',
  RESET: 'warning-alt.main',
  RESET_PENDING_APPROVAL: 'warning-alt.main',
  QC_FAILED: 'error-alt.main',
  EXCLUDED: 'secondary.main',
}

export const TASK_STATE_TEXT_COLOR: Record<QcTaskState, string> = {
  NOT_ASSIGNED: 'text.primary',
  NOT_STARTED: 'info-alt.contrastText',
  IN_PROGRESS: 'warning-alt.contrastText',
  COMPLETE: 'success-alt.contrastText',
  REOPENED: 'warning-alt.contrastText',
  RESET: 'warning-alt.contrastText',
  RESET_PENDING_APPROVAL: 'warning-alt.contrastText',
  QC_FAILED: 'error-alt.contrastText',
  EXCLUDED: 'text.primary',
}
