import { PaginatedResponse_SeriesLabelRecord_, SeriesLabelMetadata } from '@common/config/api/client'
import { TransformedSeriesLabels, TransformedSeriesLabelsMetadata } from '.'
export const transformGetSeriesLabelsResponse = (
  data: PaginatedResponse_SeriesLabelRecord_ | undefined,
): TransformedSeriesLabels => {
  return {
    items:
      data?.items.map((item) => ({
        id: item.id,
        name: item.name,
        allow_qc_to_duplicate: item.allow_qc_to_duplicate,
        createdAt: item.created_at
          ? new Date(item.created_at)
              .toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })
              .replace(/\./g, '/')
          : null,
        updatedAt: item.updated_at
          ? new Date(item.updated_at)
              .toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })
              .replace(/\./g, '/')
          : null,
        createdByUser: item.created_by_user,
      })) ?? [],
    pagination: {
      nextCursor: data?.pagination.next_cursor ?? null,
      previousCursor: data?.pagination.previous_cursor ?? null,
      limit: data?.pagination.limit ?? null,
    },
  }
}

export const transformGetSeriesLabelMetadataResponse = (data: SeriesLabelMetadata): TransformedSeriesLabelsMetadata => {
  return {
    imaging_modalities: data.imaging_modalities.map((item) => ({ label: item.name, value: item.id })),
    imaging_modality_categories: data.imaging_modality_categories.map(([value, label]) => ({ label, value })),
  }
}
