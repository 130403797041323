import { Permission } from '@auth/permissionsEnum'

export const PermissionUtilsFactory = (permissions: unknown[]) => {
  const hasPermission = (permission: Permission) => permissions.includes(permission)
  const hasAllPermissions = (requiredPermissions: Permission[]) => requiredPermissions.every(hasPermission)
  const hasAnyPermission = (requiredPermissions: Permission[]) => requiredPermissions.some(hasPermission)

  return {
    hasPermission,
    hasAllPermissions,
    hasAnyPermission,
  }
}

const getAllPermissionForType = (permission: Permission): Permission | null => {
  const resourceType = permission.split(':')[0]

  return `${resourceType}:all` as Permission
}

export const EnhancedPermissionUtilsFactory = (studyPermissions: unknown[], globalPermissions: unknown[]) => {
  const hasPermission = (permission: Permission) => {
    // Check if the user is assigned to the study
    if (studyPermissions.length > 0) {
      return studyPermissions.includes(permission)
    } else {
      const allPermission = getAllPermissionForType(permission)
      if (!allPermission) return false
      // Check if the ALL permission exists in either permissions set
      return studyPermissions.includes(allPermission) || globalPermissions.includes(allPermission)
    }
  }

  const hasAllPermissions = (requiredPermissions: Permission[]) => requiredPermissions.every(hasPermission)

  const hasAnyPermission = (requiredPermissions: Permission[]) => requiredPermissions.some(hasPermission)

  return {
    hasPermission,
    hasAllPermissions,
    hasAnyPermission,
  }
}
