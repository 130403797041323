import { useState, MouseEvent } from 'react'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { queryClient } from '@common/config/api/queryClient'
import { ImagingModality, ImagingModalityCategory, SeriesLabelsParams } from '@features/series_label_management/types'
import { useCreateSeriesLabel, useDeleteSeriesLabel, useUpdateSeriesLabel } from '.'
import { getSeriesLabelQuery, useGetSeriesLabelById } from '.'
import { TransformedSeriesLabels } from '.'
import { SeriesLabelFormValues } from '@features/series_label_management/components/CreateOrUpdateSeriesLabelDialog'
import queryKeyFactory from '@features/series_label_management/queryKeyFactory'
import { useBulkDeleteSeriesLabels } from './useSeriesLabelsMutations'
import { handleMutationError } from '@common/hooks/handleMutationError'

export default function useSeriesLabelManagementHandlers() {
  const [page, setPage] = useState(0)
  const [isSeriesLabelDialogVisible, setIsSeriesLabelDialogVisible] = useState(false)
  const [currentImagingModalityCategory, setCurrentImagingModalityCategory] = useState<ImagingModality | null>(null)
  const [selectedImagingModality, setSelectedImagingModality] = useState<ImagingModality | null>(null)
  const [editingSeriesLabelId, seteditingSeriesLabelId] = useState<string | null>(null)
  const [addMenuAnchor, setAddMenuAnchor] = useState<null | HTMLElement>(null)
  const [seriesLabelsParams, setSeriesLabelsParams] = useState<SeriesLabelsParams>({
    imaging_modality_category: undefined,
    search: undefined,
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'name',
    asc_order: true,
  })

  const { mutate: createSeriesLabel } = useCreateSeriesLabel()
  const { mutate: updateSeriesLabel } = useUpdateSeriesLabel()
  const { mutate: deleteSeriesLabel } = useDeleteSeriesLabel()
  const { mutate: bulkDeleteSeriesLabels } = useBulkDeleteSeriesLabels()
  const { data: editingSeriesLabel, isPending: isEditingSeriesLabelPending } =
    useGetSeriesLabelById(editingSeriesLabelId)

  const handleOpenSeriesLabelMenu = (event: MouseEvent<HTMLElement>) => {
    setAddMenuAnchor(event.currentTarget)
  }

  const handleCloseSeriesLabelMenu = () => {
    setAddMenuAnchor(null)
  }

  const handleImagingModalitySelect = (imagingModality: ImagingModality) => {
    setSelectedImagingModality(imagingModality)
    setIsSeriesLabelDialogVisible(true)
    handleCloseSeriesLabelMenu()
  }

  const handleSaveSeriesLabel = (seriesLabelPayload: SeriesLabelFormValues) => {
    if (!selectedImagingModality) return

    createSeriesLabel(seriesLabelPayload, {
      onSuccess: () => {
        toast(<Toast message="Series label created successfully." variant="success" />)
        setIsSeriesLabelDialogVisible(false)
        setSelectedImagingModality(null)
        queryClient.invalidateQueries({ queryKey: ['seriesLabels'] })
      },
      onError: handleMutationError,
    })
  }

  const handlePageChange = (
    seriesLabels: TransformedSeriesLabels | undefined,
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const isNextPage = newPage > page
    const cursor = isNextPage ? seriesLabels?.pagination.nextCursor : seriesLabels?.pagination.previousCursor

    if (cursor) {
      setSeriesLabelsParams((prev) => ({
        ...prev,
        next_cursor: isNextPage ? cursor : null,
        previous_cursor: isNextPage ? null : cursor,
      }))
      setPage(isNextPage ? page + 1 : page - 1)
    }
  }
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeriesLabelsParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleSearch = (value: string) => {
    setSeriesLabelsParams((prev) => ({
      ...prev,
      search: value || undefined,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = seriesLabelsParams.order_by_field === property && seriesLabelsParams.asc_order
    setSeriesLabelsParams((prev) => ({
      ...prev,
      order_by_field: property,
      asc_order: !isAsc,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleAddClick = (imagingModality: ImagingModality) => {
    setSelectedImagingModality(imagingModality)
    setIsSeriesLabelDialogVisible(true)
  }

  const handleEditClick = (seriesLabelId: string) => {
    seteditingSeriesLabelId(seriesLabelId)
    setIsSeriesLabelDialogVisible(true)
  }

  const handleSaveEditedSeriesLabel = (seriesLabelPayload: SeriesLabelFormValues) => {
    if (!editingSeriesLabelId || !editingSeriesLabel) return

    updateSeriesLabel(
      { seriesLabelId: editingSeriesLabelId, seriesLabelPayload },
      {
        onSuccess: () => {
          toast(<Toast message="Series label updated successfully" variant="success" />)
          seteditingSeriesLabelId(null)
          setIsSeriesLabelDialogVisible(false)
          queryClient.invalidateQueries({ queryKey: ['seriesLabels'] })
          queryClient.invalidateQueries({ queryKey: queryKeyFactory.seriesLabel(editingSeriesLabelId) })
        },
        onError: handleMutationError,
      },
    )
  }

  const handleDeleteSeriesLabel = (seriesLabelId: string) => {
    deleteSeriesLabel(seriesLabelId, {
      onSuccess: () => {
        toast(<Toast message="Series label deleted successfully" variant="success" />)
        queryClient.invalidateQueries({ queryKey: ['seriesLabels'] })
      },
      onError: (error) => {
        toast(<Toast message={error.message || 'Failed to delete series label'} variant="error" />)
      },
    })
  }

  const handleBulkDeleteSeriesLabels = (seriesLabelIds: Array<string>) => {
    bulkDeleteSeriesLabels(seriesLabelIds, {
      onSuccess: () => {
        toast(<Toast message="Series labels deleted successfully" variant="success" />)
        queryClient.invalidateQueries({ queryKey: ['seriesLabels'] })
      },
      onError: (error) => {
        toast(<Toast message={error.message || 'Failed to delete series label'} variant="error" />)
      },
    })
  }
  const handleDuplicateSeriesLabel = async (seriesLabelId: string) => {
    const seriesLabel = await queryClient.fetchQuery(getSeriesLabelQuery(seriesLabelId))
    if (seriesLabel) {
      const duplicatePayload = {
        name: `${seriesLabel.name} (Copy)`,
        allow_qc_to_duplicate: seriesLabel.allow_qc_to_duplicate,
        imaging_modality_id: seriesLabel.imaging_modality?.id,
      }
      createSeriesLabel(duplicatePayload, {
        onSuccess: () => {
          toast(<Toast message="Series label duplicated successfully." variant="success" />)
          queryClient.invalidateQueries({ queryKey: ['seriesLabels'] })
        },
        onError: handleMutationError,
      })
    }
  }

  const handleImagingModalityCategoryChange = (imagingModalityCategory: ImagingModalityCategory | null) => {
    setCurrentImagingModalityCategory(imagingModalityCategory)
  }

  return {
    page,
    isSeriesLabelDialogVisible,
    selectedImagingModality,
    editingSeriesLabelId,
    addMenuAnchor,
    seriesLabelsParams,
    setSeriesLabelsParams,
    editingSeriesLabel,
    currentImagingModalityCategory,
    handleOpenSeriesLabelMenu,
    handleCloseSeriesLabelMenu,
    handleImagingModalitySelect,
    handleSaveSeriesLabel,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    handleSaveEditedSeriesLabel,
    handleDeleteSeriesLabel,
    handleDuplicateSeriesLabel,
    seteditingSeriesLabelId,
    setIsSeriesLabelDialogVisible,
    handleImagingModalityCategoryChange,
    handleBulkDeleteSeriesLabels,
    isEditingSeriesLabelPending,
  }
}
