import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { SideBar, SIDEBAR_WIDTH, COLLAPSED_SIDEBAR_WIDTH } from '@common/components/SideBar'
import { APP_BAR_HEIGHT, AppBar } from '@common/components/AppBar'
import { useSidebar } from '@common/context/SidebarContext'
import { SPACING } from '@common/theme/spacing'

interface MainStyledProps {
  isCollapsed: boolean
}

const MainStyled = styled('main')<MainStyledProps>(({ isCollapsed, theme }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(SPACING.pageSpacingY),
  paddingLeft: theme.spacing(SPACING.pageSpacingX),
  paddingRight: theme.spacing(SPACING.pageSpacingX),
  height: `calc(100vh - ${APP_BAR_HEIGHT})`,
  width: `calc(100% - ${isCollapsed ? COLLAPSED_SIDEBAR_WIDTH : SIDEBAR_WIDTH}px)`,
  marginLeft: isCollapsed ? COLLAPSED_SIDEBAR_WIDTH : SIDEBAR_WIDTH,
}))

interface BaseLayoutProps {
  header?: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
}

export function BaseLayout({ header, footer, children }: BaseLayoutProps) {
  const { isCollapsed } = useSidebar()

  return (
    <>
      <SideBar />
      {header || <AppBar />}
      <MainStyled isCollapsed={isCollapsed}>{children || <Outlet />}</MainStyled>
      {footer}
    </>
  )
}
