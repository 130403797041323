import { Box, Tab, Tabs } from '@mui/material'
import { ReactElement, SyntheticEvent } from 'react'

type SegmentedControlsProps = {
  controls: {
    icon: ReactElement
  }[]
  value: number
  setValue: (val: number) => void
}

const SegmentedControls = ({ controls, value, setValue }: SegmentedControlsProps) => {
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Tabs
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        sx={{
          alignItems: 'center',
          alignContent: 'center',
          bgcolor: 'transparent',
          minHeight: 'unset',
          '& button': {
            borderRadius: 2,
            maxWidth: 36,
            minWidth: 36,
            minHeight: 36,
            maxHeight: 36,
            color: 'text.secondary',
            marginX: 0.25,
          },
          '& button.Mui-selected': {
            bgcolor: 'background.default',
            color: 'text.primary',
            boxShadow: 2,
          },
        }}
        value={value}
        onChange={handleChange}
        aria-label="enhanced tabs"
      >
        {controls.map((control, index) => (
          <Tab key={index} icon={control.icon} />
        ))}
      </Tabs>
    </Box>
  )
}

export default SegmentedControls
