import { useNavigate } from 'react-router-dom'
import { ROUTES, RouteKey } from 'src/routes'

interface NavigationParams {
  [key: string]: string | number
}

export const useAppNavigation = () => {
  const navigate = useNavigate()

  const goTo = (routeKey: RouteKey, params: NavigationParams = {}) => {
    const route = ROUTES[routeKey]

    if (!route) {
      return
    }

    let path = route.path
    // Handle dynamic params in the path, e.g., '/study/:id'
    for (const [key, value] of Object.entries(params)) {
      path = path.replace(`:${key}`, value.toString())
    }
    navigate(path)
  }

  const getRoutePath = (routeKey: RouteKey, params?: Record<string, string | number>): string | undefined => {
    const route = ROUTES[routeKey]
    if (!route) return undefined
    let path = route.path
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        path = path.replace(`:${key}`, value.toString())
      }
    }
    return path
  }

  return { goTo, getRoutePath }
}
