import { SeriesLabelsParams } from './types'

const queryKeyFactory = {
  seriesLabel: (seriesLabelId: string) => ['seriesLabel', seriesLabelId],
  seriesLabelMetadata: () => ['seriesLabelMetadata'],
  seriesLabels: (params: SeriesLabelsParams) => [
    'seriesLabels',
    params.imaging_modality_category,
    params.search,
    params.limit,
    params.next_cursor,
    params.previous_cursor,
    params.order_by_field,
    params.asc_order,
  ],
}

export default queryKeyFactory
