export interface StorageProvider {
  get(key: string): unknown | null
  set(key: string, value: unknown): void
  remove(key: string): void
}

export class SessionStorageProvider implements StorageProvider {
  get(key: string): unknown | null {
    const value = sessionStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }

  set(key: string, value: unknown): void {
    sessionStorage.setItem(key, JSON.stringify(value))
  }

  remove(key: string): void {
    sessionStorage.remove(key)
  }
}

export class LocalStorageProvider implements StorageProvider {
  get(key: string): unknown | null {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }

  set(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value))
  }

  remove(key: string): void {
    localStorage.remove(key)
  }
}
