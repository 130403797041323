import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Typography } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { TextField } from '@common/components/Form/TextField'
import { Dropdown } from '@common/components/Form/Dropdown/Dropdown'
import { CompaniesMetadataSchema } from '@common/config/api/client'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { UserFormData } from '@features/user_management/types'

interface AddUserModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (data: UserFormData) => void
  companiesMetadata: CompaniesMetadataSchema | undefined
}

export default function AddUserModal({ open, onClose, onSubmit, companiesMetadata }: Readonly<AddUserModalProps>) {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('')
  const methods = useForm<UserFormData>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      company_id: '',
      role_id: '',
    },
  })

  const companyOptions = useMemo(() => {
    if (!companiesMetadata?.companies) return []
    return companiesMetadata?.companies.map((company) => ({
      label: company.company_name,
      value: company.company_id,
    }))
  }, [companiesMetadata])

  const roleOptions = useMemo(() => {
    if (!companiesMetadata?.companies || !selectedCompanyId) return []

    const selectedCompany = companiesMetadata.companies.find((company) => company.company_id === selectedCompanyId)

    return (
      selectedCompany?.roles.map((role) => ({
        label: role.role_name,
        value: role.role_id,
      })) ?? []
    )
  }, [companiesMetadata, selectedCompanyId])

  const handleSubmit = (data: UserFormData) => {
    onSubmit(data)
    methods.reset()
    setSelectedCompanyId('')
  }

  const handleCompanyChange = (
    _: SyntheticEvent<Element, Event>,
    value:
      | NonNullable<string | { label: string; value: string }>
      | (string | { label: string; value: string })[]
      | null,
  ) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      setSelectedCompanyId(value.value)
      methods.setValue('company_id', value.value)
      methods.setValue('role_id', '')
    }
  }

  useEffect(() => {
    if (!open) {
      methods.reset()
      setSelectedCompanyId('')
    }
  }, [open, methods])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { p: 1 } }}>
      <DialogTitle>Add new user</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <DialogContent>
            <Stack spacing={3}>
              <Typography variant="h6">1. General information</Typography>
              <Stack direction="row" spacing={2}>
                <TextField
                  control={methods.control}
                  name="name"
                  label="Name"
                  rules={{ required: 'Name is required' }}
                  size="small"
                  sx={{ width: '50%' }}
                />
                <TextField
                  control={methods.control}
                  name="phone"
                  label="Phone number"
                  helperText="Optional"
                  size="small"
                  sx={{ width: '50%' }}
                />
              </Stack>
              <TextField
                control={methods.control}
                name="email"
                label="Email address"
                size="small"
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
              />

              <Typography variant="h6">2. Company information</Typography>
              <Dropdown
                name="company_id"
                label="Company"
                options={companyOptions}
                control={methods.control}
                rules={{ required: 'Company is required' }}
                size="small"
                onChange={handleCompanyChange}
              />
              <Dropdown
                name="role_id"
                label="Role"
                options={roleOptions}
                control={methods.control}
                rules={{ required: 'Role is required' }}
                size="small"
                disabled={!selectedCompanyId}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              Create new user
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}
