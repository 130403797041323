import { ReactNode, useState } from 'react'
import { CurrentActiveTabContext, CurrentActiveTabContextType } from './CurrentActiveTabContext'

export const CurrentActiveTabProvider = ({ children }: { children: ReactNode }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState<number>(0)

  const contextValue: CurrentActiveTabContextType = {
    currentActiveTab,
    setCurrentActiveTab,
  }

  return <CurrentActiveTabContext.Provider value={contextValue}>{children}</CurrentActiveTabContext.Provider>
}
