import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ImagingModalityCategory, SeriesLabelsParams } from '@features/series_label_management/types'

interface UseSeriesLabelModalityNavigationProps {
  imagingModalityCategories?: ImagingModalityCategory[]
  seriesLabelsParams: SeriesLabelsParams
  setSeriesLabelsParams: React.Dispatch<React.SetStateAction<SeriesLabelsParams>>
}

export default function useSeriesLabelModalityCategoryNavigation({
  imagingModalityCategories,
  seriesLabelsParams,
  setSeriesLabelsParams,
}: UseSeriesLabelModalityNavigationProps) {
  const location = useLocation()

  // Set initial imaging modality category when metadata loads
  useEffect(() => {
    if (imagingModalityCategories?.length && !seriesLabelsParams.imaging_modality_category) {
      setSeriesLabelsParams((prev) => ({
        ...prev,
        imaging_modality_category: imagingModalityCategories[0].value,
      }))
    }
  }, [imagingModalityCategories, seriesLabelsParams.imaging_modality_category, setSeriesLabelsParams])

  // Update series label imaging modality category based on URL hash
  useEffect(() => {
    if (imagingModalityCategories) {
      const hash = decodeURIComponent(location.hash.slice(1))
      const matchingImagingModalityCategory = hash
        ? imagingModalityCategories.find((modality) => modality.value === hash)
        : imagingModalityCategories[0]
      if (matchingImagingModalityCategory) {
        setSeriesLabelsParams((prev) => ({
          ...prev,
          imaging_modality_category: matchingImagingModalityCategory.value,
        }))
        if (!hash) {
          window.location.hash = matchingImagingModalityCategory.value
        }
      }
    }
  }, [location.hash, imagingModalityCategories, setSeriesLabelsParams])
}
