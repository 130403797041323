import { formatQueryStatusText } from '@features/subject/utils'
import { Chip } from '@mui/material'

export default function QueryStatusChip({ queryStatus }: { queryStatus: string }) {
  return (
    <Chip
      data-testid="query-status-chip"
      label={formatQueryStatusText(queryStatus)}
      size="small"
      sx={{
        backgroundColor: '#DCE3E5',
        color: '#263238',
        padding: 0.5,
        alignItems: 'center',
      }}
    />
  )
}
