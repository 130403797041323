export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    llg: 1600,
    xl: 1920,
  },
} as const
