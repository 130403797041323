import { Stack, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import InfoIcon from '@mui/icons-material/Info'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export type ToastProps = {
  message: string
  variant?: 'error' | 'success' | 'warning' | 'info'
}

const Toast = ({ message, variant = 'info' }: ToastProps) => {
  const getIcon = () => {
    switch (variant) {
      case 'success':
        return <CheckIcon sx={{ color: 'green' }} />
      case 'error':
        return <ErrorOutlineIcon sx={{ color: 'red' }} />
      case 'warning':
        return <WarningAmberIcon sx={{ color: 'orange' }} />
      case 'info':
      default:
        return <InfoIcon sx={{ color: 'primary' }} />
    }
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ padding: 2 }}>
      {getIcon()}
      <Typography sx={{ marginLeft: 1 }}>{message}</Typography>
    </Stack>
  )
}

export default Toast
