import { TabOptionalComponentContext } from '@common/context/TabOptionalComponentContext'
import { useContext } from 'react'

export const useTabOptionalComponent = () => {
  const context = useContext(TabOptionalComponentContext)
  if (context === undefined) {
    throw new Error('useTabOptionalComponent must be used within a TabOptionalComponentProvider')
  }
  return context
}
