import { Box, IconButton, Menu, MenuItem, Toolbar, AppBar as MuiAppBar, Avatar } from '@mui/material'
import { useState, MouseEvent } from 'react'
import { useAuth } from 'react-oidc-context'
import { Notifications } from '@common/components/Notifications'

export const APP_BAR_HEIGHT = '56px'

export function AppBar() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const auth = useAuth()

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <MuiAppBar position="static" sx={{ bgcolor: 'white', boxShadow: 2 }}>
        <Toolbar sx={{ justifyContent: 'space-between', minHeight: `${APP_BAR_HEIGHT} !important` }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
            <Notifications />
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 2 }}>
              <Avatar alt={auth.user?.profile.name} src="/images/avatar.png" sx={{ width: 32, height: 32 }} />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => void auth.signoutRedirect()}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}
