import { ReactNode, useState } from 'react'
import { CurrentUserGroupContext, CurrentUserGroupContextType, CurrentUserGroup } from './CurrentUserGroupContext'
import { RouteConfig } from 'src/routes'

export const CurrentUserGroupProvider = ({ children }: { children: ReactNode }) => {
  const [currentUserGroup, setCurrentUserGroup] = useState<CurrentUserGroup>('IQM')
  const [navigationRoutes, setNavigationRoutes] = useState<RouteConfig[]>([])

  const contextValue: CurrentUserGroupContextType = {
    currentUserGroup,
    navigationRoutes,
    setCurrentUserGroup,
    setNavigationRoutes,
  }

  return <CurrentUserGroupContext.Provider value={contextValue}>{children}</CurrentUserGroupContext.Provider>
}
