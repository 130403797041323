import { SIDEBAR_WIDTH, COLLAPSED_SIDEBAR_WIDTH } from '@common/components/SideBar'
import { useSidebar } from '@common/context/SidebarContext'
import { Box, Toolbar, AppBar, Stack, Typography, Button, CircularProgress } from '@mui/material'
import { useMemo } from 'react'

type StudyHeaderProps = {
  handleSaveAsDraft: () => void
  isSubmitting: boolean
}

function StudyHeader({ handleSaveAsDraft, isSubmitting }: StudyHeaderProps) {
  const { isCollapsed } = useSidebar()
  const currentSidebarWidth = useMemo(() => (isCollapsed ? COLLAPSED_SIDEBAR_WIDTH : SIDEBAR_WIDTH), [isCollapsed])
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 10,
        width: `calc(100% - ${currentSidebarWidth}px)`,
      }}
    >
      <AppBar position="static" sx={{ bgcolor: 'white', boxShadow: 2 }}>
        <Toolbar sx={{ justifyContent: 'space-between', minHeight: '56px !important' }}>
          <Stack>
            <Typography color="textPrimary" variant="subtitle2">
              Last save
            </Typography>
            <Typography color="textSecondary" variant="caption">
              from 29.08.2024
            </Typography>
          </Stack>
          <Button
            onClick={handleSaveAsDraft}
            color="secondary"
            variant="contained"
            size="large"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? 'Saving...' : 'Save as draft'}
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default StudyHeader
