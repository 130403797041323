/**
 * Table component for displaying and managing serie labels with sorting, filtering,
 * and action menu functionality
 */
import { MouseEvent } from 'react'
import { TableBody, TableCell, TableRow, Paper, Checkbox, IconButton, Link, Box, Chip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { SeriesLabelData, ImagingModalityCategory } from '@features/series_label_management/types'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { Order, HeadCell } from '@common/components/Table/table.types'
import SeriesLabelActionMenu from './SeriesLabelActionMenu'
import SeriesLabelManagementTableToolbar from './SeriesLabelManagementTableToolbar'
import {
  TransformedSeriesLabel,
  TransformedSeriesLabels,
  useSeriesLabelManagementTableHandlers,
} from '@features/series_label_management/hooks'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import { NavTabsVariant } from '@common/components/Tab/tabs.types'

interface Props {
  imagingModalityCategories?: ImagingModalityCategory[]
  page: number
  seriesLabels: TransformedSeriesLabels | undefined
  currentImagingModalityCategory: ImagingModalityCategory | null
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearch: (value: string) => void
  searchValue?: string
  order: Order
  orderBy: string
  onRequestSort: (event: MouseEvent<unknown>, property: keyof SeriesLabelData) => void
  onAddClick: (imagingModality: ImagingModalityCategory) => void
  onEditClick: (seriesLabelId: string) => void
  onDeleteSeriesLabel: (seriesLabelId: string) => void
  onDuplicateSeriesLabel: (seriesLabelId: string) => void
  onImagingModalityCategoryChange: (imagingModalityCategory: ImagingModalityCategory | null) => void
  onBulkDeleteSeriesLabels: (seriesLabelIds: Array<string>) => void
}

const getHeadCells = (): readonly HeadCell<SeriesLabelData>[] => {
  const baseCells: HeadCell<SeriesLabelData>[] = [
    { id: 'name', label: 'Series Label', disablePadding: false },
    { id: 'allow_qc_to_duplicate', label: 'QC able to duplicate', disablePadding: false },
    { id: 'created_by', label: 'Created by', disablePadding: false },
  ]
  return [
    ...baseCells,
    { id: 'editSeriesLabel', label: '', disablePadding: false },
    { id: 'actions', label: '', disablePadding: false },
  ]
}
export default function SeriesLabelManagementTable({
  imagingModalityCategories,
  seriesLabels,
  currentImagingModalityCategory,
  onSearch,
  searchValue,
  onRowsPerPageChange,
  handlePageChange,
  page,
  order,
  orderBy,
  onRequestSort,
  onAddClick,
  onEditClick,
  onDeleteSeriesLabel,
  onDuplicateSeriesLabel,
  onImagingModalityCategoryChange,
  onBulkDeleteSeriesLabels,
}: Readonly<Props>) {
  const {
    selected,
    menuAnchorEl,
    handleEditClick,
    handleSelectAllClick,
    handleClick,
    handleOpenMenu,
    handleCloseMenu,
    handleDeleteSeriesLabel,
    handleDuplicateSeriesLabel,
    handleBulkDeleteSeriesLabels,
  } = useSeriesLabelManagementTableHandlers({
    onEditClick,
    onDeleteSeriesLabel,
    onDuplicateSeriesLabel,
    onBulkDeleteSeriesLabels,
    seriesLabels,
  })

  const headCells = getHeadCells()

  const imagingModalityCateogryRoutes =
    imagingModalityCategories?.map((imagingModalityCateogry) => ({
      path: `#${imagingModalityCateogry.value}`,
      label: imagingModalityCateogry.label,
      id: imagingModalityCateogry.value,
    })) ?? []

  return (
    <Box component="section">
      <SeriesLabelManagementTableToolbar
        imagingModalityCategories={imagingModalityCategories}
        onSearch={onSearch}
        searchValue={searchValue}
        onAddClick={onAddClick}
        currentImagingModalityCategory={currentImagingModalityCategory}
        onImagingModalityCategoryChange={onImagingModalityCategoryChange}
      />
      <Paper sx={{ p: 3 }}>
        <EnhancedNavTabs routes={imagingModalityCateogryRoutes} variant={NavTabsVariant.SEGMENTED_CONTROLS} />
        <EnhancedTable<SeriesLabelData>
          rows={seriesLabels?.items ?? []}
          headCells={headCells}
          order={order}
          orderBy={orderBy as keyof SeriesLabelData}
          selected={selected}
          page={page}
          next_cursor={seriesLabels?.pagination.nextCursor}
          previous_cursor={seriesLabels?.pagination.previousCursor}
          rowsPerPage={seriesLabels?.pagination.limit ?? 50}
          rowsPerPageOptions={[10, 25, 50]}
          onRequestSort={onRequestSort}
          onSelectAllClick={handleSelectAllClick}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onRowsPerPageChange}
          onBulkDelete={handleBulkDeleteSeriesLabels}
        >
          <TableBody>
            {seriesLabels?.items.map((row: TransformedSeriesLabel) => {
              const isItemSelected = selected.indexOf(row.id) !== -1

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={row.allow_qc_to_duplicate ? 'Enabled' : 'Disabled'}
                      color={row.allow_qc_to_duplicate ? 'success-alt' : 'warning-alt'}
                    />
                  </TableCell>
                  <TableCell>{row.createdByUser?.name ?? '-'}</TableCell>
                  <TableCell align="right">
                    <Link onClick={(e) => handleEditClick(e, row.id)}>Edit series label</Link>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={(e) => handleOpenMenu(e, row.id)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </EnhancedTable>
        <SeriesLabelActionMenu
          anchorEl={menuAnchorEl}
          onClose={handleCloseMenu}
          onDuplicate={handleDuplicateSeriesLabel}
          onDelete={handleDeleteSeriesLabel}
        />
      </Paper>
    </Box>
  )
}
