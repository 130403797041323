import { EditDocumentIcon, LabsIcon } from '@common/components/Icons'
import { Status } from '@common/config/api/client'
import { formatStatusText } from '@features/study_view/utils'
import { CancelRounded, CheckCircleRounded, PauseCircleRounded, PlayCircleRounded } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

function getStatusIcon(status: Status): ReactNode {
  switch (status) {
    case 'DRAFT':
      return <EditDocumentIcon data-testid="status-icon-draft" />
    case 'UAT':
      return <LabsIcon data-testid="status-icon-uat" />
    case 'LIVE':
      return (
        <PlayCircleRounded
          data-testid="status-icon-live"
          sx={{
            color: 'primary.light',
          }}
        />
      )
    case 'CLOSE_OUT':
      return (
        <CheckCircleRounded
          data-testid="status-icon-close-out"
          sx={{
            color: 'success.main',
          }}
        />
      )
    case 'ON_HOLD':
      return (
        <PauseCircleRounded
          data-testid="status-icon-on-hold"
          sx={{
            color: 'warning.main',
          }}
        />
      )
    case 'CANCELLED':
      return (
        <CancelRounded
          data-testid="status-icon-cancelled"
          sx={{
            color: 'error.main',
          }}
        />
      )
  }
}

export default function StudyStatus({ status }: { status: Status }) {
  return (
    <Stack
      direction={'row'}
      spacing={0.25}
      sx={{
        alignItems: 'center',
      }}
      data-testid="study-status"
    >
      {getStatusIcon(status)}
      <Typography
        variant="body2"
        sx={{
          color: 'text.primary',
        }}
        data-testid="status-text"
      >
        {formatStatusText(status)}
      </Typography>
    </Stack>
  )
}
