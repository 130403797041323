import { Paper, Typography } from '@mui/material'

export default function QCForm() {
  return (
    <Paper
      sx={{
        padding: 3,
        borderRadius: 4,
      }}
    >
      <Typography variant="h6">QC 1 form</Typography>
    </Paper>
  )
}
