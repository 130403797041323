import { Box, Paper, Skeleton, Stack } from '@mui/material'

export default function FormManagementTableSkeleton() {
  return (
    <Box component="section" mt={4}>
      {/* Toolbar Skeleton */}
      <Stack direction="row" spacing={2} sx={{ mb: 2, p: 2 }} alignItems="center">
        {/* Tab Navigation Skeleton */}
        <Stack direction="row" spacing={2}>
          {[1, 2, 3, 4].map((i) => (
            <Skeleton key={i} variant="rectangular" width={100} height={32} sx={{ borderRadius: 1 }} />
          ))}
        </Stack>

        {/* Add Button and Search Skeleton */}
        <Stack direction="row" spacing={2} sx={{ ml: 'auto' }}>
          <Skeleton variant="rectangular" width={120} height={32} sx={{ borderRadius: 1 }} />
          <Skeleton variant="rectangular" width={200} height={32} sx={{ borderRadius: 1 }} />
        </Stack>
      </Stack>

      {/* Table Skeleton */}
      <Paper sx={{ p: 3 }}>
        {/* Table Header Skeleton */}
        <Skeleton variant="rectangular" width="100%" height={52} sx={{ mb: 1 }} />

        {/* Table Rows Skeleton */}
        {[1, 2, 3, 4, 5].map((i) => (
          <Skeleton key={i} variant="rectangular" width="100%" height={52} sx={{ mb: 1, borderRadius: 1 }} />
        ))}

        {/* Pagination Skeleton */}
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
          <Skeleton variant="rectangular" width={300} height={32} sx={{ borderRadius: 1 }} />
        </Stack>
      </Paper>
    </Box>
  )
}
