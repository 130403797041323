import {
  assignQcTaskQcTasksAssignPost,
  AssignQcTaskQcTasksAssignPostResponse,
  Body_assign_qc_task_qc_tasks_assign_post,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useAssignTasksMutation = (): UseMutationResult<
  AssignQcTaskQcTasksAssignPostResponse,
  AxiosError<HTTPValidationError>,
  Body_assign_qc_task_qc_tasks_assign_post
> => {
  return useMutation<
    AssignQcTaskQcTasksAssignPostResponse,
    AxiosError<HTTPValidationError>,
    Body_assign_qc_task_qc_tasks_assign_post
  >({
    mutationFn: async (body) => {
      const response = await assignQcTaskQcTasksAssignPost({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTasksList'] })
    },
  })
}
