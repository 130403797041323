import { useContext } from 'react'
import { CurrentUserGroupContext } from '../context/CurrentUserGroupContext'

export const useUserGroup = () => {
  const context = useContext(CurrentUserGroupContext)
  if (context === undefined) {
    throw new Error('useUserGroup must be used within a CurrentUserGroupProvider')
  }
  return context
}
