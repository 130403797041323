import { useQuery } from '@tanstack/react-query'
import { getFormDefinitionFormsFormDefinitionIdGet } from '@common/config/api/client'
import queryKeyFactory from '../queryKeyFactory'

export default function useGetFormDefinitionById(formId: string | null) {
  return useQuery({
    queryKey: queryKeyFactory.formDefinition(formId!),
    queryFn: async () => {
      const response = await getFormDefinitionFormsFormDefinitionIdGet({
        path: { form_definition_id: formId! },
        throwOnError: true,
      })
      return response.data
    },
    enabled: !!formId,
  })
}
