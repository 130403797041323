import { StorageProvider } from './storageProviders'

export class StorageService {
  private provider: StorageProvider

  constructor(provider: StorageProvider) {
    this.provider = provider
  }

  getPermissions(): Array<unknown> {
    return this.provider.get('userPermissions') as Array<unknown>
  }

  setPermissions(permissions: Array<unknown>): void {
    this.provider.set('userPermissions', permissions)
  }

  clearPermissions(): void {
    this.provider.remove('userPermissions')
  }
}
