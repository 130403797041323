import { Box, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import dayjs from 'dayjs'

type SeriesRowProps = {
  isBorderNeeded: boolean
  description: string
  tooltipText: string
  acquisitionDate: Date
  seriesNumber: string
  instances: number
  modality: string
}

export default function SeriesRow({
  isBorderNeeded,
  description,
  seriesNumber,
  acquisitionDate,
  tooltipText,
  instances,
  modality,
}: SeriesRowProps) {
  const theme = useTheme()

  return (
    <Box
      px={2}
      py={1}
      bgcolor="#F0F4F5"
      borderBottom={isBorderNeeded ? `1px solid ${theme.palette.divider}` : undefined}
      display="flex"
      gap={2}
      alignItems="center"
    >
      <CheckCircleOutlineRoundedIcon sx={{ color: 'success.main' }} />
      <Box flex={5}>
        <Box display="flex" mb={0.25} alignItems="center">
          <Typography variant="subtitle2" color="text.secondary" mr={0.5}>
            Series description
          </Typography>
          <Tooltip title={tooltipText} placement="top" arrow>
            <InfoRoundedIcon sx={{ width: 12, height: 12, color: 'text.secondary' }} />
          </Tooltip>
        </Box>
        <Typography variant="body2">{description}</Typography>
      </Box>
      <Box flex={2}>
        <Typography variant="subtitle2" color="text.secondary" mb={0.25}>
          Acquisition date
        </Typography>
        <Typography variant="body2">{dayjs(acquisitionDate).format('DD/MM/YYYY')}</Typography>
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={0.25}>
          Series №
        </Typography>
        <Typography variant="body2">{seriesNumber}</Typography>
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={0.25}>
          Instances
        </Typography>
        <Typography variant="body2">{instances}</Typography>
      </Box>
      <Box flex={2}>
        <Typography variant="subtitle2" color="text.secondary" mb={0.25}>
          Modality
        </Typography>
        <Typography variant="body2">{modality}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box display="flex" gap={1}>
        <IconButton size="small" aria-label="Checklist">
          <ChecklistRoundedIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" aria-label="Label">
          <LabelOutlinedIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" aria-label="Delete">
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}
