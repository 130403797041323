import { useStepper } from '@common/context/StepperContext'
import { Typography, Stack, Chip, Grid2 as Grid } from '@mui/material'
import { Suspense, lazy, useEffect } from 'react'

const StudyOverview = lazy(() => import('./StudyOverview'))
const TimePointDefinition = lazy(() => import('./TimePointDefinition'))
const QualityControl = lazy(() => import('./QualityControl'))
const DICOMTagsDeId = lazy(() => import('./DICOMTagsDeId'))
const ReadDesign = lazy(() => import('./ReadDesign'))
const Notifications = lazy(() => import('./Notifications'))
const DocumentManagement = lazy(() => import('./DocumentManagement'))

export default function CreateOrUpdateStudy() {
  const { activeStep, setActiveStep } = useStepper()

  useEffect(() => {
    setActiveStep(0)
    return () => {
      setActiveStep(0)
    }
  }, [])

  const stepComponents = [
    () => <StudyOverview />,
    () => <TimePointDefinition />,
    () => <QualityControl />,
    () => <DICOMTagsDeId />,
    () => <ReadDesign />,
    () => <DocumentManagement />,
    () => <Notifications />,
  ]
  const StepComponent = stepComponents[activeStep]

  const getGridWidth = () => {
    if (activeStep === 0 || activeStep === 2 || activeStep === 3) {
      return 8
    }

    return 12
  }

  const getGridOffset = () => {
    if (activeStep === 0 || activeStep === 2 || activeStep === 3) {
      return 2
    }

    return 0
  }

  return (
    <Stack>
      <Grid container>
        <Grid size={{ xs: 12, lg: getGridWidth() }} offset={{ xs: 0, lg: getGridOffset() }}>
          <Stack direction="row" spacing={2} alignItems="center" mb={4}>
            <Typography variant="h4">Create a clinical study</Typography>
            <Chip label="Study setup" color="info-alt" />
          </Stack>
        </Grid>
      </Grid>

      <Suspense fallback={<div>Loading...</div>}>{StepComponent ? <StepComponent /> : null}</Suspense>
    </Stack>
  )
}
