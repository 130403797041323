import { Typography, Stack, Grid2 as Grid } from '@mui/material'
import { Breadcrumb } from '@common/components/Breadcrumb'
import UserTable from './management/components/UserTable'
import { USER_MANAGEMENT_BREADCRUMB_ITEMS } from './constants'
import { useEffect } from 'react'
import AddUserModal from './management/components/AddUserModal'

import { CompaniesUsersReadSchema } from '@common/config/api/client'
import { useLocation } from 'react-router-dom'
import { useUserManagementHandlers } from './hooks'

export default function UserManagement() {
  const location = useLocation()
  const {
    isLoading,
    isAddModalOpen,
    usersList,
    companiesMetadata,
    page,
    userListParams,
    setIsAddModalOpen,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddUser,
    handleDeleteUser,
    handleCompanyTypeChange,
  } = useUserManagementHandlers()

  useEffect(() => {
    handleCompanyTypeChange()
  }, [location.hash, companiesMetadata?.companies, handleCompanyTypeChange])

  if (isLoading) {
    return <div>Loading...</div>
  }
  return (
    <>
      <Breadcrumb items={USER_MANAGEMENT_BREADCRUMB_ITEMS} />
      <Grid container columnSpacing={3} sx={{ marginTop: 4 }}>
        <Grid size={12}>
          <Stack spacing={8}>
            <Typography variant="h4">User management</Typography>
            <UserTable
              companiesMetadata={companiesMetadata}
              usersList={usersList}
              onDeleteUser={handleDeleteUser}
              onAddUser={() => setIsAddModalOpen(true)}
              page={page}
              onPageChange={(event, newPage) => handlePageChange(usersList, event, newPage)}
              onRowsPerPageChange={handleRowsPerPageChange}
              onSearch={handleSearch}
              searchValue={userListParams.search ?? ''}
              orderBy={userListParams.order_by_field as keyof CompaniesUsersReadSchema}
              order={userListParams.asc_order ? 'asc' : 'desc'}
              onRequestSort={handleRequestSort}
            />
          </Stack>
        </Grid>
      </Grid>
      <AddUserModal
        companiesMetadata={companiesMetadata}
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddUser}
      />
    </>
  )
}
