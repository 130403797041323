import { createContext } from 'react'

export type TabOptionalComponentContextType = {
  optionalComponent: React.ReactNode
  isComponentVisible: boolean
  isComponentUsed: boolean
  setOptionalComponent: (optionalComponent: React.ReactNode) => void
  setIsComponentVisible: (isComponentVisible: boolean) => void
  setIsComponentUsed: (isComponentUsed: boolean) => void
}

export const TabOptionalComponentContext = createContext<TabOptionalComponentContextType | undefined>(undefined)
