import { useMemo } from 'react'
import { TableBody, TableRow, TableCell } from '@mui/material'
import PopupMenuList from './PopupMenuList'
import { EnhancedTableBodyProps } from '@reader_task_list_view/reader_task_list.types'
import { formatTaskCreationDate, getComparator } from '@reader_task_list_view/utils'
import TaskStateChip from './TaskStateChip'
import TaskTypeChip from './TaskTypeChip'
import { PlayArrowRounded } from '@mui/icons-material'
import { useAssignTasksMutation } from '@reader_task_list_view/hooks/useAssignTasksMutation'
import Toast from './Toast'
import { toast } from 'sonner'
import { composeOhifReaderLink } from '@common/utils/ohifUtils'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'

export default function EnhancedTableBodyMyTasks({
  rows,
  order,
  orderBy,
  authedUserToken,
  authedUserId,
}: EnhancedTableBodyProps) {
  const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows])
  const { mutateAsync: assignTask } = useAssignTasksMutation()
  const { studyId } = useParams()

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const handleAssignTask = async (task_ids: string[]) => {
    if (authedUserId) {
      await assignTask({
        query: {
          read_task_ids: task_ids,
          study_id: studyId!,
        },
      })
        .then((res) => {
          toast(
            <Toast
              message="You were assigned task"
              task_ids={res.processed_task_ids.map((task) => {
                return task.split('-')[0]
              })}
              variant="success"
              data-testid="success-toast"
            />,
          )
          openInNewTab(composeOhifReaderLink(task_ids[0], studyId!, authedUserToken!))
        })
        .catch((error) => {
          toast(<Toast message={(error as AxiosError).message} variant="error" data-testid="error-toast" />)
        })
    }
  }

  return (
    <TableBody>
      {visibleRows.map((row) => {
        return (
          <TableRow
            tabIndex={-1}
            key={row.task_id}
            sx={{
              borderBottom: '1px solid rgba(220, 227, 229, 1)',
            }}
          >
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="blind-code-cell">
              {row.blind_code}
            </TableCell>
            <TableCell align="left" data-testid="task-type-cell">
              <TaskTypeChip taskType={row.task_type} />
            </TableCell>
            <TableCell align="left" data-testid="priority-cell">
              {row.priority}
            </TableCell>
            <TableCell align="left" data-testid="created-at-cell">
              {formatTaskCreationDate(row.created_at)}
            </TableCell>
            <TableCell align="left" data-testid="due-date-cell">
              {formatTaskCreationDate(row.due_date)}
            </TableCell>
            <TableCell align="left" data-testid="status-cell">
              <TaskStateChip taskState={row.status} />
            </TableCell>
            {row.status === 'IN_PROGRESS' ? (
              <TableCell align="right" data-testid="actions-cell">
                <PopupMenuList read_task_id={row.task_id} read_pseudo_id={row.task_pseudo_id} study_id={studyId!} />
              </TableCell>
            ) : (
              <TableCell
                align="right"
                data-testid="start-cell"
                onClick={() => {
                  handleAssignTask([row.task_id])
                }}
              >
                <PlayArrowRounded
                  sx={{
                    cursor: 'pointer',
                    color: 'text.primary',
                  }}
                />
              </TableCell>
            )}
          </TableRow>
        )
      })}
    </TableBody>
  )
}
