import { useMutation } from '@tanstack/react-query'
import { removeCompanyUserCompaniesCompanyIdUsersCompanyUserIdDelete } from '@common/config/api/client'

interface DeleteUserParams {
  companyId: string
  userId: string
}

export default function useDeleteUser() {
  return useMutation({
    mutationFn: ({ companyId, userId }: DeleteUserParams) =>
      removeCompanyUserCompaniesCompanyIdUsersCompanyUserIdDelete({
        path: {
          company_id: companyId,
          company_user_id: userId,
        },
        throwOnError: true,
      }),
  })
}
