import {
  resetQcTasksResetPost,
  ResetQcTasksResetPostResponse,
  Body_reset_qc_tasks_reset_post,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useResetTasksMutation = (): UseMutationResult<
  ResetQcTasksResetPostResponse,
  AxiosError<HTTPValidationError>,
  Body_reset_qc_tasks_reset_post
> => {
  return useMutation<ResetQcTasksResetPostResponse, AxiosError<HTTPValidationError>, Body_reset_qc_tasks_reset_post>({
    mutationFn: async (body) => {
      const response = await resetQcTasksResetPost({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTasksList'] })
    },
  })
}
