import {
  UnassignQcTaskQcTasksUnassignPostResponse,
  Body_unassign_qc_task_qc_tasks_unassign_post,
  HTTPValidationError,
  unassignQcTaskQcTasksUnassignPost,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useUnassignTasksMutation = (): UseMutationResult<
  UnassignQcTaskQcTasksUnassignPostResponse,
  AxiosError<HTTPValidationError>,
  Body_unassign_qc_task_qc_tasks_unassign_post
> => {
  return useMutation<
    UnassignQcTaskQcTasksUnassignPostResponse,
    AxiosError<HTTPValidationError>,
    Body_unassign_qc_task_qc_tasks_unassign_post
  >({
    mutationFn: async (body) => {
      const response = await unassignQcTaskQcTasksUnassignPost({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTasksList'] })
    },
  })
}
