import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  HTTPValidationError,
  createFormDefinitionFormsPost,
  FormDefinitionRecord,
  CreateFormSchema,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

export const useCreateFormDefinition = (): UseMutationResult<
  FormDefinitionRecord,
  AxiosError<HTTPValidationError>,
  CreateFormSchema
> => {
  return useMutation<FormDefinitionRecord, AxiosError<HTTPValidationError>, CreateFormSchema>({
    mutationFn: async (formData) => {
      const response = await createFormDefinitionFormsPost({
        body: formData,
        throwOnError: true,
      })
      return response.data
    },
  })
}
