import { Box, Typography } from '@mui/material'

export default function Forbidden() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 56px)',
        maxHeight: 'calc(100vh - 56px)',
        gap: 2,
      }}
    >
      <Typography variant="h3">Access Denied</Typography>
      <Typography>You don't have permission to access this page.</Typography>
    </Box>
  )
}
