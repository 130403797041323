import { Chip, SxProps, Theme } from '@mui/material'
import { StudyTimepointSubmission, TimepointSubmissionStatus } from '@common/config/api/client'

function getChipStyle(status: TimepointSubmissionStatus): SxProps<Theme> {
  switch (status) {
    case 'NOT_STARTED':
      return {
        backgroundColor: '#F0F3F5',
        border: '1px dashed #546E7A',
      }
    case 'IN_PROGRESS':
      return {
        backgroundColor: '#FFEFBF',
      }
    case 'COMPLETE':
      return {
        backgroundColor: '#D7F5DB',
      }
    default:
      return {
        backgroundColor: '#F0F3F5',
      }
  }
}

export interface TimepointStatusProps {
  timepoint: StudyTimepointSubmission
}

export default function TimepointSubmissionChip({ timepoint }: TimepointStatusProps) {
  return (
    <Chip
      data-testid="timepoint-status-chip"
      label={timepoint.name}
      size="small"
      sx={{
        ...getChipStyle(timepoint.status),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        width: 'auto',
        paddingY: 2,
      }}
    />
  )
}
