import { Box, Divider, IconButton, Tooltip, Typography, useTheme, TextField, InputAdornment } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { DatePicker } from '@mui/x-date-pickers'

type NonDICOMRowProps = {
  isBorderNeeded: boolean
  description: string
  tooltipText: string
  seriesNumber: string
}

export default function NonDICOMRow({ isBorderNeeded, description, seriesNumber, tooltipText }: NonDICOMRowProps) {
  const theme = useTheme()

  return (
    <Box
      px={2}
      py={1}
      bgcolor="#F0F4F5"
      borderBottom={isBorderNeeded ? `1px solid ${theme.palette.divider}` : undefined}
      display="flex"
      gap={2}
      alignItems="center"
    >
      <Box flex={4.5}>
        <TextField
          label="Series description"
          size="small"
          fullWidth
          value={description}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
            input: {
              sx: {
                bgcolor: 'white',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={tooltipText} placement="top" arrow>
                    <InfoRoundedIcon data-testid="InfoRoundedIcon" />
                  </Tooltip>
                </InputAdornment>
              ),
            },
          }}
          variant="outlined"
        />
      </Box>
      <Box flex={3}>
        <DatePicker
          label="Acquisition date"
          slotProps={{
            textField: {
              size: 'small',
              sx: {
                bgcolor: 'white',
              },
            },
          }}
        />
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={0.25}>
          Series №
        </Typography>
        <Typography variant="body2">{seriesNumber}</Typography>
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={0.25}>
          Instances
        </Typography>
        <Typography variant="body2">1</Typography>
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={0.25}>
          Modality
        </Typography>
        <Typography variant="body2">OT</Typography>
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box display="flex" gap={1}>
        <IconButton size="small" aria-label="View">
          <RemoveRedEyeRoundedIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" aria-label="Delete">
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}
