import { Order } from '@common/components/Table/table.types'
import { Task } from '@common/config/api/client'
import { RouteConfig, RouteKeyEnum, ROUTES } from 'src/routes'
import { QCTaskListTabsKeyEnum } from './constant'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedValue = (obj: Record<string, any>, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

export const getComparator = (order: Order, orderBy: keyof Task | string, getter?: (item: Task) => string) => {
  return order === 'desc'
    ? (a: Task, b: Task) => {
        const aValue = getter ? getter(a) : getNestedValue(a, orderBy)
        const bValue = getter ? getter(b) : getNestedValue(b, orderBy)

        if (aValue === null || aValue === undefined) {
          return 1
        } else if (bValue === null || bValue === undefined) {
          return -1
        }
        return bValue < aValue ? -1 : 1
      }
    : (a: Task, b: Task) => {
        const aValue = getter ? getter(a) : getNestedValue(a, orderBy)
        const bValue = getter ? getter(b) : getNestedValue(b, orderBy)

        if (aValue === null || aValue === undefined) {
          return -1
        } else if (bValue === null || bValue === undefined) {
          return 1
        }
        return aValue < bValue ? -1 : 1
      }
}

export const formatTaskTypeText = (taskType: string) => {
  return taskType
    .split('_')
    .map((word, index) => (index === 0 ? word.toUpperCase() : word.toLowerCase()))
    .join(' ')
}

export const formatTaskStateText = (taskState: string) => {
  const splitTaskState = taskState.split('_')
  const formatedTaskState = splitTaskState.map((word, index) => {
    if (word === 'QC') {
      return word
    } else if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    } else return word.toLowerCase()
  })
  return formatedTaskState.join(' ')
}

export const formatQueryStatusText = (queryStatus: string) => {
  return queryStatus.charAt(0).toUpperCase() + queryStatus.slice(1)
}

export const formatTaskDate = (date: string) => {
  return date.split(' ')[0].split('-').reverse().join('/')
}

export default function getRoutes(): RouteConfig[] {
  const included_routes = [
    RouteKeyEnum.STUDY_DETAILS,
    RouteKeyEnum.SITES,
    RouteKeyEnum.SUBJECTS,
    RouteKeyEnum.QC_TASKS,
    RouteKeyEnum.QUERIES,
  ]

  const routes = Object.values(RouteKeyEnum).filter((route) => included_routes.includes(route))
  return routes.map((route) => {
    return {
      ...ROUTES[route],
      label: ROUTES[route].label.replace('QC ', ''),
    }
  })
}

export function getTabIndex(tab: QCTaskListTabsKeyEnum) {
  return Object.values(QCTaskListTabsKeyEnum).indexOf(tab)
}
