import { useParams } from 'react-router-dom'
import QCDetailsHeader from './QCDetailsHeader'
import QCDetailsOverview from './QCDetailsOverview'
import { useQCTask } from './useQCTask'

export default function QCDetailsView() {
  const { studyId, qcTaskId } = useParams()

  const { data, error, isLoading } = useQCTask(['QCTask'], studyId, qcTaskId)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error || !data || !data.data) {
    return <div>Error: {error?.message}</div>
  }

  return (
    <>
      <QCDetailsHeader qcTask={data.data} />
      <QCDetailsOverview qcTask={data.data} />
    </>
  )
}
