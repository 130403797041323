import { Menu, MenuItem, MenuList } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import SkipModal from './SkipModal'
import ResetModal from './ResetModal'

export default function PopupMenuList({
  read_task_id,
  read_pseudo_id,
  study_id,
}: {
  read_task_id: string
  read_pseudo_id: number
  study_id: string
}) {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <MoreVertRoundedIcon
            fontSize="small"
            {...bindTrigger(popupState)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                borderRadius: '50%',
                backgroundColor: '#F3F4F6',
              },
            }}
            data-testid="popup-menu-icon"
          />
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: 0.5,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  width: '100%',
                }}
                data-testid="popup-menu-list-item-skip"
              >
                <SkipModal
                  read_task_id={read_task_id}
                  read_pseudo_id={read_pseudo_id}
                  study_id={study_id}
                  popupState={popupState}
                />
              </MenuItem>
              <MenuItem
                sx={{
                  width: '100%',
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <ResetModal
                  read_task_id={read_task_id}
                  read_pseudo_id={read_pseudo_id}
                  study_id={study_id}
                  popupState={popupState}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
