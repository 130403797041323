import { getQcUsersQcTasksQcUsersGet, GetQcUsersQcTasksQcUsersGetData } from '@common/config/api/client'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const useUserList = (queryKey: string[], queryParams: GetQcUsersQcTasksQcUsersGetData['query']) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getQcUsersQcTasksQcUsersGet({
        query: queryParams,
      }),
    placeholderData: keepPreviousData,
  })
}
