/**
 * SeriesLabelAddMenu component displays a dropdown menu for selecting imaging modalities.
 * It is used in the SeriesLabel Management page to allow users to create new series labels
 * of different imaging modalities (e.g. MRI, CT, Bone Scan, DXA, etc.).
 */
import { MenuItem } from '@mui/material'
import { SeriesLabelAddMenuProps } from '@features/series_label_management/types'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'

export default function SeriesLabelAddMenu({
  anchorEl,
  onClose,
  imagingModalities,
  onImagingModalitySelect,
}: Readonly<SeriesLabelAddMenuProps>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      {imagingModalities?.map((modality) => (
        <MenuItem
          key={modality.value}
          onClick={() => {
            onImagingModalitySelect(modality)
            onClose()
          }}
        >
          {modality.label}
        </MenuItem>
      ))}
    </BaseDropdownMenu>
  )
}
