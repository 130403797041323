import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { HTTPValidationError, ValidationError } from '@common/config/api/client'
import { AxiosError } from 'axios'

export const handleMutationError = ({ response, message }: AxiosError<HTTPValidationError>) => {
  if (Array.isArray(response?.data?.detail)) {
    response.data.detail.forEach((error: ValidationError) => {
      toast(<Toast message={error.msg} variant="error" />)
    })
  } else {
    toast(<Toast message={response?.data?.detail || message} variant="error" />)
  }
}
