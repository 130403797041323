import { Stack, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { CheckCircleOutlineRounded } from '@mui/icons-material'

export type ToastProps = {
  message: string
  task_ids?: string[]
  variant?: 'error' | 'success' | 'warning' | 'info'
}

const Toast = ({ message, task_ids, variant = 'info' }: ToastProps) => {
  const getIcon = () => {
    switch (variant) {
      case 'success':
        return <CheckCircleOutlineRounded sx={{ color: '#43A04F' }} />
      case 'error':
        return <ErrorOutlineIcon sx={{ color: 'red' }} />
      case 'warning':
        return <WarningAmberIcon sx={{ color: 'orange' }} />
      case 'info':
      default:
        return <InfoIcon sx={{ color: 'primary' }} />
    }
  }

  const getStyle = () => {
    switch (variant) {
      case 'success':
        return {
          bgcolor: '#D7F5DB',
          border: '1px solid #43A04F',
          color: '#1B5E24',
        }
      case 'error':
        return {
          bgcolor: '#F5737E',
          border: '1px solid #E53544',
          color: '#E53544',
        }
      case 'warning':
        return {
          bgcolor: '#FFCA28',
          border: '1px solid #FFB300',
          color: '#FFB300',
        }
      case 'info':
      default:
        return {
          bgcolor: '#E3F2FD',
          border: '1px solid #00ACE5',
          color: '#00ACE5',
        }
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        paddingX: 2,
        paddingY: 1,
        borderRadius: 1,
        gap: 1,
        width: '100%',
        ...getStyle(),
      }}
    >
      {getIcon()}
      <Typography>{message}</Typography>
      {task_ids && (
        <Typography
          sx={{
            typography: 'button',
          }}
        >{`${task_ids.join(', ')}`}</Typography>
      )}
    </Stack>
  )
}

export default Toast
