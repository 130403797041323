import { Box, Typography } from '@mui/material'
import UploadZone from '../UploadZone'
import Uppy from '@uppy/core'
import { useState } from 'react'
import { FileSystemItem } from '@features/subject/subject.types.ts'
import { deleteItemsByIds, getAllChildrenIdsForParents, mapFilesToStructure } from '@features/subject/utils.ts'
import { useParams } from 'react-router-dom'

type AddFilesStepProps = {
  uppy: Uppy
  containerSubmissionId: string
}

export default function AddFilesStep({ uppy, containerSubmissionId }: AddFilesStepProps) {
  const [data, setData] = useState<FileSystemItem[]>(mapFilesToStructure(uppy.getFiles()))
  const { studyId, timepointSubmissionId, subjectId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId: string
  }>()

  const deleteFiles = (filesIds: string[]) => {
    const actualFiles = getAllChildrenIdsForParents(data, filesIds)
    uppy.removeFiles(actualFiles)
    setData(deleteItemsByIds(data, actualFiles))
  }

  return (
    <Box>
      <Typography color="text.primary" variant="h4">
        Add and review files
      </Typography>
      <Typography color="text.secondary" variant="body2" mb={4}>
        Pick files or folders from your computer and add them here
      </Typography>
      <UploadZone
        onRemove={deleteFiles}
        data={data}
        onDrop={(acceptedFiles) => {
          acceptedFiles.forEach((file) => {
            const fileId = uppy.addFile(file)

            uppy.setFileMeta(fileId, {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              path: file.path,
              file_name: file.name,
              file_type: file.type || 'application/octet-stream',
              study_id: studyId,
              container_submission_id: containerSubmissionId,
              subject_id: subjectId,
              timepoint_submission_id: timepointSubmissionId,
            })
          })

          setData(mapFilesToStructure(uppy.getFiles()))
        }}
        onDropRejected={() => {}}
        accept={{
          'image/*': ['.jpg', '.png', '.bmp', '.tiff'],
          'application/dicom': ['.dcm'],
          'application/zip': ['.zip'],
          'application/dicom+json': ['.dcm'],
          'application/octet-stream': ['.dcm', '.nifti'],
          'application/nifti': ['.nifti'],
          'video/*': ['.mp4', '.mov'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc'],
        }}
      />
    </Box>
  )
}
