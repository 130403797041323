import { useMutation } from '@tanstack/react-query'
import { addCompanyUserCompaniesCompanyIdUsersPost, HTTPValidationError } from '@common/config/api/client'
import { UserFormData } from '../types'
import { queryClient } from '@common/config/api/queryClient'
import { AxiosError } from 'axios'
import queryKeyFactory from '@features/user_management/queryKeyFactory'

interface AddUserParams {
  companyId: string
  userData: UserFormData
}

export default function useAddUser() {
  return useMutation<unknown, AxiosError<HTTPValidationError>, AddUserParams>({
    mutationFn: async ({ companyId, userData }) => {
      const response = await addCompanyUserCompaniesCompanyIdUsersPost({
        path: {
          company_id: companyId,
        },
        body: {
          role_id: userData.role_id,
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
        },
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeyFactory.allUsers() })
    },
  })
}
