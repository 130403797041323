import {
  reopenQcTaskQcTasksReopenPost,
  ReopenQcTaskQcTasksReopenPostResponse,
  Body_reopen_qc_task_qc_tasks_reopen_post,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useReopenTasksMutation = (): UseMutationResult<
  ReopenQcTaskQcTasksReopenPostResponse,
  AxiosError<HTTPValidationError>,
  Body_reopen_qc_task_qc_tasks_reopen_post
> => {
  return useMutation<
    ReopenQcTaskQcTasksReopenPostResponse,
    AxiosError<HTTPValidationError>,
    Body_reopen_qc_task_qc_tasks_reopen_post
  >({
    mutationFn: async (body) => {
      const response = await reopenQcTaskQcTasksReopenPost({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTasksList'] })
    },
  })
}
