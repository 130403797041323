import { Theme } from '@mui/material/styles'

export default function ChipOverrides(theme: Theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(1),
        },
        deleteIcon: {
          color: '#5D666D',
          '&:hover': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
  }
}
