import { Chip, Stack } from '@mui/material'

export default function StudyTasksCountChips({
  overdueTasks,
  queries,
  openTasks,
}: {
  overdueTasks: number
  queries: number
  openTasks: number
}) {
  return (
    <Stack direction={'row'} spacing={0.5} data-testid="study-tasks-chips">
      {overdueTasks > 0 && (
        <Chip
          data-testid="chip-overdue"
          color="error"
          size="small"
          variant="outlined"
          label={`${overdueTasks} overdue`}
        />
      )}
      <Chip data-testid="chip-open" size="small" variant="outlined" label={`${openTasks} open`} />
      <Chip data-testid="chip-queries" size="small" variant="outlined" label={`${queries} queries`} />
    </Stack>
  )
}
