import { MenuItem } from '@mui/material'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'

interface UserActionMenuProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  onEdit: () => void
  onDelete: () => void
}

export default function UserActionMenu({ anchorEl, onClose, onEdit, onDelete }: Readonly<UserActionMenuProps>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      <MenuItem onClick={onEdit}>Edit User</MenuItem>
      <MenuItem onClick={onDelete}>Delete User</MenuItem>
    </BaseDropdownMenu>
  )
}
