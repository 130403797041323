import { Typography } from '@mui/material'

const AccountManagement = () => {
  return (
    <div>
      <Typography>Account Management</Typography>
    </div>
  )
}

export default AccountManagement
