import {
  Autocomplete as MuiAutocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps as MuiAutocompleteProps,
  TextField,
  TextFieldProps,
  AutocompleteValue,
} from '@mui/material'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'

export type Option = {
  label: string
  value: string
  additional_fields?: Record<string, unknown>
}

export const Dropdown = <
  T extends Option,
  P extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  control,
  name,
  defaultValue,
  rules,
  label,
  placeholder,
  options,
  slotProps,
  onBlur,
  multiple,
  helperText,
  ...rest
}: Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> &
  Omit<TextFieldProps, 'error' | 'onChange' | 'required'> &
  UseControllerProps<P>) => {
  const {
    field: { ref, onBlur: onFieldBlur, onChange: onFieldChange, value, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  function isOption(param: unknown): param is Option {
    return Boolean((param as Option)?.value !== undefined && (param as Option)?.label !== undefined)
  }

  function getOptionLabel(option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) {
    return isOption(option) ? option.label : option.toString()
  }

  function getOptionValue(option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) {
    return isOption(option) ? option.value.toString() : option.toString()
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const selectedOption: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo> = multiple
    ? options.filter((option) => (value as string[])?.includes(option.value.toString()))
    : options.find((option) => option.value.toString() === value?.toString()) || (rest.freeSolo ? (value as any) : null)

  return (
    <MuiAutocomplete
      data-testid="dropdown-autocomplete"
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => getOptionValue(option) === getOptionValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={error?.message || helperText}
          inputRef={ref}
          slotProps={{
            input: {
              ...(slotProps?.input || {}),
              ...params.InputProps,
            },
          }}
        />
      )}
      onChange={(_, value) => {
        const optionValue = multiple
          ? (value as T[]).map((option) => (isOption(option) ? option.value.toString() : option))
          : isOption(value)
            ? value.value.toString()
            : value
        onFieldChange(optionValue)
      }}
      fullWidth
      options={options}
      value={selectedOption}
      onBlur={(event) => {
        onFieldBlur()

        if (onBlur) {
          onBlur(event)
        }
      }}
      multiple={multiple}
      {...field}
      {...rest}
    />
  )
}
