import { TableCell, TableHead, TableRow } from '@mui/material'
import { HeadCell } from '@common/components/Table/table.types'
import { SubjectStudyTimepoint, TimepointSubject } from '@common/config/api/client'

export default function DefaultSubjectTableHeader({ studyTimepoints }: { studyTimepoints: SubjectStudyTimepoint[] }) {
  const headCells: readonly HeadCell<TimepointSubject>[] = [
    { disablePadding: false, id: 'pseudo_id', label: 'Subject', disableSort: false, stickyColumn: true, width: 170 },
    { disablePadding: false, id: 'status', label: 'Status', disableSort: false, stickyColumn: true, width: 170 },
    {
      disablePadding: false,
      id: 'query' as keyof TimepointSubject,
      label: 'Query',
      disableSort: false,
      stickyColumn: true,
      width: 170,
    },
    ...(studyTimepoints || []).map((tp) => ({
      disablePadding: false,
      id: tp.timepoint_id as keyof TimepointSubject,
      label: tp.name,
      disableSort: true,
      minWidth: 220,
    })),
  ]

  return (
    <TableHead
      sx={{
        visibility: 'collapse',
      }}
      data-testid="default-subject-table-header"
    >
      <TableRow data-testid="header-row">
        <TableCell
          padding="checkbox"
          sx={{
            minWidth: 48,
            width: 48,
          }}
          data-testid="header-checkbox-cell"
        />
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            data-testid={`head-table-cell-${index}`}
            sx={{
              width: headCell.width ? headCell.width : 'auto',
              minWidth: headCell.minWidth ? headCell.minWidth : 'auto',
              zIndex: headCell.stickyColumn ? 2 : 'auto',
              left: headCell.stickyColumn && headCell.width ? 48 + index * headCell.width : 'auto',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
