import { Button, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import TaskStateChip from './TaskStateChip'
import { QcTaskTimepointSchema } from '@common/config/api/client'

const formatTaskDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-GB')
}

export default function QCDetailsHeader({ qcTask }: { qcTask: QcTaskTimepointSchema }) {
  return (
    <>
      <Grid container size={12} paddingX={10}>
        <Grid size={12}>
          <Stack spacing={4}>
            <Stack spacing={0.5}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="h4">QC {qcTask.order}</Typography>
                  <TaskStateChip taskState="IN_PROGRESS" />
                </Stack>
                <Button variant="contained" disabled>
                  Complete QC {qcTask.order}
                </Button>
              </Stack>
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Created: {formatTaskDate(qcTask.create_date)}
              </Typography>
            </Stack>
            <Tabs value={0}>
              <Tab label="Overview" />
              <Tab label="Queries" />
            </Tabs>
          </Stack>
        </Grid>
      </Grid>
      <Divider
        sx={{
          marginX: -5,
        }}
      />
    </>
  )
}
