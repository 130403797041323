import SvgIcon from '@mui/material/SvgIcon'

export default function LabsIcon() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask
          id="mask0_1617_14060"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#00ACE5" />
        </mask>
        <g mask="url(#mask0_1617_14060)">
          <path
            d="M12 22C10.6167 22 9.4375 21.5125 8.4625 20.5375C7.4875 19.5625 7 18.3833 7 17V8C6.45 8 5.97917 7.80417 5.5875 7.4125C5.19583 7.02083 5 6.55 5 6V4C5 3.45 5.19583 2.97917 5.5875 2.5875C5.97917 2.19583 6.45 2 7 2H17C17.55 2 18.0208 2.19583 18.4125 2.5875C18.8042 2.97917 19 3.45 19 4V6C19 6.55 18.8042 7.02083 18.4125 7.4125C18.0208 7.80417 17.55 8 17 8V17C17 18.3833 16.5125 19.5625 15.5375 20.5375C14.5625 21.5125 13.3833 22 12 22ZM12 20C12.8333 20 13.5417 19.7083 14.125 19.125C14.7083 18.5417 15 17.8333 15 17H13C12.7167 17 12.4792 16.9042 12.2875 16.7125C12.0958 16.5208 12 16.2833 12 16C12 15.7167 12.0958 15.4792 12.2875 15.2875C12.4792 15.0958 12.7167 15 13 15H15V13H13C12.7167 13 12.4792 12.9042 12.2875 12.7125C12.0958 12.5208 12 12.2833 12 12C12 11.7167 12.0958 11.4792 12.2875 11.2875C12.4792 11.0958 12.7167 11 13 11H15V8H9V17C9 17.8333 9.29167 18.5417 9.875 19.125C10.4583 19.7083 11.1667 20 12 20Z"
            fill="#00ACE5"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
