import {
  reopenReadTaskReadTasksReopenPost,
  ReopenReadTaskReadTasksReopenPostResponse,
  Body_reopen_read_task_read_tasks_reopen_post,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useReopenTasksMutation = (): UseMutationResult<
  ReopenReadTaskReadTasksReopenPostResponse,
  AxiosError<HTTPValidationError>,
  Body_reopen_read_task_read_tasks_reopen_post
> => {
  return useMutation<
    ReopenReadTaskReadTasksReopenPostResponse,
    AxiosError<HTTPValidationError>,
    Body_reopen_read_task_read_tasks_reopen_post
  >({
    mutationFn: async (body) => {
      const response = await reopenReadTaskReadTasksReopenPost({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ReadTasksList'] })
    },
  })
}
