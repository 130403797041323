import { useState, MouseEvent } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { GetStudyHomepageStudiesHomepageGetData, ListStudy } from '@common/config/api/client'
import { ROW_PER_PAGE_OPTIONS } from '@common/constants/table'
import { useStudiesHomepage } from '@features/study_view/hooks/useStudiesHomepage'
import EnhancedTableBodyStudyView from '../components/EnhancedTableBodyStudyView'
import StudyTabs from '../components/StudyTabs'
import { STUDY_FILTER_VIEWS } from '@features/study_view/constants'
import { Paper } from '@mui/material'
import { mapStatusText } from '@features/study_view/utils'

const headCells: readonly HeadCell<ListStudy>[] = [
  { id: 'nickname', disablePadding: true, label: 'Study name', disableSort: false },
  { id: 'id', disablePadding: false, label: 'ID', disableSort: false },
  { id: 'type', disablePadding: false, label: 'Type', disableSort: false },
  { id: 'status', disablePadding: false, label: 'Status', disableSort: false },
  { id: 'open_tasks_count', disablePadding: false, label: 'Open tasks', disableSort: false },
  { id: 'overdue_count', disablePadding: false, label: 'Overdue tasks', disableSort: false },
  { id: 'queries_count', disablePadding: false, label: 'Queries', disableSort: false },
]

export default function StudyTableView() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof ListStudy>('nickname')
  const [page, setPage] = useState(0)
  const [currentActiveTab, setCurrentActiveTab] = useState(0)

  const [paginationParams, setPaginationParams] = useState<GetStudyHomepageStudiesHomepageGetData['query']>({
    limit: ROW_PER_PAGE_OPTIONS[2],
    next_cursor: null,
    previous_cursor: null,
  })

  const { studies, pagination, error, isLoading } = useStudiesHomepage(
    ['studiesHomepage', `${page}`, `${paginationParams.limit}`],
    paginationParams,
  )

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof ListStudy) => {
    const isAsc = orderBy.split('.')[0] === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      // Moving to next page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: pagination.next_cursor,
        previous_cursor: null,
      }))
      setPage(page + 1)
    } else if (newPage < 0) {
      // Moving to previous page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: null,
        previous_cursor: pagination.previous_cursor,
      }))
      setPage(page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  return (
    <Paper
      sx={{
        padding: 3,
        borderRadius: 4,
      }}
    >
      <StudyTabs
        tabs={STUDY_FILTER_VIEWS}
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
      />
      <EnhancedTable<ListStudy>
        rows={studies}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
        rowsPerPage={paginationParams.limit}
        next_cursor={pagination.next_cursor}
        previous_cursor={pagination.previous_cursor}
        onRequestSort={handleRequestSort}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      >
        <EnhancedTableBodyStudyView
          rows={studies}
          order={order}
          orderBy={orderBy}
          filterBy={mapStatusText(STUDY_FILTER_VIEWS[currentActiveTab])}
        />
      </EnhancedTable>
    </Paper>
  )
}
