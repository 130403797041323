import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  batchDeleteSeriesLabelsSeriesLabelsBatchDeletePost,
  createSeriesLabelSeriesLabelsPost,
  deleteSeriesLabelSeriesLabelsSeriesLabelIdDelete,
  updateSeriesLabelSeriesLabelsSeriesLabelIdPut,
} from '@common/config/api/client/services.gen'
import { AxiosError } from 'axios'
import {
  CreateSeriesLabelsSchema,
  HTTPValidationError,
  SeriesLabelRecord,
  UpdateSeriesLabelsSchema,
} from '@common/config/api/client'

export const useDeleteSeriesLabel = () => {
  return useMutation({
    mutationFn: (seriesLabelId: string) =>
      deleteSeriesLabelSeriesLabelsSeriesLabelIdDelete({ path: { series_label_id: seriesLabelId } }),
  })
}

export const useBulkDeleteSeriesLabels = () => {
  return useMutation({
    mutationFn: (seriesLabelIds: Array<string>) =>
      batchDeleteSeriesLabelsSeriesLabelsBatchDeletePost({ body: { series_labels_ids: seriesLabelIds } }),
  })
}

export const useCreateSeriesLabel = (): UseMutationResult<
  SeriesLabelRecord,
  AxiosError<HTTPValidationError>,
  CreateSeriesLabelsSchema
> => {
  return useMutation<SeriesLabelRecord, AxiosError<HTTPValidationError>, CreateSeriesLabelsSchema>({
    mutationFn: async (seriesLabelPayload) => {
      const response = await createSeriesLabelSeriesLabelsPost({
        body: seriesLabelPayload,
        throwOnError: true,
      })
      return response.data
    },
  })
}

export const useUpdateSeriesLabel = (): UseMutationResult<
  SeriesLabelRecord,
  AxiosError<HTTPValidationError>,
  { seriesLabelId: string; seriesLabelPayload: UpdateSeriesLabelsSchema }
> => {
  return useMutation({
    mutationFn: async ({ seriesLabelId, seriesLabelPayload }) => {
      const response = await updateSeriesLabelSeriesLabelsSeriesLabelIdPut({
        path: { series_label_id: seriesLabelId },
        body: seriesLabelPayload,
        throwOnError: true,
      })
      return response.data
    },
  })
}
