import { ChatBubbleOutlineRounded, InfoOutlined } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import ExamDetailsList from './ExamDetailsList'
import ExamReason from './ExamReason'
import ArrowTooltips from '@common/components/Tooltip'

export default function Exam() {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="start">
        <Typography variant="subtitle1">Site_name_01</Typography>
        <ArrowTooltips
          message="Site ID: 123456"
          children={
            <IconButton
              sx={{
                padding: 0,
              }}
            >
              <InfoOutlined
                sx={{
                  color: 'text.secondary',
                  width: 16,
                  height: 16,
                }}
              />
            </IconButton>
          }
          placement="top"
        />
      </Stack>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography
              variant="subtitle2"
              sx={{
                color: 'text.secondary',
              }}
            >
              CT Abdomen and Pelvis - Appendicitis Protocol
            </Typography>
            <IconButton>
              <ChatBubbleOutlineRounded
                sx={{
                  color: 'text.secondary',
                  width: 20,
                  height: 20,
                }}
              />
            </IconButton>
          </Stack>
          <ExamDetailsList />
        </Stack>
        <Stack spacing={1}>
          <Stack>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'text.secondary',
              }}
            >
              CT Abdomen - Pre-Contrast Phase
            </Typography>
          </Stack>
          <ExamReason />
        </Stack>
      </Stack>
    </Stack>
  )
}
