import { useQuery } from '@tanstack/react-query'
import {
  listSeriesLabelsSeriesLabelsGet,
  getSeriesLabelSeriesLabelsSeriesLabelIdGet,
  getSeriesLabelMetadataSeriesLabelsMetadataGet,
  ImagingModalityCategory,
} from '@common/config/api/client'
import queryKeyFactory from '@features/series_label_management/queryKeyFactory'
import { SeriesLabelsParams } from '@features/series_label_management/types'
import { transformGetSeriesLabelsResponse, transformGetSeriesLabelMetadataResponse } from '.'

export const useGetSeriesLabels = (params: SeriesLabelsParams) => {
  return useQuery({
    queryKey: queryKeyFactory.seriesLabels(params),
    queryFn: async () => {
      const response = await listSeriesLabelsSeriesLabelsGet({
        query: {
          imaging_modality_category: params.imaging_modality_category as ImagingModalityCategory,
          search: params.search,
          limit: params.limit,
          next_cursor: params.next_cursor,
          previous_cursor: params.previous_cursor,
          order_by_field: params.order_by_field,
          asc_order: params.asc_order,
        },
      })
      return response.data
    },
    select: transformGetSeriesLabelsResponse,
    enabled: !!params.imaging_modality_category,
  })
}

export const getSeriesLabelQuery = (seriesLabelId: string) => ({
  queryKey: queryKeyFactory.seriesLabel(seriesLabelId),
  queryFn: async () => {
    const response = await getSeriesLabelSeriesLabelsSeriesLabelIdGet({
      path: { series_label_id: seriesLabelId },
      throwOnError: true,
    })
    return response.data
  },
})

export const useGetSeriesLabelById = (seriesLabelId: string | null) => {
  return useQuery({
    ...getSeriesLabelQuery(seriesLabelId!),
    enabled: !!seriesLabelId,
  })
}

export const useGetSeriesLabelsMetadata = () => {
  return useQuery({
    queryKey: queryKeyFactory.seriesLabelMetadata(),
    queryFn: async () => {
      const response = await getSeriesLabelMetadataSeriesLabelsMetadataGet({
        throwOnError: true,
      })
      return response.data
    },
    select: transformGetSeriesLabelMetadataResponse,
  })
}
