import { Paper } from '@mui/material'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { MouseEvent, useState } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { useGetSubjectsList } from '../hooks/useGetSubjectsList'
import { TimepointSubject } from '@common/config/api/client'
import { DEFAULT_SITE } from '@common/constants/study'
import { useParams } from 'react-router-dom'
import SubjectListTableBody from '../components/SubjectTable/SubjectListTableBody'

export default function SubjectListTable() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof TimepointSubject>('pseudo_id')
  const { studyId } = useParams<{ studyId: string }>()

  const { data, error, isLoading } = useGetSubjectsList(['SubjectsList'], {
    site_id: DEFAULT_SITE.site_id,
    study_id: studyId!,
  })

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  const headCells: readonly HeadCell<TimepointSubject>[] = [
    { disablePadding: false, id: 'pseudo_id', label: 'Subject', disableSort: false, stickyColumn: true, width: 170 },
    { disablePadding: false, id: 'status', label: 'Status', disableSort: false, stickyColumn: true, width: 170 },
    {
      disablePadding: false,
      id: 'query' as keyof TimepointSubject,
      label: 'Query',
      disableSort: false,
      stickyColumn: true,
      width: 170,
    },
    ...(data?.data?.study_timepoints || []).map((tp) => ({
      disablePadding: false,
      id: tp.timepoint_id as keyof TimepointSubject,
      label: tp.name,
      disableSort: true,
      minWidth: 220,
    })),
  ]

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof TimepointSubject) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  return (
    <Paper
      sx={{
        padding: 3,
      }}
    >
      <EnhancedTable<TimepointSubject>
        rows={[]}
        headCells={headCells}
        orderBy={orderBy}
        order={order}
        expandable
        rowsPerPage={50}
        onRequestSort={handleRequestSort}
        onChangePage={() => {}}
        onChangeRowsPerPage={() => {}}
      >
        <SubjectListTableBody rows={data?.data?.subjects || []} order={order} orderBy={orderBy} />
      </EnhancedTable>
    </Paper>
  )
}
