import { useQuery } from '@tanstack/react-query'
import {
  FormDefinitionUserSchema,
  listFormDefinitionsFormsGet,
  PaginatedResponse_FormDefinitionRecord_,
  ResponseCriteriaSchema,
} from '@common/config/api/client'
import queryKeyFactory from '@features/form_management/queryKeyFactory'
import { FormDefinitionsParams } from '@features/form_management/types'

export interface TransformedFormDefinitions {
  items: {
    id: string
    name: string
    type: string
    responseCriteria?: ResponseCriteriaSchema | null
    createdAt: string | null
    updatedAt: string | null
    createdByUser: FormDefinitionUserSchema | null
  }[]
  pagination: {
    nextCursor: string | null
    previousCursor: string | null
    limit: number | null
  }
}

function transformResponse(data: PaginatedResponse_FormDefinitionRecord_ | undefined): TransformedFormDefinitions {
  return {
    items:
      data?.items.map((item) => ({
        id: item.id,
        name: item.name,
        type: item.type,
        responseCriteria: item.response_criteria,
        createdAt: item.created_at
          ? new Date(item.created_at)
              .toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })
              .replace(/\./g, '/')
          : null,
        updatedAt: item.updated_at
          ? new Date(item.updated_at)
              .toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })
              .replace(/\./g, '/')
          : null,
        createdByUser: item.created_by_user,
      })) ?? [],
    pagination: {
      nextCursor: data?.pagination.next_cursor ?? null,
      previousCursor: data?.pagination.previous_cursor ?? null,
      limit: data?.pagination.limit ?? null,
    },
  }
}

export default function useFormDefinitions(params: FormDefinitionsParams) {
  return useQuery({
    queryKey: queryKeyFactory.formDefinitions(params),
    queryFn: async () => {
      const response = await listFormDefinitionsFormsGet({
        query: {
          form_type: params.form_type,
          search: params.search,
          limit: params.limit,
          next_cursor: params.next_cursor,
          previous_cursor: params.previous_cursor,
          order_by_field: params.order_by_field,
          asc_order: params.asc_order,
        },
      })
      return response.data
    },
    select: transformResponse,
    enabled: !!params.form_type,
  })
}
