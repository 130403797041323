import { Order } from '@common/components/Table/table.types'
import { ListStudy, Status } from '@common/config/api/client'

export const getComparator = (order: Order, orderBy: keyof ListStudy) => {
  return order === 'desc'
    ? (a: ListStudy, b: ListStudy) => {
        const aValue = a[orderBy] ?? 0
        const bValue = b[orderBy] ?? 0
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0
      }
    : (a: ListStudy, b: ListStudy) => {
        const aValue = a[orderBy] ?? 0
        const bValue = b[orderBy] ?? 0
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
      }
}

export const formatStatusText = (status: Status) => {
  switch (status) {
    case 'DRAFT':
      return 'Draft'
    case 'UAT':
      return 'UAT testing'
    case 'LIVE':
      return 'Live'
    case 'CLOSE_OUT':
      return 'Close out'
    case 'ON_HOLD':
      return 'On hold'
    case 'CANCELLED':
      return 'Cancelled'
  }
}

export const mapStatusText = (status: string) => {
  switch (status) {
    case 'Draft':
      return 'DRAFT'
    case 'UAT testing':
      return 'UAT'
    case 'Live':
      return 'LIVE'
    case 'Close out':
      return 'CLOSE_OUT'
    case 'On hold':
      return 'ON_HOLD'
    case 'Cancelled':
      return 'CANCELLED'
    default:
      return 'ALL'
  }
}
