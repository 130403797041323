/**
 * FormAddMenu component displays a dropdown menu for selecting form types.
 * It is used in the Form Management page to allow users to create new forms
 * of different types (e.g. Timepoint eForm, Container eForm, QC form, eCRF).
 */
import { MenuItem } from '@mui/material'
import { FormAddMenuProps } from '../types'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'

export default function FormAddMenu({ anchorEl, onClose, formTypes, onFormTypeSelect }: Readonly<FormAddMenuProps>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      {formTypes?.map((formType) => (
        <MenuItem
          key={formType.id}
          onClick={() => {
            onFormTypeSelect(formType)
            onClose()
          }}
        >
          {formType.label}
        </MenuItem>
      ))}
    </BaseDropdownMenu>
  )
}
