import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { FieldValues, UseFormReturn } from 'react-hook-form'

interface BaseDialogProps<T extends FieldValues> {
  title: string
  description?: string
  open: boolean
  onClose: () => void
  onSubmit?: (data: T) => void
  form?: UseFormReturn<T>
  children: React.ReactNode
  submitLabel?: string
  cancelLabel?: string
  width?: number
  contentSpacing?: number
  isSubmitting?: boolean
}

const BaseDialog = <T extends Record<string, unknown>>({
  title,
  description,
  open,
  onClose,
  onSubmit,
  form,
  children,
  submitLabel = 'Save',
  cancelLabel = 'Cancel',
  width = 500,
  contentSpacing = 3,
  isSubmitting = false,
}: BaseDialogProps<T>) => {
  const handleSubmit = () => {
    if (form && onSubmit) {
      form.handleSubmit(onSubmit)()
    }
  }

  const BaseDialogContent = (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ paddingX: 3, paddingY: 2, width, mx: 'auto' }}>
        {description && (
          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 4 }}>
            {description}
          </Typography>
        )}
        <Stack direction="column" spacing={contentSpacing}>
          {children}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ paddingX: 3, paddingY: 2 }}>
        <Button onClick={onClose} size="large" color="secondary" variant="contained" disabled={isSubmitting}>
          {cancelLabel}
        </Button>
        {onSubmit && (
          <Button onClick={handleSubmit} size="large" color="primary" variant="contained" disabled={isSubmitting}>
            {submitLabel}
          </Button>
        )}
      </DialogActions>
    </>
  )

  return (
    <Dialog onClose={onClose} open={open}>
      {form ? (
        <form role="form" onSubmit={form.handleSubmit(onSubmit!)}>
          {BaseDialogContent}
        </form>
      ) : (
        BaseDialogContent
      )}
    </Dialog>
  )
}

export default BaseDialog
