import LockIcon from '@mui/icons-material/Lock'
import { InputAdornment, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'

export const TextField = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  rules,
  onBlur,
  onChange,
  disabled,
  slotProps,
  variant = 'outlined',
  maxLength,
  type,
  ...rest
}: MuiTextFieldProps & UseControllerProps<T> & { maxLength?: number }) => {
  const {
    field: { value, ref, onChange: onFieldChange, onBlur: onFieldBlur, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    if (type === 'number') {
      if (inputValue === '') {
        onFieldChange(null)
      } else {
        const numValue = Number(inputValue)
        onFieldChange(isNaN(numValue) ? null : numValue)
      }
    } else {
      onFieldChange(inputValue)
    }

    if (onChange) {
      onChange(event)
    }
  }
  const characterCount = value?.length || 0

  return (
    <MuiTextField
      error={!!error}
      helperText={error?.message || (maxLength ? `${characterCount}/${maxLength}` : null)}
      fullWidth
      onBlur={(event) => {
        onFieldBlur()

        if (onBlur) {
          onBlur(event)
        }
      }}
      onChange={handleChange}
      value={value || ''}
      variant={variant}
      disabled={disabled}
      inputRef={ref}
      slotProps={{
        input: {
          endAdornment: disabled ? (
            <InputAdornment position="end">
              <LockIcon />
            </InputAdornment>
          ) : null,
          ...slotProps?.input,
          inputProps: {
            maxLength: maxLength,
          },
        },
      }}
      {...field}
      {...rest}
    />
  )
}
