import { useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { CompaniesMetadataSchema, CompaniesUsersReadSchema } from '@common/config/api/client'

export default function useUserTable(
  companiesMetadata: CompaniesMetadataSchema | undefined,
  users: CompaniesUsersReadSchema[],
  onDeleteUser: (companyId: string, userId: string) => void,
) {
  const navigate = useNavigate()
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)

  const handleOpenMenu = (event: MouseEvent<HTMLElement>, userId: string) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
    setSelectedUserId(userId)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
    setSelectedUserId(null)
  }

  const handleEditUser = () => {
    const selectedUser = users.find((user) => user.user_id === selectedUserId)
    if (selectedUserId && selectedUser) {
      navigate(`/users_management/companies/${selectedUser.company_id}/users/${selectedUserId}?edit=true`)
      handleCloseMenu()
    }
  }

  const handleDeleteUser = () => {
    const selectedUser = users.find((user) => user.user_id === selectedUserId)
    if (selectedUser && selectedUserId) {
      onDeleteUser(selectedUser.company_id, selectedUserId)
      handleCloseMenu()
    }
  }

  const userTypeRoutes = getCompanyTypeRoutes(companiesMetadata)

  return {
    menuAnchorEl,
    handleOpenMenu,
    handleCloseMenu,
    handleEditUser,
    handleDeleteUser,
    userTypeRoutes,
  }
}

function transformCompanyType(type: string): string {
  return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
}

function getCompanyTypeRoutes(companiesMetadata: { companies: Array<{ company_type: string }> } | undefined) {
  return [
    {
      path: '',
      label: 'All users',
      id: 'all-users',
    },
    ...(companiesMetadata?.companies ?? [])
      .map((company) => company.company_type)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((type) => ({
        path: `#${type.toLowerCase()}`,
        label: transformCompanyType(type),
        id: type.toLowerCase(),
      })),
  ]
}
