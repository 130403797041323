import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { FormDefinitionsParams } from '../types'
import { TransformedFormMetadata } from './useFormMetadata'

interface UseFormTypeNavigationProps {
  formMetadata?: TransformedFormMetadata
  formDefinitionsParams: FormDefinitionsParams
  setFormDefinitionsParams: React.Dispatch<React.SetStateAction<FormDefinitionsParams>>
}

export default function useFormTypeNavigation({
  formMetadata,
  formDefinitionsParams,
  setFormDefinitionsParams,
}: UseFormTypeNavigationProps) {
  const location = useLocation()

  // Set initial form type when metadata loads
  useEffect(() => {
    if (formMetadata?.formTypes.length && !formDefinitionsParams.form_type) {
      setFormDefinitionsParams((prev) => ({
        ...prev,
        form_type: formMetadata.formTypes[0].id,
      }))
    }
  }, [formMetadata?.formTypes, formDefinitionsParams.form_type, setFormDefinitionsParams])

  // Update form type based on URL hash
  useEffect(() => {
    if (formMetadata?.formTypes) {
      const hash = location.hash.slice(1)
      const matchingType = hash ? formMetadata.formTypes.find((type) => type.name === hash) : formMetadata.formTypes[0]

      if (matchingType) {
        setFormDefinitionsParams((prev) => ({
          ...prev,
          form_type: matchingType.id,
        }))
        if (!hash) {
          window.location.hash = matchingType.name
        }
      }
    }
  }, [location.hash, formMetadata?.formTypes, setFormDefinitionsParams])
}
