import { Chip } from '@mui/material'
import { TASK_TYPE_COLOR, TASK_TYPE_TEXT_COLOR } from './TaskTypeColor'
import { formatTaskTypeText } from '../utils'

export default function TaskTypeChip({ taskType }: { taskType: string }) {
  return (
    <Chip
      data-testid="task-type-chip"
      label={formatTaskTypeText(taskType)}
      size="small"
      sx={{
        backgroundColor: TASK_TYPE_COLOR,
        color: TASK_TYPE_TEXT_COLOR,
        padding: 0.5,
        alignItems: 'center',
      }}
    />
  )
}
