import { matchPath } from 'react-router-dom'

export function getActive(path: string | null, pathname: string) {
  if (!path) {
    return false
  }
  return !!matchPath({ path, end: path === '/' }, pathname)
}

export function generatePathWithParams(path: string, params: Record<string, string | number>): string {
  return Object.keys(params).reduce((updatedPath, param) => {
    return updatedPath.replace(`:${param}`, String(params[param]))
  }, path)
}

export function isParentActive(subItems: Array<{ path: string | null }>, pathname: string): boolean {
  return subItems.some((item) => item.path && !!matchPath({ path: item.path, end: true }, pathname))
}
