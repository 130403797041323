import { StepType } from '@common/context/StepperContext'
import { Box, Stepper, Step, StepLabel, Typography, useTheme, Tooltip, useMediaQuery } from '@mui/material'

type HorizontalLinearStepperProps = {
  steps: StepType[]
  activeStep: number
  showStepLabels?: boolean
}

export function HorizontalLinearStepper({ steps, activeStep, showStepLabels = false }: HorizontalLinearStepperProps) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'))

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map(({ label, optional = false, completed = false }, index) => {
          const isActiveStep = index === activeStep
          const stepProps: { completed: boolean } = { completed }
          const labelProps: {
            optional?: React.ReactNode
            slotProps?: {
              label?: {
                sx?: object
              }
            }
          } = {}

          if (optional) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>
          }

          labelProps.slotProps = {
            label: {
              sx: {
                display: 'block',
                [theme.breakpoints.down('xl')]: {
                  display: isActiveStep || showStepLabels ? 'block' : 'none',
                },
              },
            },
          }

          const stepLabel = <StepLabel {...labelProps}>{label}</StepLabel>

          return (
            <Step key={label} {...stepProps}>
              {!isActiveStep && isSmallScreen && !showStepLabels ? (
                <Tooltip title={label} arrow>
                  <span>{stepLabel}</span>
                </Tooltip>
              ) : (
                stepLabel
              )}
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
