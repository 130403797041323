import { Button, Divider, Paper, Stack, Typography } from '@mui/material'
import Exam from './Exam'

export default function Exams() {
  return (
    <Paper
      sx={{
        padding: 3,
        borderRadius: 4,
      }}
    >
      <Stack spacing={4}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Exams</Typography>
          <Button
            sx={{
              color: 'text.primary',
              borderColor: 'secondary.dark',
              py: 0.75,
              px: 2,
            }}
            variant="outlined"
          >
            Open in viewer
          </Button>
        </Stack>
        <Stack spacing={3}>
          <Exam />
          <Divider />
        </Stack>
      </Stack>
    </Paper>
  )
}
