import { createContext, useContext, ReactNode } from 'react'
import { useGetStudyPermissions, useUserPermissions } from '@auth/hooks/useAuthQueries'
import { CombinedPermissionsContextType } from '@auth/types/permissions'
import { EnhancedPermissionUtilsFactory } from '@auth/utils/permissionsUtilsFactory'
import { Permission } from '@auth/permissionsEnum'

export const CombinedPermissionsContext = createContext<CombinedPermissionsContextType | undefined>(undefined)

export function CombinedPermissionsProvider({ children, studyId }: { children: ReactNode; studyId: string }) {
  const { data: globalPermissions = [], isLoading: isLoadingGlobal } = useUserPermissions()
  const { data: studyPermissions = [], isLoading: isLoadingStudy } = useGetStudyPermissions(studyId)
  const permissionUtils = EnhancedPermissionUtilsFactory(studyPermissions, globalPermissions)

  return (
    <CombinedPermissionsContext.Provider
      value={{
        globalPermissions: globalPermissions as Permission[],
        studyPermissions: studyPermissions as Permission[],
        ...permissionUtils,
        isLoading: isLoadingGlobal || isLoadingStudy,
      }}
    >
      {children}
    </CombinedPermissionsContext.Provider>
  )
}

export const useCombinedPermissions = () => {
  const context = useContext(CombinedPermissionsContext)
  if (!context) {
    throw new Error('useCombinedPermissions must be used within CombinedPermissionsProvider')
  }
  return context
}
