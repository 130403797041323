import EnhancedGrid from '@common/components/Grid'
import StudyCard from '../components/StudyCard'
import { useStudiesHomepage } from '@features/study_view/hooks/useStudiesHomepage'
import { ROW_PER_PAGE_OPTIONS } from '@common/constants/table'

export default function CardView() {
  const { studies } = useStudiesHomepage(['studiesHomepage'], {
    limit: ROW_PER_PAGE_OPTIONS[2],
    next_cursor: null,
    previous_cursor: null,
  })

  return <EnhancedGrid items={studies} renderItem={(study) => <StudyCard study={study} />} />
}
