import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { toast } from 'sonner'
import Toast from './Toast'
import { AxiosError } from 'axios'
import { useExcludeTasksMutation } from '@qc_task_list_view/hooks/useExcludeTasksMutation'
import ArrowTooltips from '@common/components/Tooltip'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@common/components/Form/TextField'
import { DeleteSweepRounded } from '@mui/icons-material'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 1,
}

const ExcludeValidationSchema = z.object({
  reason: z.string().min(1),
})

export default function ExcludeModal({
  task_id,
  pseudo_id,
  study_id,
}: {
  task_id: string
  pseudo_id: string
  study_id: string
}) {
  const {
    control,
    watch,
    formState: { isValid },
    setError,
  } = useForm({ mode: 'onChange', defaultValues: { reason: '' }, resolver: zodResolver(ExcludeValidationSchema) })
  const reason = watch('reason')
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const { mutateAsync: excludeTask } = useExcludeTasksMutation()

  const handleExcludeTask = async (task_ids: string[], study_id: string, reason: string) => {
    if (isValid) {
      await excludeTask({
        status_change_reason: reason,
        qc_task_ids: task_ids,
        study_id: study_id,
      })
        .then(() => {
          toast(<Toast message="Your request was sent successfully" variant="success" data-testid="success-toast" />)
          handleClose()
        })
        .catch((error) => {
          toast(<Toast message={(error as AxiosError).message} variant="error" data-testid="error-toast" />)
        })
    } else {
      setError('reason', { message: 'Provide a reason why you need to exclude the task' })
    }
  }

  return (
    <>
      <Box
        onClick={(event) => {
          event.stopPropagation()
          setOpen(true)
        }}
        data-testid="exclude-task-button"
      >
        <ArrowTooltips
          message={'Exclude task. You will remove QC task from the list.'}
          children={
            <DeleteSweepRounded
              fontSize="small"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  borderRadius: '50%',
                  backgroundColor: '#F3F4F6',
                },
              }}
            />
          }
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        data-testid="exclude-modal"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              alignSelf: 'stretch',
              width: 600,
            }}
          >
            <Typography variant="h6" color="text.primary">
              {`Exclude task №${pseudo_id}?`}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Briefly explain why you want to exclude this task.
            </Typography>
          </Box>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: 2,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              name="reason"
              control={control}
              id="outlined-multiline-static"
              label="Provide a reason"
              multiline
              rows={6}
              fullWidth
              value={reason}
              onKeyDown={(e) => e.stopPropagation()}
              data-testid="reason-textfield"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
              padding: 2,
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="cancel-exclude-task-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              onClick={() => handleExcludeTask([task_id], study_id, reason)}
              data-testid="confirm-exclude-task-button"
            >
              Exclude task
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
