import {
  core__models__form_definition__FormDefinitionModel__Type,
  FormMetadataSchema,
  getFormMetadataFormsMetadataGet,
  ResponseCriteriaSchema,
} from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'
import { FormType } from '@features/form_management/types'
import queryKeyFactory from '@features/form_management/queryKeyFactory'

export interface TransformedFormMetadata {
  formTypes: FormType[]
  responseCriteria: ResponseCriteriaSchema[]
}

function transformFormMetadata(data: FormMetadataSchema): TransformedFormMetadata {
  return {
    formTypes: data.form_types.map((type) => ({
      id: type.name as core__models__form_definition__FormDefinitionModel__Type,
      name: type.name,
      label: type.label,
    })),
    responseCriteria:
      data.response_criteria?.map((criteria) => ({
        id: criteria.id,
        name: criteria.name,
      })) ?? [],
  }
}

export default function useFormMetadata() {
  return useQuery({
    queryKey: queryKeyFactory.formMetadata(),
    queryFn: async () => {
      const response = await getFormMetadataFormsMetadataGet()
      return response.data ?? { form_types: [], response_criteria: [] }
    },
    select: transformFormMetadata,
  })
}
