import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App.tsx'
import { AuthProvider } from 'react-oidc-context'
import { onSigninCallback, userManager } from './auth/oidc.config'
import { ProtectedApp } from './auth/components/ProtectedApp'
import { ThemeProvider } from '@common/theme'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from '@common/config/api/queryClient'
import { enableMocking } from './mock'
import { CurrentUserGroupProvider } from '@common/context/CurrentUserGroupProvider.tsx'
import { SidebarProvider } from '@common/context/SidebarContext.tsx'
import { StorageContext } from '@common/hooks/useStorage.ts'
import { StorageService } from '@common/services/storageService.ts'
import { SessionStorageProvider } from '@common/services/storageProviders.ts'
import { GlobalPermissionsProvider } from '@auth/hooks/useGlobalPermissions.tsx'
import { StepperProvider } from '@common/context/StepperContext.tsx'

const mountApp = () => {
  const storage = new StorageService(new SessionStorageProvider())
  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <StorageContext.Provider value={storage}>
                  <ProtectedApp>
                    <GlobalPermissionsProvider>
                      <StepperProvider>
                        <CurrentUserGroupProvider>
                          <SidebarProvider>
                            <App />
                          </SidebarProvider>
                        </CurrentUserGroupProvider>
                      </StepperProvider>
                    </GlobalPermissionsProvider>
                  </ProtectedApp>
                </StorageContext.Provider>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </StrictMode>,
  )
}
enableMocking().then(mountApp)
