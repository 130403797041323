export const SPACING = {
  unit: 8,
  pageSpacingY: 4,
  pageSpacingX: 4,
  headerSpacingY: 4,
  formFieldsSpacing: 2,
  sectionsSpacing: 4,
  breadcrumbsMarginBottom: 2,
  tableToolbarItemsSpacing: 4,
  formFieldsSectionSpacing: 4,
  formFieldsInnerSectionSpacing: 2,
}
