import {
  getTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGet,
  GetTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGetData,
} from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'

export const useGetTimePointSubmissionDetails = (
  queryKey: string[],
  params: GetTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGetData['path'],
) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGet({
        path: params,
      }),
    enabled: !!params.subject_id && !!params.site_id && !!params.timepoint_submission_id && !!params.study_id,
  })
}
