import { useQuery } from '@tanstack/react-query'
import { getCompanyUserCompaniesCompanyIdUsersUserIdGet } from '@common/config/api/client'
import queryKeyFactory from '../queryKeyFactory'

interface UseUserDetailsParams {
  companyId: string
  userId: string
}

export default function useUserDetails({ companyId, userId }: UseUserDetailsParams) {
  return useQuery({
    queryKey: queryKeyFactory.userDetails(userId),
    queryFn: async () => {
      const response = await getCompanyUserCompaniesCompanyIdUsersUserIdGet({
        path: {
          company_id: companyId,
          user_id: userId,
        },
        throwOnError: true,
      })
      return response.data
    },
    enabled: !!companyId && !!userId,
  })
}
