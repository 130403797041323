import { Theme } from '@mui/material/styles'

export function MenuOverrides(theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: theme.spacing(0),
        },
        paper: {
          boxShadow: theme.shadows[4],
          borderRadius: theme.spacing(1),
        },
      },
    },
  }
}
