import { useQuery } from '@tanstack/react-query'
import {
  getUserPermissionsAuthUserPermissionsGet,
  GetUserPermissionsAuthUserPermissionsGetResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

export const useUserPermissions = (options = {}) => {
  return useQuery<GetUserPermissionsAuthUserPermissionsGetResponse, AxiosError<HTTPValidationError>>({
    queryKey: ['userPermissions'],
    queryFn: async () => {
      const response = await getUserPermissionsAuthUserPermissionsGet({
        throwOnError: true,
      })
      return response.data ?? []
    },
    retry: false,
    throwOnError: true,
    ...options,
  })
}

export const useGetStudyPermissions = (studyId: string) => {
  return useQuery({
    queryKey: ['studyPermissions', studyId],
    queryFn: async () => {
      const response = await getUserPermissionsAuthUserPermissionsGet({
        query: { study_id: studyId },
        throwOnError: true,
      })
      return response.data ?? []
    },
    enabled: !!studyId,
  })
}
