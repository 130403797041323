import { Typography } from '@mui/material'

const Sites = () => {
  return (
    <div>
      <Typography>Sites</Typography>
    </div>
  )
}

export default Sites
