import { useState, MouseEvent } from 'react'
import { FormType, FormDefinitionsParams } from '../types'
import { FormBuilderJsonResponse } from '@study_setup/types/form_definition'
import { useCreateFormDefinition } from '@study_setup/hooks/useFormMutations'
import {
  useUpdateFormDefinition,
  useDeleteFormDefinition,
  useGetFormDefinitionById,
  TransformedFormDefinitions,
} from '.'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { queryClient } from '@common/config/api/queryClient'
import { getFormDefinitionFormsFormDefinitionIdGet } from '@common/config/api/client/services.gen'

export default function useFormManagementHandlers() {
  const [page, setPage] = useState(0)
  const [isFormBuilderVisible, setIsFormBuilderVisible] = useState(false)
  const [currentFormType, setCurrentFormType] = useState<FormType | null>(null)
  const [selectedFormType, setSelectedFormType] = useState<FormType | null>(null)
  const [editingFormId, setEditingFormId] = useState<string | null>(null)
  const [addMenuAnchor, setAddMenuAnchor] = useState<null | HTMLElement>(null)
  const [formDefinitionsParams, setFormDefinitionsParams] = useState<FormDefinitionsParams>({
    form_type: undefined,
    search: undefined,
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'name',
    asc_order: true,
  })

  const { mutate: createForm } = useCreateFormDefinition()
  const { mutate: updateForm } = useUpdateFormDefinition()
  const { mutate: deleteForm } = useDeleteFormDefinition()
  const { data: editingForm, isPending: isEditingFormPending } = useGetFormDefinitionById(editingFormId)

  const handleOpenFormMenu = (event: MouseEvent<HTMLElement>) => {
    setAddMenuAnchor(event.currentTarget)
  }

  const handleCloseFormMenu = () => {
    setAddMenuAnchor(null)
  }

  const handleFormTypeSelect = (formType: FormType) => {
    setSelectedFormType(formType)
    setIsFormBuilderVisible(true)
    handleCloseFormMenu()
  }

  const handleSaveForm = (formData: FormBuilderJsonResponse) => {
    if (!selectedFormType) return

    const newForm = {
      name: formData.title ?? formData.pages[0]?.title ?? 'Untitled Form',
      fields: formData.pages[0].elements as { [key: string]: unknown }[],
      type: selectedFormType.id,
    }

    createForm(newForm, {
      onSuccess: () => {
        toast(<Toast message="Form created successfully." variant="success" />)
        setIsFormBuilderVisible(false)
        setSelectedFormType(null)
        queryClient.invalidateQueries({ queryKey: ['formDefinitions'] })
      },
      onError: (error) => {
        toast(<Toast message={error.message || 'Failed to create form'} variant="error" />)
      },
    })
  }

  const handlePageChange = (
    formDefinitions: TransformedFormDefinitions | undefined,
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const isNextPage = newPage > page
    const cursor = isNextPage ? formDefinitions?.pagination.nextCursor : formDefinitions?.pagination.previousCursor

    if (cursor) {
      setFormDefinitionsParams((prev) => ({
        ...prev,
        next_cursor: isNextPage ? cursor : null,
        previous_cursor: isNextPage ? null : cursor,
      }))
      setPage(isNextPage ? page + 1 : page - 1)
    }
  }
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormDefinitionsParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleSearch = (value: string) => {
    setFormDefinitionsParams((prev) => ({
      ...prev,
      search: value || undefined,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = formDefinitionsParams.order_by_field === property && formDefinitionsParams.asc_order
    setFormDefinitionsParams((prev) => ({
      ...prev,
      order_by_field: property,
      asc_order: !isAsc,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleAddClick = (formType: FormType) => {
    setSelectedFormType(formType)
    setIsFormBuilderVisible(true)
  }

  const handleEditClick = (formId: string) => {
    setEditingFormId(formId)
    setIsFormBuilderVisible(true)
  }

  const handleSaveEditedForm = (formData: FormBuilderJsonResponse) => {
    if (!editingFormId || !editingForm) return

    const updatedForm = {
      name: formData.title ?? formData.pages[0]?.title ?? editingForm.name,
      fields: formData.pages[0]?.elements as { [key: string]: unknown }[],
      type: editingForm.type,
    }

    updateForm(
      { formId: editingFormId, formData: updatedForm },
      {
        onSuccess: () => {
          toast(<Toast message="Form updated successfully" variant="success" />)
          setEditingFormId(null)
          setIsFormBuilderVisible(false)
          queryClient.invalidateQueries({ queryKey: ['formDefinitions'] })
        },
        onError: (error) => {
          toast(<Toast message={error.message || 'Failed to update form'} variant="error" />)
        },
      },
    )
  }

  const handleDeleteForm = (formId: string) => {
    deleteForm(formId, {
      onSuccess: () => {
        toast(<Toast message="Form deleted successfully" variant="success" />)
        queryClient.invalidateQueries({ queryKey: ['formDefinitions'] })
      },
      onError: (error) => {
        toast(<Toast message={error.message || 'Failed to delete form'} variant="error" />)
      },
    })
  }

  const handleDuplicateForm = async (formId: string) => {
    try {
      const response = await getFormDefinitionFormsFormDefinitionIdGet({
        path: { form_definition_id: formId },
        throwOnError: true,
      })
      const formToDuplicate = response.data

      const duplicatedForm = {
        name: `${formToDuplicate.name} (Copy)`,
        fields: formToDuplicate.fields as { [key: string]: unknown }[],
        type: formToDuplicate.type,
      }

      createForm(duplicatedForm, {
        onSuccess: () => {
          toast(<Toast message="Form duplicated successfully" variant="success" />)
          queryClient.invalidateQueries({ queryKey: ['formDefinitions'] })
        },
        onError: (error) => {
          toast(<Toast message={error.message || 'Failed to duplicate form'} variant="error" />)
        },
      })
    } catch {
      toast(<Toast message="Failed to fetch form data for duplication" variant="error" />)
    }
  }

  const handleFormTypeChange = (formType: FormType | null) => {
    setCurrentFormType(formType)
  }

  const handleUpdateResponseCriteria = async (formId: string, responseCriteriaId: string) => {
    try {
      const response = await getFormDefinitionFormsFormDefinitionIdGet({
        path: { form_definition_id: formId },
        throwOnError: true,
      })
      const formToUpdate = response.data

      const updatedForm = {
        name: formToUpdate.name,
        type: formToUpdate.type,
        fields: formToUpdate.fields as { [key: string]: unknown }[],
        response_criteria_id: responseCriteriaId,
      }

      updateForm(
        { formId, formData: updatedForm },
        {
          onSuccess: () => {
            toast(<Toast message="Response criteria updated successfully" variant="success" />)
            queryClient.invalidateQueries({ queryKey: ['formDefinitions'] })
          },
          onError: (error) => {
            toast(<Toast message={error.message || 'Failed to update response criteria'} variant="error" />)
          },
        },
      )
    } catch {
      toast(<Toast message="Failed to fetch form data for update" variant="error" />)
    }
  }

  return {
    page,
    isFormBuilderVisible,
    selectedFormType,
    editingFormId,
    addMenuAnchor,
    formDefinitionsParams,
    setFormDefinitionsParams,
    editingForm,
    currentFormType,
    handleOpenFormMenu,
    handleCloseFormMenu,
    handleFormTypeSelect,
    handleSaveForm,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    handleSaveEditedForm,
    handleDeleteForm,
    handleDuplicateForm,
    setEditingFormId,
    setIsFormBuilderVisible,
    handleFormTypeChange,
    isEditingFormPending,
    handleUpdateResponseCriteria,
  }
}
