import { Typography } from '@mui/material'

const Queries = () => {
  return (
    <div>
      <Typography>Queries</Typography>
    </div>
  )
}

export default Queries
