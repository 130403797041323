import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Paper } from '@mui/material'
import { EnhancedTabsProps, TabConfig } from './tabs.types'
import EnhancedTabPanel from './EnhancedTabPanel'
import { a11yProps } from './utils'
import { SyntheticEvent } from 'react'
import { useActiveTab } from '@common/hooks/useActiveTab'
import { useTabOptionalComponent } from '@common/hooks/useTabOptionalComponent'

export default function EnhancedTabs<T extends string | number | symbol>({ tabs }: EnhancedTabsProps<T>) {
  const { currentActiveTab, setCurrentActiveTab } = useActiveTab()
  const { optionalComponent, isComponentVisible, setIsComponentUsed } = useTabOptionalComponent()

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setCurrentActiveTab(newValue)
  }
  const tab_values: TabConfig[] = Object.values(tabs)

  return (
    <Paper
      sx={{
        padding: 3,
      }}
    >
      <Box
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            bgcolor: 'action.hover',
            borderRadius: 1.5,
            padding: 0.5,
            typography: 'subtitle1',
          }}
        >
          <Tabs
            sx={{
              alignItems: 'center',
              height: 36,
              minHeight: 'unset',
            }}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            value={currentActiveTab}
            onChange={handleChange}
            aria-label="enhanced tabs"
          >
            {tab_values.map((tab, index) => (
              <Tab
                key={index}
                sx={{
                  borderRadius: 1,
                  bgcolor: currentActiveTab === index ? 'background.default' : 'action.hover',
                  color: 'text.primary',
                  typography: 'subtitle1',
                }}
                label={tab.label}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {isComponentVisible && <Box onClick={() => setIsComponentUsed(true)}>{optionalComponent}</Box>}
      </Box>

      {tab_values.map((tab, index) => (
        <EnhancedTabPanel key={index} value={currentActiveTab} index={index}>
          {tab.content}
        </EnhancedTabPanel>
      ))}
    </Paper>
  )
}
