import {
  excludeQcTasksQcTasksExcludePost,
  ExcludeQcTasksQcTasksExcludePostResponse,
  Body_exclude_qc_tasks_qc_tasks_exclude_post,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useExcludeTasksMutation = (): UseMutationResult<
  ExcludeQcTasksQcTasksExcludePostResponse,
  AxiosError<HTTPValidationError>,
  Body_exclude_qc_tasks_qc_tasks_exclude_post
> => {
  return useMutation<
    ExcludeQcTasksQcTasksExcludePostResponse,
    AxiosError<HTTPValidationError>,
    Body_exclude_qc_tasks_qc_tasks_exclude_post
  >({
    mutationFn: async (body) => {
      const response = await excludeQcTasksQcTasksExcludePost({
        body: body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTasksList'] })
    },
  })
}
