import { Home } from '@mui/icons-material'
import { RouteKeyEnum } from 'src/routes'

export const SERIES_LABEL_MANAGEMENT_BREADCRUMB_ITEMS = [
  {
    title: 'Home',
    route_key: RouteKeyEnum.HOME,
    icon: Home,
  },
  {
    title: 'Series Label Management',
    route_key: RouteKeyEnum.SERIES_LABEL_MANAGEMENT,
  },
]
