/**
 * Table component for displaying and managing form templates with sorting, filtering,
 * and action menu functionality
 */
import { MouseEvent } from 'react'
import { TableBody, TableCell, TableRow, Paper, Checkbox, IconButton, Link, Box } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FormData, FormType } from '@features/form_management/types'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { Order, HeadCell } from '@common/components/Table/table.types'
import FormActionMenu from './FormActionMenu'
import FormManagementTableToolbar from './FormManagementTableToolbar'
import {
  TransformedFormMetadata,
  TransformedFormDefinitions,
  useFormManagementTableHandlers,
} from '@features/form_management/hooks'
import ResponseCriteriaDialog from './ResponseCriteriaDialog'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import { NavTabsVariant } from '@common/components/Tab/tabs.types'

interface Props {
  formMetadata?: TransformedFormMetadata
  page: number
  formDefinitions: TransformedFormDefinitions | undefined
  currentFormType: FormType | null
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearch: (value: string) => void
  searchValue?: string
  order: Order
  orderBy: string
  onRequestSort: (event: MouseEvent<unknown>, property: keyof FormData) => void
  onAddClick: (formType: FormType) => void
  onEditClick: (formId: string) => void
  onDeleteForm: (formId: string) => void
  onDuplicateForm: (formId: string) => void
  onFormTypeChange: (formType: FormType | null) => void
  onUpdateResponseCriteria: (formId: string, responseCriteriaId: string) => void
}

const getHeadCells = (currentFormType: FormType | null): readonly HeadCell<FormData>[] => {
  const baseCells: HeadCell<FormData>[] = [
    { id: 'name', label: 'Form name', disablePadding: false },
    { id: 'created_by', label: 'Created By', disablePadding: false },
    { id: 'created_at', label: 'Date Created', disablePadding: false },
    { id: 'updated_at', label: 'Last Edited', disablePadding: false },
  ]

  if (currentFormType?.id === 'ECRF') {
    baseCells.splice(1, 0, { id: 'response_criteria', label: 'Response Criteria', disablePadding: false })
  }

  return [
    ...baseCells,
    { id: 'editForm', label: '', disablePadding: false },
    { id: 'actions', label: '', disablePadding: false },
  ]
}

export default function FormManagementTable({
  formMetadata,
  formDefinitions,
  currentFormType,
  onSearch,
  searchValue,
  onRowsPerPageChange,
  handlePageChange,
  page,
  order,
  orderBy,
  onRequestSort,
  onAddClick,
  onEditClick,
  onDeleteForm,
  onDuplicateForm,
  onFormTypeChange,
  onUpdateResponseCriteria,
}: Readonly<Props>) {
  const {
    selected,
    menuAnchorEl,
    isResponseCriteriaDialogOpen,
    handleEditClick,
    handleSelectAllClick,
    handleClick,
    handleOpenMenu,
    handleCloseMenu,
    handleDeleteForm,
    handleDuplicateForm,
    handleOpenResponseCriteriaDialog,
    handleResponseCriteriaSelect,
    setIsResponseCriteriaDialogOpen,
  } = useFormManagementTableHandlers({
    onEditClick,
    onDeleteForm,
    onDuplicateForm,
    onUpdateResponseCriteria,
    formDefinitions,
  })

  const headCells = getHeadCells(currentFormType)

  const formTypeRoutes =
    formMetadata?.formTypes?.map((formType) => ({
      path: `#${formType.name}`,
      label: formType.label,
      id: formType.id,
    })) ?? []

  return (
    <Box component="section">
      <FormManagementTableToolbar
        formTypes={formMetadata?.formTypes}
        onSearch={onSearch}
        searchValue={searchValue}
        onAddClick={onAddClick}
        currentFormType={currentFormType}
        onFormTypeChange={onFormTypeChange}
      />
      <Paper sx={{ p: 3 }}>
        <EnhancedNavTabs routes={formTypeRoutes} variant={NavTabsVariant.SEGMENTED_CONTROLS} />
        <EnhancedTable<FormData>
          rows={formDefinitions?.items ?? []}
          headCells={headCells}
          order={order}
          orderBy={orderBy as keyof FormData}
          selected={selected}
          page={page}
          next_cursor={formDefinitions?.pagination.nextCursor}
          previous_cursor={formDefinitions?.pagination.previousCursor}
          rowsPerPage={formDefinitions?.pagination.limit ?? 50}
          rowsPerPageOptions={[10, 25, 50]}
          onRequestSort={onRequestSort}
          onSelectAllClick={handleSelectAllClick}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onRowsPerPageChange}
        >
          <TableBody>
            {formDefinitions?.items.map((row) => {
              const isItemSelected = selected.indexOf(row.id) !== -1

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  {currentFormType?.id === 'ECRF' && (
                    <TableCell>
                      <Link component="button" onClick={() => handleOpenResponseCriteriaDialog(row.id)}>
                        {row.responseCriteria?.name ?? 'Edit Response Criteria'}
                      </Link>
                    </TableCell>
                  )}
                  <TableCell>{row.createdByUser?.name ?? '-'}</TableCell>
                  <TableCell>{row.createdAt ?? '-'}</TableCell>
                  <TableCell>{row.updatedAt ?? '-'}</TableCell>
                  <TableCell align="right">
                    <Link onClick={(e) => handleEditClick(e, row.id)}>Edit form</Link>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={(e) => handleOpenMenu(e, row.id)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </EnhancedTable>
        <FormActionMenu
          anchorEl={menuAnchorEl}
          onClose={handleCloseMenu}
          onDuplicate={handleDuplicateForm}
          onDelete={handleDeleteForm}
        />
        <ResponseCriteriaDialog
          open={isResponseCriteriaDialogOpen}
          onClose={() => setIsResponseCriteriaDialogOpen(false)}
          responseCriteria={formMetadata?.responseCriteria ?? []}
          onSelect={handleResponseCriteriaSelect}
        />
      </Paper>
    </Box>
  )
}
