import { Chip } from '@mui/material'
import { Status } from '@common/config/api/client'
import { STUDY_STATUS_COLOR, STUDY_STATUS_TEXT_COLOR } from './StudyStatusColor'
import { formatStatusText } from '@features/study_view/utils'

export default function StudyStatusChip({ status }: { status: Status }) {
  return (
    <Chip
      data-testid={`status-chip-${status}`}
      size="small"
      label={formatStatusText(status)}
      variant={status === 'DRAFT' ? 'outlined' : 'filled'}
      sx={{
        backgroundColor: status === 'DRAFT' ? 'transparent' : STUDY_STATUS_COLOR[status],
        color: STUDY_STATUS_TEXT_COLOR[status],
        padding: 0.5,
        alignItems: 'center',
        borderRadius: 4,
      }}
    />
  )
}
