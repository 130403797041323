import { Link as MuiLink } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { ReactNode } from 'react'

interface LinkProps {
  to: string
  children: ReactNode
}

export default function Link({ to, children }: LinkProps) {
  return (
    <MuiLink component={RouterLink} to={to}>
      {children}
    </MuiLink>
  )
}
