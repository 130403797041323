import { IconButton, TableCell, TableRow } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import QueryStatusChip from './QueryStatusChip'
import SubjectStatusChip from './SubjectStatusChip'
import { TimepointSubject } from '@common/config/api/client'

export default function SubjectTableRow({
  subject,
  expanded,
  setExpanded,
}: {
  subject: TimepointSubject
  expanded: boolean
  setExpanded: (expanded: boolean) => void
}) {
  return (
    <TableRow data-testid="subject-table-row">
      <TableCell
        padding="checkbox"
        sx={{
          position: 'sticky',
          bgcolor: 'background.paper',
          left: 0,
        }}
        data-testid="expand-cell"
      >
        <IconButton size="small" onClick={() => setExpanded(!expanded)} data-testid="expand-button">
          {expanded ? (
            <KeyboardArrowUpIcon data-testid="icon-expanded" />
          ) : (
            <KeyboardArrowDownIcon data-testid="icon-collapsed" />
          )}
        </IconButton>
      </TableCell>
      <TableCell
        sx={{
          position: 'sticky',
          bgcolor: 'background.paper',
          left: 48,
        }}
        data-testid="pseudo-id-cell"
      >
        {subject.pseudo_id}
      </TableCell>
      <TableCell
        sx={{
          position: 'sticky',
          bgcolor: 'background.paper',
          left: 218,
        }}
        data-testid="status-chip-cell"
      >
        <SubjectStatusChip subjectStatus={subject.status} size="small" />
      </TableCell>
      <TableCell
        sx={{
          position: 'sticky',
          bgcolor: 'background.paper',
          left: 388,
        }}
        data-testid="query-chip-cell"
      >
        <QueryStatusChip queryStatus="none" />
      </TableCell>
      {subject.study_timepoints?.map((studyTimepoint) => (
        <TableCell key={studyTimepoint.timepoint_id} data-testid={`timepoint-cell-${studyTimepoint.timepoint_id}`}>
          {studyTimepoint.timepoint_submissions?.length} Timepoints
        </TableCell>
      ))}
    </TableRow>
  )
}
