export function SwitchOverrides() {
  return {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#78909C',
        },
        track: {
          backgroundColor: '#B0BEC5',
        },
      },
    },
  }
}
