import { Divider, Stack, Typography } from '@mui/material'
import ExamDetailPopupMenuList from './ExamDetailPopupMenuList'

export default function ExamDetails() {
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" paddingX={2} paddingY={1}>
      <Stack spacing={0.25}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Modality
        </Typography>
        <Typography variant="body2">CT</Typography>
      </Stack>
      <Stack spacing={0.25}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Study description
        </Typography>
        <Typography variant="body2">Abdominal Imaging for Cancer Staging</Typography>
      </Stack>
      <Stack spacing={0.25}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Date
        </Typography>
        <Typography variant="body2">23/05/24</Typography>
      </Stack>
      <Stack spacing={0.25}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          № of series
        </Typography>
        <Typography variant="body2">2</Typography>
      </Stack>
      <Stack spacing={0.25}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Criteria check
        </Typography>
        <Typography variant="body2">0 issues</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Divider orientation="vertical" flexItem />
        <ExamDetailPopupMenuList />
      </Stack>
    </Stack>
  )
}
