import {
  getAllSubjectsTimepointsStudiesStudyIdSitesSiteIdGet,
  GetAllSubjectsTimepointsStudiesStudyIdSitesSiteIdGetData,
} from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'

export const useGetSubjectsList = (
  queryKey: string[],
  params: GetAllSubjectsTimepointsStudiesStudyIdSitesSiteIdGetData['path'],
) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getAllSubjectsTimepointsStudiesStudyIdSitesSiteIdGet({
        path: params,
      }),
  })
}
