import BaseDialog from '@common/components/BaseDialog'
import { Dropdown } from '@common/components/Form/Dropdown'
import RadioGroup from '@common/components/Form/RadioGroup'
import { TextField } from '@common/components/Form/TextField'
import { zodResolver } from '@hookform/resolvers/zod'
import { Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { SeriesLabelCreateSchema } from '@features/series_label_management/schemas/seriesLabelValidationSchema'
import { useGetSeriesLabelsMetadata } from '../hooks/useSeriesLabelsQueries'

export type SeriesLabelFormValues = {
  name: string
  imaging_modality_id: string
  allow_qc_to_duplicate: boolean
}

export interface CreateSeriesLabelDialogProps {
  onSave: (data: SeriesLabelFormValues) => void
  onClose: () => void
  data?: SeriesLabelFormValues | null
  title: string
}

const CreateOrUpdateSeriesLabelDialog = ({ onSave, onClose, data }: CreateSeriesLabelDialogProps) => {
  const ALLOW_QC_TO_DUPLICATE_OPTIONS = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ]
  const form = useForm<SeriesLabelFormValues>({
    resolver: zodResolver(SeriesLabelCreateSchema),
    mode: 'onBlur',
    defaultValues: {
      name: data?.name || '',
      imaging_modality_id: data?.imaging_modality_id,
      allow_qc_to_duplicate: data?.allow_qc_to_duplicate || false,
    },
  })

  const { data: { imaging_modalities } = {}, isLoading } = useGetSeriesLabelsMetadata()

  const handleSave = (formData: SeriesLabelFormValues) => {
    onSave(formData)
    form.reset()
    onClose()
  }

  return (
    <BaseDialog
      title="New Series Label"
      description="Please provide the details for this series label below"
      open={true}
      onClose={onClose}
      onSubmit={handleSave}
      form={form}
      submitLabel="Save Series Label"
    >
      <Typography variant="body1" color="text.primary" sx={{ marginBottom: 4 }}>
        1. General series label information
      </Typography>
      <TextField
        name="name"
        control={form.control}
        label="Series label name"
        placeholder="Enter series label name here"
      />
      <Dropdown
        name="imaging_modality_id"
        control={form.control}
        label="Imaging Modality"
        placeholder="Lorem ipsum"
        options={imaging_modalities || []}
        loading={isLoading}
      />
      <Stack direction="row" gap={3} alignItems="center">
        <Typography>Allow QC to duplicate</Typography>
        <RadioGroup
          name="allow_qc_to_duplicate"
          control={form.control}
          options={ALLOW_QC_TO_DUPLICATE_OPTIONS}
          row
        ></RadioGroup>
      </Stack>
    </BaseDialog>
  )
}

export default CreateOrUpdateSeriesLabelDialog
