import React, { createContext, useCallback, useContext, useState } from 'react'

export type StepType = {
  label: string
  optional?: boolean
  completed?: boolean
}

export interface StepperContextType {
  steps: StepType[]
  activeStep: number
  setSteps: (steps: StepType[]) => void
  setActiveStep: (step: number) => void
  markStepCompleted: (stepIndex: number) => void
  markStepOptional: (stepIndex: number) => void
  resetSteps: () => void
}

const defaultContext: StepperContextType = {
  steps: [],
  activeStep: 0,
  setSteps: () => {},
  setActiveStep: () => {},
  markStepCompleted: () => {},
  markStepOptional: () => {},
  resetSteps: () => {},
}

export const StepperContext = createContext<StepperContextType>(defaultContext)

export const StepperProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [steps, setStepsState] = useState<StepType[]>([])
  const [activeStep, setActiveStepState] = useState<number>(0)

  const setSteps = useCallback((newSteps: StepType[]) => {
    setStepsState(newSteps)
  }, [])

  const setActiveStep = useCallback(
    (step: number) => {
      if (step >= 0 && step < steps.length) {
        setActiveStepState(step)
      }
    },
    [steps.length],
  )

  const markStepCompleted = useCallback((stepIndex: number) => {
    setStepsState((currentSteps) => {
      if (stepIndex < 0 || stepIndex >= currentSteps.length) return currentSteps

      return currentSteps.map((step, index) => (index === stepIndex ? { ...step, completed: true } : step))
    })
  }, [])

  const markStepOptional = useCallback((stepIndex: number) => {
    setStepsState((currentSteps) => {
      if (stepIndex < 0 || stepIndex >= currentSteps.length) return currentSteps

      return currentSteps.map((step, index) => (index === stepIndex ? { ...step, optional: true } : step))
    })
  }, [])

  const resetSteps = useCallback(() => {
    setStepsState([])
    setActiveStepState(0)
  }, [])

  const value = {
    steps,
    activeStep,
    setSteps,
    setActiveStep,
    markStepCompleted,
    markStepOptional,
    resetSteps,
  }

  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
}

export const useStepper = () => {
  const context = useContext(StepperContext)

  if (!context) {
    throw new Error('useStepper must be used within a StepperProvider')
  }

  return context
}
