import { useState, MouseEvent, useEffect } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import EnhancedTableBodyCompletedTasks from '../components/EnhancedTableBodyCompletedTasks'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { GetQcTasksQcTasksGetData, Task } from '@common/config/api/client'
import { usePaginatedTasks } from '@qc_task_list_view/hooks/usePaginatedTasks'
import { ROW_PER_PAGE_OPTIONS } from '@common/constants/table'
import { useTabOptionalComponent } from '@common/hooks/useTabOptionalComponent'
import { useParams } from 'react-router-dom'

const headCells: readonly HeadCell<Task>[] = [
  { id: 'task_pseudo_id', disablePadding: true, label: 'Blind Code', disableSort: false },
  { id: 'sites', disablePadding: false, label: 'Site ID', disableSort: false },
  { id: 'subject', disablePadding: false, label: 'Subject ID', disableSort: false },
  { id: 'timepoint_requirement', disablePadding: false, label: 'Timepoint', disableSort: false },
  { id: 'task_state', disablePadding: false, label: 'Task Status', disableSort: false },
  { id: 'completed_date', disablePadding: false, label: 'Completed Date', disableSort: false },
]

export default function QcTaskListTableCompletedTasks() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Task | string>('task_pseudo_id')
  const [page, setPage] = useState(0)
  const { setOptionalComponent, setIsComponentVisible, setIsComponentUsed } = useTabOptionalComponent()
  const { studyId } = useParams()

  const [paginationParams, setPaginationParams] = useState<GetQcTasksQcTasksGetData['query']>({
    limit: ROW_PER_PAGE_OPTIONS[2],
    next_cursor: null,
    previous_cursor: null,
    category: 'completed_tasks',
    study_id: studyId!,
    asc_order: true,
    order_by_field: 'created_at',
  })

  useEffect(() => {
    setIsComponentVisible(false)
    setIsComponentUsed(false)
    setOptionalComponent(null)
  }, [])

  const { data, error, isLoading } = usePaginatedTasks(
    ['QCTasksList', 'CompletedTasks', `${page}`, `${paginationParams.limit}`],
    paginationParams,
  )

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof Task) => {
    const isAsc = orderBy.split('.')[0] === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')

    switch (property) {
      case 'sites':
        setOrderBy('sites.site_pseudo_id')
        break
      case 'subject':
        setOrderBy('subject.subject_pseudo_id')
        break
      case 'timepoint_requirement':
        setOrderBy('timepoint_requirement.name')
        break
      default:
        setOrderBy(property)
    }
  }

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      // Moving to next page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: data?.data?.pagination.next_cursor,
        previous_cursor: null,
      }))
      setPage(page + 1)
    } else if (newPage < 0) {
      // Moving to previous page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: null,
        previous_cursor: data?.data?.pagination.previous_cursor,
      }))
      setPage(page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  return (
    <EnhancedTable<Task>
      rows={data?.data?.items || []}
      headCells={headCells}
      order={order}
      orderBy={orderBy.split('.')[0] as keyof Task}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      rowsPerPage={paginationParams.limit || 0}
      next_cursor={data?.data?.pagination.next_cursor}
      previous_cursor={data?.data?.pagination.previous_cursor}
      onRequestSort={handleRequestSort}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
    >
      <EnhancedTableBodyCompletedTasks rows={data?.data?.items || []} order={order} orderBy={orderBy} />
    </EnhancedTable>
  )
}
