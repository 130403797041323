import { Button, Paper, Stack, Typography } from '@mui/material'

export default function StudyDefinition() {
  return (
    <Paper
      sx={{
        padding: 3,
        borderRadius: 4,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Study and series definition</Typography>
        <Button
          variant="outlined"
          sx={{
            color: 'text.primary',
            borderColor: 'secondary.dark',
            py: 0.75,
            px: 2,
          }}
        >
          Create
        </Button>
      </Stack>
    </Paper>
  )
}
