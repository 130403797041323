import { MenuItem } from '@mui/material'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { SeriesLabelActionMenuProps } from '@features/series_label_management/types'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'

export default function SeriesLabelActionMenu({
  anchorEl,
  onClose,
  onDuplicate,
  onDelete,
}: Readonly<SeriesLabelActionMenuProps>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      <MenuItem onClick={onDuplicate}>
        <ContentCopyRoundedIcon sx={{ mr: 1.5 }} />
        Duplicate Series Label
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <DeleteOutlineOutlinedIcon sx={{ mr: 1.5 }} />
        Delete Series Label
      </MenuItem>
    </BaseDropdownMenu>
  )
}
