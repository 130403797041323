import { Badge, Box, IconButton, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import GroupedFilesBlock from 'src/features/subject/components/GroupedFilesBlock'
import SeriesRow from '@features/subject/components/SeriesRow'
import NonDICOMRow from '@features/subject/components/NonDICOMRow'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'

const STUDIES = [
  {
    name: 'Abdominal Imaging for Cancer Staging',
    date: new Date(),
    series: [
      {
        description: 'CT Pelvis with contrast',
        tooltipText: 'Cancer Images',
        acquisitionDate: new Date(),
        seriesNumber: '0011',
        instances: 28,
        modality: 'CT',
      },
      {
        description: 'CT Abdomen with contrast',
        tooltipText: 'Cancer Images',
        acquisitionDate: new Date(),
        seriesNumber: '0022',
        instances: 36,
        modality: 'CT',
      },
    ],
  },
  {
    name: 'Head Trauma Evaluation',
    date: new Date(),
    series: [
      {
        description: 'Skull X-ray, Lateral and AP Views',
        tooltipText: 'X-ray Images',
        acquisitionDate: new Date(),
        seriesNumber: '0039',
        instances: 10,
        modality: 'XR',
      },
    ],
  },
]

const NON_DICOM_FILES = [
  {
    description: 'Head_Trauma_XRay_01',
    tooltipText: 'Head_Trauma_XRay_01.png',
    seriesNumber: '0001',
  },
  {
    description: 'Head_Trauma_XRay_02',
    tooltipText: 'Head_Trauma_XRay_02.png',
    seriesNumber: '0002',
  },
  {
    description: 'Head_Trauma_XRay_03',
    tooltipText: 'Head_Trauma_XRay_03.png',
    seriesNumber: '0003',
  },
]

export default function CheckStep() {
  const renderStudyActions = () => {
    return (
      <Box display="flex" gap={1}>
        <IconButton>
          <RemoveRedEyeRoundedIcon sx={{ color: 'text.primary' }} />
        </IconButton>
        <IconButton>
          <DeleteOutlineOutlinedIcon sx={{ color: 'text.primary' }} />
        </IconButton>
      </Box>
    )
  }

  const renderNonDicomActions = () => {
    return (
      <IconButton>
        <DeleteOutlineOutlinedIcon sx={{ color: 'text.primary' }} />
      </IconButton>
    )
  }

  return (
    <Box pb={12}>
      <Typography color="text.primary" variant="h4">
        Studies and adherence criteria check
      </Typography>
      <Typography color="text.secondary" variant="body2" mb={4}>
        Check the studies from uploaded files and enter details for non-DICOM files.
      </Typography>
      <Stack gap={2}>
        {STUDIES.map((item, index) => (
          <GroupedFilesBlock
            title={item.name}
            subtitle={`Study date: ${dayjs(item.date).format('DD/MM/YYYY')}`}
            key={index}
            status="PASSED"
            actions={renderStudyActions()}
          >
            <Box pt={4}>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="subtitle2" color="text.secondary" mr={2.25}>
                  Series
                </Typography>
                <Badge variant="standard" color="secondary" badgeContent={item.series.length} />
              </Box>
              <Box borderRadius={3} overflow="hidden">
                {item.series.map((series, subIndex) => (
                  <SeriesRow
                    key={subIndex}
                    isBorderNeeded={subIndex !== item.series.length - 1}
                    description={series.description}
                    acquisitionDate={series.acquisitionDate}
                    seriesNumber={series.seriesNumber}
                    instances={series.instances}
                    modality={series.modality}
                    tooltipText={series.tooltipText}
                  />
                ))}
              </Box>
            </Box>
          </GroupedFilesBlock>
        ))}
        <GroupedFilesBlock
          title="Non-DICOM files"
          subtitle="Complete the missing information for each file. Adherence criteria check is not required."
          actions={renderNonDicomActions()}
        >
          <Box pt={4}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="subtitle2" color="text.secondary" mr={2.25}>
                Files to be converted to DICOM
              </Typography>
              <Badge variant="standard" color="secondary" badgeContent={NON_DICOM_FILES.length} />
            </Box>
            <Box borderRadius={3} overflow="hidden">
              {NON_DICOM_FILES.map((item, index) => (
                <NonDICOMRow
                  key={index}
                  isBorderNeeded={index !== NON_DICOM_FILES.length - 1}
                  description={item.description}
                  seriesNumber={item.seriesNumber}
                  tooltipText={item.tooltipText}
                />
              ))}
            </Box>
          </Box>
        </GroupedFilesBlock>
      </Stack>
    </Box>
  )
}
