import { Stack, Typography, Button } from '@mui/material'
import { useMemo, useState } from 'react'
import Grid from '@mui/material/Grid2'
import CardView from '@features/study_view/card_view/pages/CardView'
import StudyTableView from '@features/study_view/Table_view/pages/StudyTableView'
import { AddRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import StudiesOverviewHeader from '../components/StudiesOverviewHeader'
import { useGlobalPermissions } from '@auth/hooks/useGlobalPermissions'
import { StudyPermission } from '@auth/permissionsEnum'
import { useSidebar } from '@common/context/SidebarContext'

export default function Home() {
  const [activeControl, setActiveControl] = useState(0)
  const layout = useMemo(() => (activeControl == 0 ? 'card' : 'table'), [activeControl])
  const { hasPermission } = useGlobalPermissions()
  const { isCollapsed } = useSidebar()

  const navigate = useNavigate()
  const handleCreateSutdyClick = () => {
    navigate(ROUTES.CREATE_STUDY.path)
  }

  return (
    <Grid
      container
      size={12}
      sx={{
        paddingX: {
          xs: 0,
          xl: isCollapsed ? 21 : 10,
        },
      }}
    >
      <Stack spacing={4} width="100%">
        <Stack spacing={4} direction="row" justifyContent="space-between">
          <Typography variant="h4">Clinical Studies</Typography>
          {hasPermission(StudyPermission.ALL) && (
            <Button
              variant="contained"
              sx={{
                maxHeight: 36,
              }}
              startIcon={<AddRounded />}
              onClick={handleCreateSutdyClick}
            >
              Create Study
            </Button>
          )}
        </Stack>
        <Stack spacing={2}>
          <StudiesOverviewHeader activeControl={activeControl} setActiveControl={setActiveControl} />
          {layout === 'card' ? <CardView /> : <StudyTableView />}
        </Stack>
      </Stack>
    </Grid>
  )
}
