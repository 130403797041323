import { getQcTaskQcTasksQcTaskIdGet } from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'

export const useQCTask = (queryKey: string[], studyID: string | undefined, qcTaskId: string | undefined) => {
  return useQuery({
    queryKey: [...queryKey, studyID, qcTaskId],
    queryFn: () =>
      getQcTaskQcTasksQcTaskIdGet({
        path: { qc_task_id: qcTaskId! },
        query: { study_id: studyID! },
      }),
    enabled: !!studyID && !!qcTaskId,
  })
}
