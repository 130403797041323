import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { SyntheticEvent, useEffect, useState } from 'react'
import { Link, useLocation, matchPath } from 'react-router-dom'
import { a11yProps } from './utils'
import { NavTabsProps, NavTabsVariant } from './tabs.types'
import { styled, SxProps, Theme } from '@mui/material/styles'

export default function EnhancedNavTabs({ routes, variant }: NavTabsProps) {
  const location = useLocation()
  const currentPath = `${location.hash}`
  const initialIndex = routes.findIndex((route) => {
    if (route.path.includes('#')) {
      return route.path === currentPath
    }
    return matchPath(route.path, location.pathname)
  })
  const [value, setValue] = useState(initialIndex !== -1 ? initialIndex : 0)
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    borderRadius: `${theme.spacing(1)} !important`,
    padding: theme.spacing(0.5),
    minHeight: 'auto',
  }))

  const mapVariantToSxProp = (index: number): SxProps<Theme> => ({
    borderRadius: (theme) => theme.spacing(1),
    ...(variant === NavTabsVariant.SEGMENTED_CONTROLS
      ? {
          color: (theme) => `${theme.palette.text.primary} !important`,
          typography: 'subtitle1',
          backgroundColor: index === value ? 'white' : 'transparent',
          paddingY: (theme) => `${theme.spacing(0)} !important`,
          minHeight: (theme) => `${theme.spacing(4.5)} !important`,
          '&.Mui-selected': {
            color: (theme) => `${theme.palette.primary.main} !important`,
          },
        }
      : {
          color: (theme) => `${theme.palette.text.secondary} !important`,
          typography: 'button',
          '&.Mui-selected': {
            color: (theme) => `${theme.palette.primary.main} !important`,
          },
        }),
  })

  useEffect(() => {
    const currentPath = `${location.pathname}${location.hash}`

    const currentIndex = routes.findIndex((route) => {
      if (route.path.includes('#')) {
        // For routes with hash, do exact match
        return route.path === currentPath
      }
      // For routes without hash, match only pathname
      return matchPath(route.path, location.pathname)
    })

    if (currentIndex !== -1) {
      setValue(currentIndex)
    }
  }, [routes, location.pathname, location.hash])

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="enhanced tabs"
        data-testid="tabs"
        TabIndicatorProps={{
          style: variant == NavTabsVariant.SEGMENTED_CONTROLS ? { display: 'none' } : {},
        }}
      >
        {routes.map((route, index) => (
          <Tab
            key={route.path}
            data-testid={`tab-${index}`}
            component={Link}
            to={route.path}
            sx={mapVariantToSxProp(index)}
            label={route.label}
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
    </Box>
  )
}
