import { useGlobalPermissions } from '@auth/hooks/useGlobalPermissions'
import { Permission } from '@auth/permissionsEnum'
import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from 'src/routes'

interface ProtectedRouteProps {
  children: ReactNode
  permissions: Permission[]
}

export function ProtectedRoute({ children, permissions = [] }: ProtectedRouteProps) {
  const { hasAllPermissions } = useGlobalPermissions()
  const location = useLocation()

  if (!hasAllPermissions(permissions)) {
    return <Navigate to={ROUTES.FORBIDDEN.path} state={{ from: location }} />
  }

  return <>{children}</>
}
