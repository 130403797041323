import { TabConfig } from '@common/components/Tab/tabs.types'
import ReaderTaskListTableMyTasks from './pages/ReaderTaskListTableMyTasks'
import ReaderTaskListTableTaskPool from './pages/ReaderTaskListTableTaskPool'
import ReaderTaskListTableCompletedTasks from './pages/ReaderTaskListTableCompletedTasks'
import ReaderTaskListTableSkippedTasks from './pages/ReaderTaskListTableSkippedTasks'
import { ReadTaskPermission } from '@auth/permissionsEnum'

export enum ReaderTaskListTabsKeyEnum {
  MY_TASKS = 'MY_TASKS',
  TASK_POOL = 'TASK_POOL',
  SKIPPED = 'SKIPPED',
  COMPLETE = 'COMPLETE',
}

export type ReaderTaskListTabsKey = ReaderTaskListTabsKeyEnum

export const READER_TASK_LIST_TABS: Record<ReaderTaskListTabsKey, TabConfig> = {
  MY_TASKS: {
    label: 'My tasks',
    content: <ReaderTaskListTableMyTasks />,
    permission: ReadTaskPermission.VIEW_ASSIGNED_TASKS,
  },
  TASK_POOL: {
    label: 'Task pool',
    content: <ReaderTaskListTableTaskPool />,
    permission: ReadTaskPermission.VIEW_TASK_POOL,
  },
  SKIPPED: {
    label: 'Skipped',
    content: <ReaderTaskListTableSkippedTasks />,
    permission: ReadTaskPermission.VIEW_SKIPPED_TASKS,
  },
  COMPLETE: {
    label: 'Complete',
    content: <ReaderTaskListTableCompletedTasks />,
    permission: ReadTaskPermission.VIEW_COMPLETED_TASKS,
  },
}
