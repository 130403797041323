import { ReactNode, useState } from 'react'
import { TabOptionalComponentContext, TabOptionalComponentContextType } from './TabOptionalComponentContext'

export const TabOptionalComponentProvider = ({ children }: { children: ReactNode }) => {
  const [optionalComponent, setOptionalComponent] = useState<ReactNode>(null)
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false)
  const [isComponentUsed, setIsComponentUsed] = useState<boolean>(false)

  const contextValue: TabOptionalComponentContextType = {
    optionalComponent,
    isComponentVisible,
    isComponentUsed,
    setOptionalComponent,
    setIsComponentVisible,
    setIsComponentUsed,
  }

  return <TabOptionalComponentContext.Provider value={contextValue}>{children}</TabOptionalComponentContext.Provider>
}
