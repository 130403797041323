import { Typography, Stack, Grid2 as Grid } from '@mui/material'
import { Breadcrumb } from '@common/components/Breadcrumb'
import { CurrentActiveTabProvider } from '@common/context/CurrentActiveTabProvider'
import { TabOptionalComponentProvider } from '@common/context/TabOptionalComponentProvider'
import { USER_MANAGEMENT_BREADCRUMB_ITEMS } from './constants'
import EnhancedTabs from '@common/components/Tab/EnhancedTabs'
import ProfileInformation from './details/components/ProfileInformation'
import StudyAssignments from './details/components/StudyAssignments'
import Settings from './details/components/Settings'
import { useUserDetails } from './hooks'
import { useParams } from 'react-router-dom'
import { RouteKeyEnum } from 'src/routes'

const formatDate = (date: string) => {
  return new Date(date)
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
    .replace(/\./g, '/')
}

const getBreadcrumbItems = (userName: string | undefined) => [
  ...USER_MANAGEMENT_BREADCRUMB_ITEMS,
  {
    title: userName ?? 'User Details',
    route_key: RouteKeyEnum.USER_PROFILE,
  },
]

export default function UserDetails() {
  const { userId, companyId } = useParams<{ userId: string; companyId: string }>()
  const { data: user, isPending: isUserDetailsPending } = useUserDetails({
    companyId: companyId ?? '',
    userId: userId ?? '',
  })

  const isLoading = isUserDetailsPending
  if (isLoading) {
    return <div>Loading...</div>
  }

  const tabs = {
    profile: {
      label: 'Profile information',
      content: <ProfileInformation user={user} />,
    },
    assignments: {
      label: 'Study Assignments',
      content: <StudyAssignments />,
    },
    settings: {
      label: 'Settings',
      content: <Settings />,
    },
  }

  return (
    <>
      <Breadcrumb items={getBreadcrumbItems(user?.user_name)} />
      <Grid container columnSpacing={3} sx={{ marginTop: 4 }}>
        <Grid size={12}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4">{user?.user_name}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2" color="text.secondary">
                User created: {user?.created_at && formatDate(user.created_at)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                -{' '}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Created by: {user?.created_by ?? '-'}
              </Typography>
            </Stack>
            <CurrentActiveTabProvider>
              <TabOptionalComponentProvider>
                <EnhancedTabs tabs={tabs} />
              </TabOptionalComponentProvider>
            </CurrentActiveTabProvider>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
