import {
  createTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionPost,
  CreateTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionPostResponse,
  CreateTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionPostData,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useCreateTimepointSubmission = (): UseMutationResult<
  CreateTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionPostResponse,
  AxiosError<HTTPValidationError>,
  CreateTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionPostData
> => {
  return useMutation<
    CreateTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionPostResponse,
    AxiosError<HTTPValidationError>,
    CreateTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionPostData
  >({
    mutationFn: async (query) => {
      const response = await createTimepointSubmissionStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionPost({
        body: query.body,
        path: query.path,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['SubjectsList'] })
    },
  })
}
