import { useState, MouseEvent, ChangeEvent } from 'react'
import { ResponseCriteriaSchema } from '@common/config/api/client'
import { TransformedFormDefinitions } from '@features/form_management/hooks'

interface UseFormManagementTableHandlersProps {
  onEditClick: (formId: string) => void
  onDeleteForm: (formId: string) => void
  onDuplicateForm: (formId: string) => void
  onUpdateResponseCriteria: (formId: string, responseCriteriaId: string) => void
  formDefinitions: TransformedFormDefinitions | undefined
}

export default function useFormManagementTableHandlers({
  onEditClick,
  onDeleteForm,
  onDuplicateForm,
  onUpdateResponseCriteria,
  formDefinitions,
}: UseFormManagementTableHandlersProps) {
  const [selected, setSelected] = useState<string[]>([])
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedFormId, setSelectedFormId] = useState<string | null>(null)
  const [isResponseCriteriaDialogOpen, setIsResponseCriteriaDialogOpen] = useState(false)
  const [selectedFormForCriteria, setSelectedFormForCriteria] = useState<string | null>(null)

  const handleEditClick = (e: MouseEvent<HTMLElement>, formId: string) => {
    e.preventDefault()
    e.stopPropagation()
    onEditClick(formId)
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(formDefinitions?.items.map((row) => row.id) ?? [])
    } else {
      setSelected([])
    }
  }
  const handleClick = (_event: MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    const newSelected = selectedIndex === -1 ? [...selected, id] : selected.filter((selectedId) => selectedId !== id)
    setSelected(newSelected)
  }

  const handleOpenMenu = (event: MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
    setSelectedFormId(id)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
    setSelectedFormId(null)
  }

  const handleDeleteForm = () => {
    if (selectedFormId) {
      onDeleteForm(selectedFormId)
      handleCloseMenu()
    }
  }

  const handleDuplicateForm = () => {
    if (selectedFormId) {
      onDuplicateForm(selectedFormId)
      handleCloseMenu()
    }
  }

  const handleOpenResponseCriteriaDialog = (formId: string) => {
    setSelectedFormForCriteria(formId)
    setIsResponseCriteriaDialogOpen(true)
  }

  const handleResponseCriteriaSelect = (criteria: ResponseCriteriaSchema) => {
    if (selectedFormForCriteria) {
      onUpdateResponseCriteria(selectedFormForCriteria, criteria.id)
    }
    setIsResponseCriteriaDialogOpen(false)
    setSelectedFormForCriteria(null)
  }

  return {
    selected,
    menuAnchorEl,
    selectedFormId,
    isResponseCriteriaDialogOpen,
    selectedFormForCriteria,

    handleEditClick,
    handleSelectAllClick,
    handleClick,
    handleOpenMenu,
    handleCloseMenu,
    handleDeleteForm,
    handleDuplicateForm,
    handleOpenResponseCriteriaDialog,
    handleResponseCriteriaSelect,
    setIsResponseCriteriaDialogOpen,
  }
}
