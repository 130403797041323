import { Button, Stack, Typography } from '@mui/material'
import { TextField } from '@common/components/Form/TextField'
import { Dropdown } from '@common/components/Form/Dropdown/Dropdown'
import { useForm, FormProvider } from 'react-hook-form'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CompaniesUsersReadSchema } from '@common/config/api/client/types.gen'

const companyTypeOptions = [
  { label: 'Site', value: 'Site' },
  { label: 'Sponsor', value: 'Sponsor' },
  { label: 'CRO', value: 'CRO' },
]

const roleOptions = [
  { label: 'IAM', value: 'IAM' },
  { label: 'Reader', value: 'Reader' },
  { label: 'Site User', value: 'Site User' },
]

const companyOptions = [
  { label: 'GCIS', value: 'GCIS' },
  { label: 'Bayer', value: 'Bayer' },
  { label: 'Other Corp', value: 'Other Corp' },
]

interface Props {
  user: CompaniesUsersReadSchema | undefined
}

export default function ProfileInformation({ user }: Props) {
  const [searchParams] = useSearchParams()
  const [isEditMode, setIsEditMode] = useState(searchParams.get('edit') === 'true')

  const methods = useForm({
    defaultValues: {
      username: user?.user_name ?? '',
      email: user?.user_email ?? '',
      company: user?.company_name ?? '',
      companyType: user?.company_type ?? '',
      verified: user?.is_verified ? 'Yes' : 'No',
    },
  })

  const handleCancel = () => {
    methods.reset()
    setIsEditMode(false)
  }

  const handleSave = () => {
    setIsEditMode(false)
  }

  return (
    <FormProvider {...methods}>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" mt={3}>
            {user?.user_name}'s profile information
          </Typography>
          {!isEditMode && (
            <Button variant="contained" onClick={() => setIsEditMode(true)} sx={{ backgroundColor: '#0F5499' }}>
              Edit personal information
            </Button>
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            control={methods.control}
            name="username"
            label="Username"
            disabled={!isEditMode}
            size="small"
            sx={{ width: '50%' }}
          />
          <TextField name="phone" label="Phone number" disabled={!isEditMode} size="small" sx={{ width: '50%' }} />
        </Stack>
        <TextField control={methods.control} name="email" label="Email" disabled={!isEditMode} size="small" />
        <Stack direction="row" spacing={2}>
          <Dropdown
            control={methods.control}
            name="company"
            label="Company name"
            options={companyOptions}
            disabled={!isEditMode}
            size="small"
            sx={{ width: '50%' }}
          />
          <Dropdown
            control={methods.control}
            name="companyType"
            label="Company type"
            options={companyTypeOptions}
            disabled={!isEditMode}
            size="small"
            sx={{ width: '50%' }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Dropdown
            name="role"
            label="Role assigned in study"
            options={roleOptions}
            disabled={!isEditMode}
            size="small"
            sx={{ width: '50%' }}
          />
          <TextField
            control={methods.control}
            name="verified"
            label="Verified"
            disabled={true}
            size="small"
            sx={{ width: '50%' }}
          />
        </Stack>
        {isEditMode && (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save changes
            </Button>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  )
}
