import { TableBody, TableRow, TableCell } from '@mui/material'
import { EnhancedTableBodyProps } from '@features/study_view/study.types'
import { getComparator } from '@features/study_view/utils'
import { useMemo } from 'react'
import StudyStatusChip from './StudyStatusChip'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/routes'

export default function EnhancedTableBodyStudyView({ rows, order, orderBy, filterBy }: EnhancedTableBodyProps) {
  const navigate = useNavigate()

  const handleViewClick = (itemId: string) => {
    const path = generatePathWithParams(ROUTES.STUDY_DETAILS.path, { studyId: itemId })
    navigate(path)
  }

  const visibleRows = useMemo(() => {
    const filteredRows = filterBy !== 'ALL' ? rows.filter((row) => row.status === filterBy) : rows
    return filteredRows.sort(getComparator(order, orderBy))
  }, [rows, order, orderBy, filterBy])

  return (
    <TableBody data-testid="table-body">
      {visibleRows.map((row) => (
        <TableRow
          tabIndex={-1}
          key={row.id}
          sx={{
            borderBottom: '1px solid rgba(220, 227, 229, 1)',
            cursor: 'pointer',
          }}
          hover
          onClick={() => handleViewClick(row.id)}
          data-testid="table-row"
        >
          <TableCell align="left" data-testid="cell-nickname">
            {row.nickname}
          </TableCell>
          <TableCell align="left" data-testid="cell-id">
            {row.user_facing_study_id}
          </TableCell>
          <TableCell align="left" data-testid="cell-type">
            {row.type}
          </TableCell>
          <TableCell align="left" data-testid="cell-status">
            <StudyStatusChip status={row.status!} />
          </TableCell>
          <TableCell align="left" data-testid="cell-open-tasks">
            {row.open_tasks_count}
          </TableCell>
          <TableCell align="left" data-testid="cell-overdue">
            {row.overdue_count}
          </TableCell>
          <TableCell align="left" data-testid="cell-queries">
            {row.queries_count}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
