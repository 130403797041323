import { GridViewRounded, ViewListRounded } from '@mui/icons-material'
import { InputAdornment, Stack, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SegmentedControls from './SegmentedControls'

type StudiesOverviewHeaderProps = {
  activeControl: number
  setActiveControl: (val: number) => void
}

const StudiesOverviewHeader = ({ activeControl, setActiveControl }: StudiesOverviewHeaderProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" data-testid="studies-overview-header">
      <Stack
        direction="row"
        justifyItems="center"
        justifyContent="space-between"
        width="100%"
        data-testid="header-controls"
      >
        <SegmentedControls
          value={activeControl}
          setValue={setActiveControl}
          controls={[
            { icon: <GridViewRounded data-testid="grid-view-icon" /> },
            { icon: <ViewListRounded data-testid="list-view-icon" /> },
          ]}
        />
        <TextField
          data-testid="search-field"
          variant="outlined"
          size="small"
          label="Search study"
          sx={{
            boxShadow: 1,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon data-testid="search-icon" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  )
}

export default StudiesOverviewHeader
