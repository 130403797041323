import { useState, MouseEvent, ChangeEvent } from 'react'
import { TransformedSeriesLabels } from './types'

interface UseSeriesLabelManagementTableHandlersProps {
  onEditClick: (seriesLabelId: string) => void
  onDeleteSeriesLabel: (seriesLabelId: string) => void
  onDuplicateSeriesLabel: (seriesLabelId: string) => void
  onBulkDeleteSeriesLabels: (seriesLabelIds: Array<string>) => void
  seriesLabels: TransformedSeriesLabels | undefined
}

export default function useSeriesLabelManagementTableHandlers({
  onEditClick,
  onDeleteSeriesLabel,
  onDuplicateSeriesLabel,
  onBulkDeleteSeriesLabels,
  seriesLabels,
}: UseSeriesLabelManagementTableHandlersProps) {
  const [selected, setSelected] = useState<string[]>([])
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedSeriesLabelId, setSelectedSeriesLabelId] = useState<string | null>(null)

  const handleEditClick = (e: MouseEvent<HTMLElement>, seriesLabelId: string) => {
    e.preventDefault()
    e.stopPropagation()
    onEditClick(seriesLabelId)
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(seriesLabels?.items.map((row) => row.id) ?? [])
    } else {
      setSelected([])
    }
  }
  const handleClick = (_event: MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    const newSelected = selectedIndex === -1 ? [...selected, id] : selected.filter((selectedId) => selectedId !== id)
    setSelected(newSelected)
  }

  const handleOpenMenu = (event: MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
    setSelectedSeriesLabelId(id)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
    setSelectedSeriesLabelId(null)
  }

  const handleDeleteSeriesLabel = () => {
    if (selectedSeriesLabelId) {
      onDeleteSeriesLabel(selectedSeriesLabelId)
      handleCloseMenu()
    }
  }
  const handleBulkDeleteSeriesLabels = () => {
    if (selected.length) {
      onBulkDeleteSeriesLabels(selected)
      setSelected([])
    }
  }

  const handleDuplicateSeriesLabel = () => {
    if (selectedSeriesLabelId) {
      onDuplicateSeriesLabel(selectedSeriesLabelId)
      handleCloseMenu()
    }
  }

  return {
    selected,
    menuAnchorEl,
    selectedSeriesLabelId,
    handleEditClick,
    handleSelectAllClick,
    handleClick,
    handleOpenMenu,
    handleCloseMenu,
    handleDeleteSeriesLabel,
    handleDuplicateSeriesLabel,
    handleBulkDeleteSeriesLabels,
  }
}
