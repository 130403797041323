import { Divider, Paper, Stack } from '@mui/material'
import ExamDetails from './ExamDetails'
import { Fragment } from 'react/jsx-runtime'

export default function ExamDetailsList() {
  const numberOfExams = 5
  return (
    <Paper
      sx={{
        borderRadius: 4,
        bgcolor: 'paper.main',
      }}
    >
      <Stack spacing={0.5}>
        {Array.from({ length: numberOfExams }, (_, index) => (
          <Fragment key={index}>
            <ExamDetails />
            {index !== numberOfExams - 1 && <Divider />}
          </Fragment>
        ))}
      </Stack>
    </Paper>
  )
}
