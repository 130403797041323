import { useMemo } from 'react'
import { TableBody, TableRow, TableCell } from '@mui/material'
import PopupMenuList from './PopupMenuList'
import { EnhancedTableBodyProps } from '../qc_task_list.types'
import TaskStateChip from './TaskStateChip'
import { formatTaskDate, getComparator } from '../utils'
import { useParams } from 'react-router-dom'
import { getPrimarySitePseudoId } from '@common/utils/getPrimarySitePseudoID'

export default function EnhancedTableBodyCompletedTasks({ rows, order, orderBy }: EnhancedTableBodyProps) {
  const visibleRows = useMemo(() => {
    const comparator =
      orderBy === 'sites.site_pseudo_id'
        ? getComparator(order, orderBy, (item) => getPrimarySitePseudoId(item.sites))
        : getComparator(order, orderBy)
    return [...rows].sort(comparator)
  }, [order, orderBy, rows])

  const { studyId } = useParams()
  return (
    <TableBody>
      {visibleRows.map((row) => {
        return (
          <TableRow
            tabIndex={-1}
            key={row.task_id}
            sx={{
              borderBottom: '1px solid rgba(220, 227, 229, 1)',
            }}
          >
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="site-pseudo-id-cell">
              {getPrimarySitePseudoId(row.sites)}
            </TableCell>
            <TableCell align="left" data-testid="subject-pseudo-id-cell">
              {getPrimarySitePseudoId(row.sites)} - {row.subject.subject_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="timepoint-name-cell">
              {row.timepoint_requirement.name}
            </TableCell>
            <TableCell align="left" data-testid="task-state-cell">
              <TaskStateChip taskState={row.task_state} />
            </TableCell>
            <TableCell align="left" data-testid="completed-date-cell">
              {formatTaskDate(row.completed_date ?? '')}
            </TableCell>
            {row.task_state === 'COMPLETE' && (
              <TableCell align="right" data-testid="actions-cell">
                <PopupMenuList pseudo_id={row.task_pseudo_id} task_id={row.task_id} study_id={studyId!} />
              </TableCell>
            )}
          </TableRow>
        )
      })}
    </TableBody>
  )
}
